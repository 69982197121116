import { useCallback, useState } from "react";

import { entries, keys, map } from "lodash";

import { Close, CopyAll } from "@mui/icons-material";
import {
  Avatar,
  Box,
  ButtonGroup,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  IconButton,
  Typography,
} from "@mui/material";

import { User } from "@/api";
import { CustomButton } from "@/common/components";
import { isFacilityBasedView, useM7SimpleContext, useToast } from "@/common/hooks";
import { isOnMobile } from "@/common/utils/isOnMobile";

import logo from "/apple-touch-icon-180x180.png";

import "./AdminFAB.scss";
import { useLocalizedContent } from "./useLocalizedContent";

const emptyValue = "_empty_";
export const AdminFAB = () => {
  const [isOpen, setIsOpen] = useState(false);
  const { showInfo } = useToast();
  const isMobile = isOnMobile();

  const m7SimpleContext = useM7SimpleContext();
  const isAdmin = m7SimpleContext.currentRole === User.ERole.admin;
  const { location, context: localizedContent } = useLocalizedContent();

  const allValues = map(
    [["path", location], ...entries(m7SimpleContext), ...entries(localizedContent)],
    ([key, value]) => `${key}: ${value || emptyValue}`,
  ).join("\n");

  const copy = useCallback(
    (value: string, message: string) => {
      void window.navigator.clipboard.writeText(value).then(() => showInfo(message));
    },
    [showInfo],
  );

  if (isMobile || !isAdmin) return null;

  return (
    <Card className={"AdminFAB " + (isOpen ? "expanded" : "contracted")}>
      <CardHeader
        avatar={
          <Avatar className="logo-open-button" onClick={() => setIsOpen(!isOpen)}>
            <img src={logo} alt="M7 Admin" />
          </Avatar>
        }
        action={
          <IconButton aria-label="close" onClick={setIsOpen.bind(null, false)}>
            <Close />
          </IconButton>
        }
        title={`M7 context`}
      />
      <CardContent className="content">
        <Box className="m7context">
          <Typography children={"General context"} p={2} />
          <Typography
            children={"View based on " + (isFacilityBasedView(location) ? "Facility" : "Unit")}
            p={2}
          />
          {[["path", location], ...entries(m7SimpleContext)].map(([key, value]) =>
            copyGroupFor(key, value, copy),
          )}
        </Box>
        {!!keys(localizedContent).length && (
          <Box className="localizedContent">
            <Typography children={"Page context"} p={2} />
            {entries(localizedContent).map(([key, value]) => copyGroupFor(key, value, copy))}
          </Box>
        )}
      </CardContent>
      <CardActions disableSpacing>
        <Box flexGrow={1} />
        <CustomButton
          trackingLabel={null}
          children="Copy All"
          variant="contained"
          endIcon={<CopyAll />}
          onClick={() => copy(allValues, "All values copied")}
        />
        <CustomButton
          variant="text"
          children="Close"
          onClick={setIsOpen.bind(null, false)}
          trackingLabel={null}
        />
      </CardActions>
    </Card>
  );
};

const copyGroupFor = (
  key: string,
  value: string | undefined | null,
  copy: (value: string, message: string) => void,
) => (
  <ButtonGroup key={key} className="key-value-line">
    <CustomButton
      variant="outlined"
      trackingLabel={null}
      className="copyKey"
      children={key}
      onClick={() => copy(key, `${key} key copied`)}
    />
    <CustomButton
      variant="outlined"
      trackingLabel={null}
      className="copyValue"
      children={<>{value || <i>{emptyValue}</i>}</>}
      onClick={() => copy(value || emptyValue, `${key} value copied`)}
    />
    <CustomButton
      variant="outlined"
      trackingLabel={null}
      className="copyKeyValue"
      endIcon={<CopyAll />}
      onClick={() => copy(`${key}: ${value || emptyValue}`, `${key} key and value copied`)}
    />
  </ButtonGroup>
);
