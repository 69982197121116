import { useCallback, useEffect, useMemo, useRef, useState } from "react";

import { entries, keyBy, keys, reduce, values } from "lodash";

import { RosterActions } from "#/features/Roster/store";
import { TBulkCreateUsersUnitData } from "#/features/Roster/store/bulkCreateUsers";
import { useAppDispatch, useAppStore } from "@/common/hooks";

import { expectedOrderedColumns } from "../constants";
import {
  TCellError,
  TColumnError,
  TExpectedColumn,
  TExtractedData,
  TUserRow,
  TUserRowOptional,
} from "../types";

import { DataParser } from "./useParseData/DataParser";

type TStateErrors = {
  cells: TIndexedErrors;
  columns: TColumnError[];
};
export type TIndexedErrors = {
  [rowIndex: number]: { [columnIndex: number]: unknown };
};

const noColumnsErrors: TColumnError[] = [];
const noCellsErrors: TIndexedErrors = {};

/**
 * Hook to update the data in the table
 * @param CSVData - The data extracted from the CSV file
 * @param unitData - The unit data (shift types, staff types, timezone ...)
 *
 * @returns The data, including rows and headers, errors, and actions
 */
export const useUpdateData = ({
  CSVData,
  unitData,
}: {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  CSVData: TExtractedData;
  unitData: TBulkCreateUsersUnitData;
}) => {
  const dispatch = useAppDispatch();
  const store = useAppStore();

  const [stateData, setStateData] = useState<TUserRowOptional[] | null>(null);
  const [stateHeaders, setStateHeaders] = useState<Record<TExpectedColumn, number> | null>(null);
  const [stateErrors, setStateErrors] = useState<TStateErrors | null>(null);
  const dataParser = useRef<DataParser | null>(null);

  /**
   * Data setters
   */
  // Set store data once: when CSVData is set, and when stateData is null
  useEffect(() => {
    if (!CSVData) return;

    if (stateData === null) setStateData(CSVData.rows);
    if (stateErrors === null)
      setStateErrors({
        cells: reduce(
          CSVData?.cellErrors,
          (acc, error) => {
            (acc[error.rowIndex] ||= {})[error.columnIndex] = error.originalValue;
            return acc;
          },
          {} as TIndexedErrors,
        ),
        columns: CSVData?.columnErrors || [],
      });
    if (stateHeaders === null) setStateHeaders(CSVData?.indexedHeaders);
  }, [CSVData, stateData, stateErrors, stateHeaders]);

  // Set dataParser when unit data is available
  useEffect(() => {
    if (!unitData || unitData.staffTypes.length === 0 || unitData.shiftTypes.length === 0) return;

    dataParser.current = new DataParser(
      unitData.staffTypes,
      unitData.shiftTypes,
      unitData.timezone,
    );
  }, [unitData]);

  // Dispatch validity
  const cellsAreValid = keys(stateErrors?.cells).length === 0;
  useEffect(() => {
    dispatch(RosterActions.setDataIsValid(cellsAreValid));
  }, [cellsAreValid, dispatch]);

  const updateErrors = useCallback(
    (rowErrors: TCellError[], rowIndex: number, columnIndex: number) => {
      setStateErrors((prevErrors) => {
        if (!prevErrors) return { cells: rowErrors, columns: [] };

        // Prepare data
        const highlightedRow = store.getState().roster.bulkCreateUsers.highlightedRow;
        const errorsByColumnIndex = keyBy(rowErrors, "columnIndex");

        // Build a new error object for the given row
        const newErrors = prevErrors?.cells || {};
        const rowNewErrors = { ...(newErrors[rowIndex] ||= {}) };

        // If the current cell has an error, add it to the row
        if (errorsByColumnIndex[columnIndex])
          rowNewErrors[columnIndex] = errorsByColumnIndex[columnIndex]!.originalValue;
        // Else, keep other errors, but remove the current cell error
        else delete rowNewErrors[columnIndex];

        // If there are no errors, remove the row from the errors
        if (Object.keys(rowNewErrors).length === 0) {
          delete newErrors[rowIndex];
          // And if the row was highlighted, remove the highlight
          if (highlightedRow === rowIndex) dispatch(RosterActions.setHighlightedRow(null));
        } else newErrors[rowIndex] = rowNewErrors;

        return {
          ...prevErrors,
          cells: newErrors,
        };
      });
    },
    [dispatch, store],
  );
  /**
   * Actions
   */
  const updateCell = useCallback(
    <T extends TExpectedColumn>(rowIndex: number, column: T, value: TUserRow[T] | undefined) => {
      setStateData((prev) => {
        const rowData = prev?.[rowIndex];
        if (!rowData || !prev || !dataParser.current || !stateHeaders) return prev;

        const columnIndex = stateHeaders[column];
        rowData[column] = value;

        // Retrieve parsed row
        const { rowErrors, indexedRow } = dataParser.current.validateRow({
          row: values(rowData),
          orderedHeaders: entries(stateHeaders),
          rowIndex,
        });
        // Replace old row with new one
        prev[rowIndex] = indexedRow;

        // And dispatch errors
        updateErrors(rowErrors, rowIndex, columnIndex);

        // Dispatch new row
        return [...prev];
      });
    },
    [stateHeaders, updateErrors],
  );

  const addRow = useCallback(() => {
    setStateData((prev) => {
      if (!dataParser.current || !stateHeaders) return prev;

      // Get row keys from first row, or expected columns if there are no rows
      const rowKeys = prev?.[0] ? keys(prev[0]) : expectedOrderedColumns;
      const rowIndex = prev?.length || 0;

      const newRowData = rowKeys.reduce((acc, column) => {
        acc[column] = undefined;
        return acc;
      }, {} as TUserRowOptional);

      // Pre-validate new row
      const { rowErrors, indexedRow } = dataParser.current.validateRow({
        row: values(newRowData),
        orderedHeaders: entries(stateHeaders),
        rowIndex,
      });

      // Dispatch errors
      setStateErrors((prevErrors) => {
        if (!prevErrors) return { cells: { [rowIndex]: rowErrors }, columns: [] };

        prevErrors.cells[rowIndex] = rowErrors;
        return { ...prevErrors };
      });

      // Dispatch new row
      return [...(prev || []), indexedRow];
    });
  }, [stateHeaders]);

  // Remove a row, and its possible errors
  const removeRow = useCallback((rowIndex: number) => {
    setStateData((prev) => (prev ? prev.filter((_, index) => index !== rowIndex) : null));
    setStateErrors((prevErrors) => {
      if (!prevErrors) return null;

      delete prevErrors.cells[rowIndex];
      return { ...prevErrors };
    });
  }, []);

  const data = useMemo(
    () => ({
      rows: stateData,
      headers: stateHeaders,
    }),
    [stateData, stateHeaders],
  );

  const errors = useMemo(
    () => ({
      cells: stateErrors?.cells || noCellsErrors,
      columns: stateErrors?.columns || noColumnsErrors,
    }),
    [stateErrors],
  );

  const actions = useMemo(
    () => ({
      updateCell,
      addRow,
      removeRow,
    }),
    [updateCell, addRow, removeRow],
  );

  return useMemo(
    () => ({
      data,
      errors,
      actions,
    }),
    [data, errors, actions],
  );
};
