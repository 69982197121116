import { upperFirst } from "lodash";

import { StaffDetails } from "~/api/staffDetails/types";

import { EUserTypes } from "./SchedulerRoster/AddEditPage/SchedulerDataForm/types";
import { EStaffStatus, IRosterListSelectFields } from "./types";

export const rosterUserRoles: IRosterListSelectFields<EUserTypes>[] = [
  { label: upperFirst(EUserTypes.staff), value: EUserTypes.staff },
  { label: upperFirst(EUserTypes.scheduler), value: EUserTypes.scheduler },
];

export const rosterUserStatuses: IRosterListSelectFields<EStaffStatus>[] = [
  { label: "Active", value: EStaffStatus.Active },
  { label: "Inactive", value: EStaffStatus.Inactive },
];

export const rosterUserEmploymentStatus: IRosterListSelectFields<StaffDetails.EEmploymentType>[] = [
  { label: "Full time", value: StaffDetails.EEmploymentType.fullTime },
  { label: "Part time", value: StaffDetails.EEmploymentType.partTime },
  { label: "Per diem", value: StaffDetails.EEmploymentType.perDiem },
  { label: "Travel staff", value: StaffDetails.EEmploymentType.travelStaff },
  { label: "In-house contract", value: StaffDetails.EEmploymentType.inHouseContract },
  { label: "Flex", value: StaffDetails.EEmploymentType.flex },
  { label: "Contract", value: StaffDetails.EEmploymentType.contract },
];
