import { Fragment, memo, useState } from "react";

import { FilterCenterFocus } from "@mui/icons-material";
import { Divider, Stack, Typography } from "@mui/material";

import { RosterActions } from "#/features/Roster/store";
import { CustomButton, CustomCollapse } from "@/common/components";
import { useAppDispatch, useEntries } from "@/common/hooks";

import { expectedColumnLabels } from "../UserTable/constants";
import { TIndexedErrors } from "../UserTable/hooks/useUpdateData";
import { TExpectedColumn } from "../UserTable/types";

export const RowErrors = memo(
  ({
    errors,
    rowIndex,
    headers,
  }: {
    errors: TIndexedErrors[number];
    rowIndex: number;
    headers: Record<string, TExpectedColumn>;
  }) => {
    const [isOpen, setIsOpen] = useState(false);

    const dispatch = useAppDispatch();
    const errorsArray = useEntries(errors);

    const label = (
      <RowLabel
        rowIndex={rowIndex}
        errorCount={errorsArray.length}
        onClick={() => {
          if (!isOpen) setIsOpen(true);

          dispatch(RosterActions.setHighlightedRow(Number(rowIndex)));
          scrollToRow(rowIndex);
        }}
      />
    );

    return (
      <CustomCollapse
        className="row-section"
        label={label}
        id={`row-${rowIndex}-section`}
        isOpen={isOpen}
        onChange={() => setIsOpen(!isOpen)}
      >
        <Fragment key={"row-errors-" + rowIndex.toString()}>
          {errorsArray?.map(([errorIndex, error]) => {
            const headerKey = headers[errorIndex];
            const headerLabel = headerKey ? expectedColumnLabels[headerKey] : "";

            return (
              <Typography key={"row-error-" + errorIndex.toString()}>
                Invalid <u>{headerLabel}</u>:{" "}
                <i>
                  <b>{(error || "").toString()}</b>
                </i>
              </Typography>
            );
          })}
          <Divider sx={{ my: 1, borderWidth: "1px" }} />
        </Fragment>
      </CustomCollapse>
    );
  },
);

const scrollToRow = (rowIndex: number) => {
  // Find the table container and the specific row
  const tableContainer = document.querySelector(".bulk-create-users-table .table-container");
  const targetRow = tableContainer?.querySelector(`[data-row-index="${rowIndex}"]`);

  // Scroll the row into view if found
  if (targetRow) {
    targetRow.scrollIntoView({ behavior: "auto", block: "center" });
  }
};

const RowLabel = ({
  rowIndex,
  errorCount,
  onClick,
}: {
  rowIndex: number;
  errorCount: number;
  onClick: () => void;
}) => (
  <Stack direction="row" alignItems="center" gap={1} className="row-label">
    <CustomButton
      iconOnly
      startIcon={<FilterCenterFocus />}
      label={`Go to row ${rowIndex}`}
      trackingLabel={null}
      onClick={(event) => {
        event.stopPropagation();
        onClick();
      }}
    />
    <Typography children={`Row ${rowIndex} (${errorCount} errors)`} />
  </Stack>
);
