import { useCurrentUnit, useIsFacilityBasedView, useAppSelector } from "@/common/hooks";

/**
 * Custom hook that returns the current facility ID based on the
 *   current selected unit, or the house view selected facility.
 * It doesn't uses the facility id HOOK as this one is used to set the data the hook
 *   depends on.
 * @returns The current facility ID or undefined if not found.
 */
export const useDropdownSelectedFacilityId = () => {
  const houseViewSelectedFacility = useAppSelector(
    (state) => state.houseView.pageFilters.selectedFacilityId,
  );
  const currentUnit = useCurrentUnit();
  const currentViewUsesFacilitySelector = useIsFacilityBasedView();

  return currentViewUsesFacilitySelector
    ? houseViewSelectedFacility || undefined
    : currentUnit?.facility?.id || undefined;
};
