import { useMemo } from "react";

import { includes } from "lodash";

import { User } from "@/api";

import { useCurrentRole } from "./useCurrentRole";

const staffAppRoles = [User.ERole.staff];

export const useIsStaffApp = (): boolean => {
  const currentRole = useCurrentRole().userIs;

  return useMemo(() => {
    return !!currentRole && includes(staffAppRoles, currentRole);
  }, [currentRole]);
};
