import { CustomSwitch, Tag } from "@/common/components";

import { TDataComponent } from "./types";

export const BooleanField: TDataComponent<boolean> = {
  editor: ({ onBlur, value, updateCell }) => {
    return (
      <CustomSwitch
        checked={value}
        onChange={(newValue) => {
          updateCell(newValue);
          onBlur();
        }}
        size="medium"
        trackingLabel={null}
        name="booleanField"
        switchVariant="buttons"
        label={null}
      />
    );
  },

  readonly: ({ value }) => {
    if (value === undefined || value === null)
      return (
        <Tag text={"N/A"} variant={"small"} className={`boolean-tag undefined`} disabled={true} />
      );

    return (
      <Tag
        text={value ? "Yes" : "No"}
        variant={"small"}
        className={`boolean-tag ${value ? "checked" : "unchecked"}`}
      />
    );
  },
};
