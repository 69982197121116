import { IShiftType } from "@/api";

import { ParserError } from "../DataParser";

import { flatCleanString } from "./helpers";
import { TDataParser } from "./types";

export const shiftTypeParser: TDataParser<IShiftType["key"]> = (value, dataParser) => {
  if (!value) return null;

  const stringValue = flatCleanString(value);
  const shiftType = dataParser.shiftTypesNames[stringValue];
  if (!shiftType) throw new ParserError("Invalid shift type name");

  return shiftType;
};
