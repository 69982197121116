import { IStaffType } from "@/api";

import { ParserError } from "../DataParser";

import { flatCleanString } from "./helpers";
import { TDataParser } from "./types";

export const staffTypeNameParser: TDataParser<IStaffType["name"]> = (value, dataParser) => {
  if (!value) return null;

  const stringValue = flatCleanString(value);
  const staffTypeName = dataParser.staffTypesNames[stringValue];
  if (!staffTypeName) throw new ParserError("Invalid staff type name");

  return staffTypeName;
};
