import { isEqual } from "lodash";

import { Box, Stack, Typography } from "@mui/material";

import { useAppSelector } from "@/common/hooks";
import { disabledGrey } from "@/common/theming";

import { Table } from "./Table";

import "./UserTable.scss";

const componentId = "bulk-create-users";
export const UserTable = () => {
  const { activeStep, uploadedFile } = useAppSelector(
    (state) => state.roster.bulkCreateUsers,
    isEqual,
  );

  if (activeStep === 0 || !uploadedFile) {
    return (
      <Box className={componentId} data-testid={componentId}>
        <Typography variant="h5">User Data</Typography>
        <Typography sx={{ color: disabledGrey, fontStyle: "italic" }}>
          Follow instructions on the sidebar to start the process.
        </Typography>
      </Box>
    );
  }

  return (
    <Box className={componentId} data-testid={componentId}>
      <Title fileName={uploadedFile.name} />
      <Table />
    </Box>
  );
};

const Title = ({ fileName }: { fileName: string }) => {
  return (
    <Stack direction="row" gap={2} sx={{ mb: 2, ml: 5 }}>
      <Typography variant="h5">User Data</Typography>
      <Typography variant="h6" sx={{ color: disabledGrey, fontStyle: "italic", fontWeight: 300 }}>
        ({fileName})
      </Typography>
    </Stack>
  );
};
