import { memo, useState } from "react";

import { entries } from "lodash";

import { Check, Delete, ReportGmailerrorred, Warning } from "@mui/icons-material";
import { Stack, TableCell, TableRow, Tooltip, Typography } from "@mui/material";

import { TBulkCreateUsersUnitData } from "#/features/Roster/store/bulkCreateUsers";
import { CustomButton } from "@/common/components";
import { black, mediumGray, redAlert } from "@/common/theming";

import {
  BooleanField,
  DateField,
  EmploymentTypeField,
  ShiftTypeField,
  StaffTypeNameField,
  StringField,
} from "./fields";
import { TDataComponent } from "./fields/types";
import { useUpdateData } from "./hooks/useUpdateData";
import { TExpectedColumn, TUserRow, TUserRowOptional } from "./types";

const componentId = "bulk-create-users-table-row";
export const UserRow = memo(
  ({
    shouldRender,
    row,
    rowIndex,
    isHighlighted,
    unitData,
    updateCell,
    removeRow,
    cellErrors,
    headers,
  }: {
    shouldRender: boolean;
    row: TUserRowOptional;
    rowIndex: number;
    isHighlighted: boolean;
    unitData: TBulkCreateUsersUnitData;
    updateCell: ReturnType<typeof useUpdateData>["actions"]["updateCell"];
    removeRow: ReturnType<typeof useUpdateData>["actions"]["removeRow"];
    cellErrors?: Record<number, unknown>;
    headers?: Record<TExpectedColumn, number>;
  }) => {
    const isDataValid = !entries(cellErrors).length;
    const [editableColumns, setEditableColumns] = useState<{
      [key in number]: unknown;
    }>({ ...cellErrors });

    if (!shouldRender) return <TableRow data-row-index={rowIndex} />;

    return (
      <TableRow
        data-row-index={rowIndex}
        sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
        className={isHighlighted ? "highlighted-row" : ""}
      >
        {entries(headers).map(([column, columnIndex]) => {
          const editable = !!cellErrors?.[columnIndex] || !!editableColumns[columnIndex];
          const cellError = cellErrors?.[columnIndex];

          const classNames = [componentId, editable ? "editable" : "readonly", column].join(" ");

          const editableField = getComponentsByDataType({
            columnKey: column,
            rowData: row,
            rowIndex,
            unitData,
            updateCell,
            // Logic to handle the editable and focused state of the field

            // Editable if there is an error, or if edit is toggled for that column
            editable: !!cellError || !!editableColumns[columnIndex],
            // Auto focused only if edit is toggled, but NO error is present
            focused: !!editableColumns[columnIndex] && !cellError,
            // On blur, toggle edit for that column
            onBlur: () => setEditableColumns((prev) => ({ ...prev, [columnIndex]: false })),
          });

          return (
            <TableCell
              key={rowIndex.toString() + column}
              align="right"
              className={classNames}
              onClick={() =>
                !cellError &&
                !editableColumns[columnIndex] &&
                setEditableColumns((prev) => ({ ...prev, [columnIndex]: true }))
              }
            >
              <>
                {columnIndex === 0 && (
                  <IndexAndActions
                    rowIndex={rowIndex}
                    isDataValid={isDataValid}
                    removeRow={removeRow}
                  />
                )}
                <ErrorTooltip
                  error={cellError}
                  onClick={() => updateCell(rowIndex, column, undefined)}
                />
                {editableField}
              </>
            </TableCell>
          );
        })}
      </TableRow>
    );
  },
);

// For each column, we have a component that will be used to edit the value
const componentsByDataType = {
  email: StringField,
  firstName: StringField,
  lastName: StringField,
  phoneNumber: StringField,
  preceptor: BooleanField,
  onOrientation: BooleanField,
  orientationEndDate: DateField,
  contractEndDate: DateField,
  employmentStartDate: DateField,
  shiftType: ShiftTypeField,
  employmentType: EmploymentTypeField,
  staffTypeName: StaffTypeNameField,
} satisfies Record<keyof TUserRow, TDataComponent>;

/**
 * Based on a column T:
 *  - get the component that will be used to edit or read the value
 *  - get the value from the row
 *  - Summon the component with the value and the updateCell function
 *
 * If the component is editable, also pass down the blur and focused state
 *  set by the parent to know which components should be editable and focused.
 * We do that to limit the number of editable components showing at once, given a few hundred
 *  is hurting performance.
 */
const getComponentsByDataType = <T extends keyof TUserRow>({
  columnKey,
  rowData,
  rowIndex,
  unitData,
  updateCell,
  editable,
  onBlur,
  focused,
}: {
  columnKey: T;
  rowData: TUserRowOptional;
  rowIndex: number;
  unitData: TBulkCreateUsersUnitData;
  updateCell: (rowIndex: number, column: T, value: TUserRowOptional[T] | undefined) => void;
  editable: boolean;
  onBlur: () => void;
  focused: boolean;
}) => {
  const DataComponent = componentsByDataType[columnKey];
  const Component = editable
    ? (DataComponent?.editor as TDataComponent<TUserRowOptional[T]>["editor"])
    : (DataComponent?.readonly as TDataComponent<TUserRowOptional[T]>["readonly"]);
  if (!Component) return <></>;

  const value = rowData[columnKey] as TUserRowOptional[T];

  return (
    <Component
      {...unitData}
      value={value}
      updateCell={(newValue: TUserRowOptional[T] | undefined) =>
        updateCell(rowIndex, columnKey, newValue)
      }
      onBlur={onBlur}
      focused={focused}
    />
  );
};

const IndexAndActions = ({
  rowIndex,
  isDataValid,
  removeRow,
}: {
  rowIndex: number;
  isDataValid: boolean;
  removeRow: (rowIndex: number) => void;
}) => (
  <Stack className="bulk-create-users-delete-row-button" direction="row">
    <Typography>{rowIndex}</Typography>
    {isDataValid ? <Check color="success" /> : <ReportGmailerrorred color="error" />}
    <CustomButton
      onClick={(event) => {
        removeRow(rowIndex);
        event.stopPropagation();
      }}
      sx={{ color: mediumGray, "&:hover": { color: redAlert } }}
      startIcon={<Delete />}
      iconOnly
      label="Delete row"
    />
  </Stack>
);

const ErrorTooltip = ({ error, onClick }: { error: unknown; onClick: () => void }) => {
  if (!error) return <></>;

  const clearable = error !== "No value";

  return (
    <Tooltip
      componentsProps={{
        tooltip: { className: "bulk-create-users-value-error-tooltip" },
      }}
      title={
        <Typography>
          {"Invalid value: "}
          <br />
          <b>{error.toString()}</b>
          {clearable && (
            <>
              <br />
              <br />
              <Typography sx={{ color: `${black} !important` }} children={"click to clear"} />
            </>
          )}
        </Typography>
      }
    >
      <Warning
        color="error"
        className="value-error-icon"
        {...(clearable
          ? {
              cursor: "pointer",
              onClick,
            }
          : {})}
      />
    </Tooltip>
  );
};
