import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { EUnitPermissionAreas } from "@m7-health/shared-utils";
import { filter, intersection, isEqual } from "lodash";

import { FilterAlt } from "@mui/icons-material";
import AddIcon from "@mui/icons-material/Add";
import { Box, Grid, Typography } from "@mui/material";

import MeatballsMenu from "~/common/components/MeatballsMenu";
import { SendMassSms } from "~/common/components/SendMassSms/SendMassSms";
import CustomButton from "~/common/components/TrackedComponents/Button";
import { useCurrentUnitId } from "~/common/hooks/useCurrentUnitId";
import { useAppDispatch, useAppSelector } from "~/common/hooks/useRedux";
import { setSelectedUnit, setUnitModal } from "~/common/store";
import { darkGray } from "~/common/theming/colors";
import {
  openModal,
  setIsAddUserModalOpen,
  setIsBulkUpdateStaffActive,
  setIsBulkUsersUploadModalOpen,
  setIsEditAttributeEligibilityModalOpen,
  setIsEditFloatEligibilityModalOpen,
  setStaffRosterFiltersModalOpen,
} from "~/features/Roster/store";
import { useUnitQuery } from "~/routes/queries";

import { schedulePermissions } from "#/features/SchedulerGrid/constants";
import CustomTabs from "@/common/components/TrackedComponents/Tabs";
import { useAppFlags, useCheckUserPermission, useCurrentRole } from "@/common/hooks";
import { useDownloadRoster } from "@/common/hooks/useDownloadRoster";
import { useDownloadSubmissions } from "@/common/hooks/useDownloadSubmissions";

import { RosterModals } from "../modals";
import { useRosterQueryAll } from "../queries";
import { EStaffRosterPageTabs } from "../types";

import { useIsStaffProfilePage } from "./hooks/useIsStaffProfilePage";
import { useListStaff } from "./hooks/useListStaff";
import { useSelectedRosterTab } from "./hooks/useSelectedRosterTab";
import { StaffProfilePage } from "./StaffProfilePage";
import StaffRosterTable from "./StaffRosterTable";

export enum ERosterUserOptions {
  home = "home",
  other = "other",
  all = "all",
}

const tabs = [
  { label: "Home Unit Users", value: EStaffRosterPageTabs.homeUnitUsers },
  { label: "Floats from Other Units", value: EStaffRosterPageTabs.otherUnitUsers },
  { label: "Suspended Users", value: EStaffRosterPageTabs.suspendedUsers },
];

const StaffRoster = () => {
  const dispatch = useAppDispatch();
  const { userIsAdmin } = useCurrentRole();
  const currentUnitId = useCurrentUnitId();
  const {
    csvUserUploadV2,
    preferenceRequirementsV2,
    templatedPreferences,
    newSmsOnboarding,
    useBulkUpdateStaff,
  } = useAppFlags();

  const { data: units, isLoading: isLoadingUnits } = useUnitQuery();
  const downloadSubmissionsCsv = useDownloadSubmissions(currentUnitId, !userIsAdmin);
  const downloadRosterCsv = useDownloadRoster();

  const { unitId } = useParams();
  const navigate = useNavigate();
  const { selectedRosterTab } = useSelectedRosterTab();

  const { userRoles, selectedUnit, filters } = useAppSelector(
    (state) => ({
      userRoles: state.user.userData.roles,
      selectedUnit: state.common.currentUnit,
      filters: state.roster.staffRosterfilters,
    }),
    isEqual,
  );
  const canManage = useCheckUserPermission("manage", EUnitPermissionAreas.staffRoster);
  const activeFiltersNumber = Object.values(filters).flat().length;

  useEffect(() => {
    if (!unitId && selectedUnit) {
      navigate(`/roster/staff/${selectedUnit.id}/${EStaffRosterPageTabs.homeUnitUsers}`);
    } else if (unitId && !selectedRosterTab) {
      navigate(`/roster/staff/${unitId}/${EStaffRosterPageTabs.homeUnitUsers}`);
    } else if (unitId && (!selectedUnit || unitId !== selectedUnit?.id)) {
      const unit = units?.find((item) => item.id === unitId);
      if (unit) {
        dispatch(setSelectedUnit(unit));
      }
    }
  }, [dispatch, navigate, selectedRosterTab, selectedUnit, selectedUnit?.id, unitId, units]);

  const handleEditUnitModal = () => {
    dispatch(setUnitModal("edit"));
  };

  const handleBulkUsersUploadModal = () => {
    if (csvUserUploadV2) {
      navigate("/roster/bulk-create");
    } else {
      dispatch(setIsBulkUsersUploadModalOpen(true));
    }
  };

  const handleEditFloatEligibilityModal = () => {
    dispatch(setIsEditFloatEligibilityModalOpen(true));
  };

  const handleEditAttributeEligibilityModal = () => {
    dispatch(setIsEditAttributeEligibilityModalOpen(true));
  };

  const triggerOpenModal =
    (modal: "updateRuleSetsModal" | "updatePreferencesTemplatesModal" | "sendResetPasswordModal") =>
    () =>
      dispatch(openModal(modal));

  const hasUnitEditPermission =
    intersection(userRoles, schedulePermissions.editSchedule)?.length > 0;

  const menuItems = filter([
    useBulkUpdateStaff &&
      selectedRosterTab === EStaffRosterPageTabs.homeUnitUsers &&
      canManage && {
        label: "Bulk Update Staff",
        onClick: () => dispatch(setIsBulkUpdateStaffActive(true)),
      },
    hasUnitEditPermission &&
      canManage && {
        label: "Edit Unit",
        onClick: handleEditUnitModal,
      },
    hasUnitEditPermission &&
      canManage && {
        label: "Bulk Upload Users",
        onClick: handleBulkUsersUploadModal,
      },
    {
      label: "Survey Responses (CSV)",
      onClick: () => downloadSubmissionsCsv(),
    },
    {
      label: "Download Roster (CSV)",
      onClick: () => downloadRosterCsv(),
    },
    {
      label: "Update Float Eligibility",
      onClick: handleEditFloatEligibilityModal,
    },
    {
      label: "Update Position Eligibility",
      onClick: handleEditAttributeEligibilityModal,
    },
    ...(preferenceRequirementsV2 && canManage
      ? [{ label: "Update Requirements", onClick: triggerOpenModal("updateRuleSetsModal") }]
      : []),
    ...(templatedPreferences && canManage
      ? [
          {
            label: "Update Preference Templates",
            onClick: triggerOpenModal("updatePreferencesTemplatesModal"),
          },
        ]
      : []),
    ...(newSmsOnboarding && canManage
      ? [{ label: "Send Reset Password", onClick: triggerOpenModal("sendResetPasswordModal") }]
      : []),
  ]);

  const noUnitContent = (
    <Grid flexDirection="column" alignItems="center" container mt="15%">
      <Typography variant="h5" component="p">
        Welcome!
      </Typography>
      <Typography mt={2} variant="body1" fontWeight={300} color={darkGray}>
        Create your first unit to begin.
      </Typography>
      <Box mt={5}>
        <CustomButton label="Create unit" onClick={() => dispatch(setUnitModal("create"))} />
      </Box>
    </Grid>
  );

  const getRosterUserOptionPerTab = (rosterTabOption: EStaffRosterPageTabs) => {
    switch (rosterTabOption) {
      case EStaffRosterPageTabs.homeUnitUsers:
        return ERosterUserOptions.home;
      case EStaffRosterPageTabs.suspendedUsers:
        return ERosterUserOptions.all;
      case EStaffRosterPageTabs.otherUnitUsers:
        return ERosterUserOptions.other;
      default:
        return ERosterUserOptions.home;
    }
  };
  const userOption = getRosterUserOptionPerTab(
    selectedRosterTab || EStaffRosterPageTabs.homeUnitUsers,
  );
  const isSuspended = selectedRosterTab === EStaffRosterPageTabs.suspendedUsers;
  const { data: rosterDataPaginated, isLoading: isRosterDataPaginatedLoading } = useListStaff(
    [isSuspended],
    userOption,
  );
  const { data: rosterDataAll } = useRosterQueryAll([isSuspended], ERosterUserOptions.all);
  const isStaffProfilePage = useIsStaffProfilePage();

  return (
    <>
      {isStaffProfilePage && rosterDataAll ? (
        <StaffProfilePage rosterData={rosterDataAll} />
      ) : (
        <>
          <Box
            pt={5}
            display="flex"
            flexDirection="column"
            maxHeight="100%"
            width="100%"
            maxWidth="100%"
            boxSizing="border-box"
          >
            {selectedUnit && (
              <Grid container pl={3} pr={3} alignItems="center">
                <Grid item container xs={8} width="auto" alignItems="center" gap={3}>
                  <Box>
                    <Typography variant="h5">{selectedUnit.name} Staff Roster</Typography>
                    {selectedUnit.facility && (
                      <Typography variant="body1" fontWeight={300} color={darkGray}>
                        Facility: {selectedUnit.facility.name}
                      </Typography>
                    )}
                  </Box>
                  <CustomTabs<EStaffRosterPageTabs>
                    onChange={(newTab) => navigate(`/roster/staff/${selectedUnit.id}/${newTab}`)}
                    pillTabs
                    tabs={tabs}
                    value={selectedRosterTab}
                    width="auto"
                  />
                </Grid>
                <Grid item container gap={1} xs={4} justifyContent="end">
                  {useBulkUpdateStaff && (
                    <CustomButton
                      size="small"
                      label={`Filters${activeFiltersNumber ? ` (${activeFiltersNumber})` : ""}`}
                      startIcon={<FilterAlt />}
                      variant="outlined"
                      onClick={() => dispatch(setStaffRosterFiltersModalOpen(true))}
                    />
                  )}
                  {canManage && (
                    <>
                      <CustomButton
                        label="Add User"
                        onClick={() => dispatch(setIsAddUserModalOpen(true))}
                        startIcon={<AddIcon />}
                      />
                      <SendMassSms.Button />
                    </>
                  )}
                  {!!menuItems.length && (
                    <MeatballsMenu data-testid="roster-more-actions-menu" items={menuItems} />
                  )}
                </Grid>
              </Grid>
            )}
            <>
              {!isLoadingUnits && units?.length === 0 ? (
                noUnitContent
              ) : (
                <StaffRosterTable
                  staffItems={rosterDataPaginated?.list}
                  total={rosterDataPaginated?.pagination.total}
                  isRosterDataLoading={isRosterDataPaginatedLoading}
                  // use home unit users if selected tab is home unit users or suspended users
                  isSuspended={selectedRosterTab === EStaffRosterPageTabs.suspendedUsers}
                  key={selectedRosterTab}
                />
              )}
            </>
          </Box>
          <RosterModals />
        </>
      )}
    </>
  );
};

export default StaffRoster;
