import { MouseEvent, useState } from "react";

import { useAuth0 } from "@auth0/auth0-react";

import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import LogoutIcon from "@mui/icons-material/Logout";
import { Alert, Box, Divider, Grid, Menu, MenuItem, Typography } from "@mui/material";

import CustomAvatar from "~/common/components/Avatar";
import CustomButton from "~/common/components/TrackedComponents/Button";
import { useLogout } from "~/common/hooks/useLogout";
import { useAppDispatch, useAppSelector } from "~/common/hooks/useRedux";
import useUserFullName from "~/common/hooks/useUserFullName";
import { black, darkGray, darkPurple, nightShift } from "~/common/theming/colors";
// eslint-disable-next-line deprecate/import -- It's ok to use it here
import { useAnalytics } from "~/common/utils/analytics";
import { setEditSettingsModalOpen } from "~/features/Roster/store";
import { persistor } from "~/store";

import { useCurrentRole } from "@/common/hooks";

export const AvatarMenu = () => {
  const { isAuthenticated, user } = useAuth0();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const emailChangeConfirmationRequired = false; /* TODO */
  const dispatch = useAppDispatch();

  const analytics = useAnalytics();
  const { logout } = useLogout();

  const { email } = user || {};

  const { firstName, lastName } = useAppSelector((state) => state.user.userData);
  const { userIsAdmin } = useCurrentRole();

  const fullName = useUserFullName(firstName, lastName);

  const isOpen = Boolean(anchorEl);

  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  if (!isAuthenticated) return null;

  const handleLogOut = async () => {
    persistor.pause();
    await persistor.flush().then(() => {
      return persistor.purge();
    });

    analytics?.logout();

    await logout();
  };

  return (
    <Box display="flex" justifyContent="flex-end" className="avatar-menu">
      <Box alignItems="end" display="flex" flexDirection="column" mt={0.5} mr={0.5}>
        <Typography variant="caption" fontWeight={1000} color={darkPurple} fontSize={11}>
          help@m7health.com
        </Typography>
        <Typography variant="caption" fontWeight={100} color={darkPurple} fontSize={11}>
          Call/Text:{" "}
          <Typography variant="caption" fontWeight={1000} color={darkPurple} fontSize={11}>
            727-777-7391
          </Typography>
        </Typography>
      </Box>
      <CustomButton
        data-testid="avatar-menu"
        className="avatar-button"
        color="secondary"
        onClick={handleClick}
        style={{
          borderColor: isOpen ? black : "transparent",
        }}
        variant="outlined"
        trackingLabel="Avatar Menu"
      >
        <Box display="flex" alignItems="center">
          <CustomAvatar
            style={{
              fontSize: "0.8125rem",
              background: nightShift,
              color: black,
              marginRight: "0.5rem",
            }}
            firstName={firstName}
            lastName={lastName}
            email={email}
            width="28px"
            height="28px"
          />
          {isOpen ? (
            <ArrowDropUpIcon className="avatar-arrow" sx={{ color: black }} />
          ) : (
            <ArrowDropDownIcon className="avatar-arrow" sx={{ color: black }} />
          )}
        </Box>
      </CustomButton>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={isOpen}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <Box sx={{ padding: "1.5rem 1.5rem 1rem" }}>
          <Box display="flex" flexDirection="column" alignItems="center">
            <CustomAvatar
              style={{
                fontSize: "1.5rem",
                background: nightShift,
                color: black,
                marginBottom: "1rem",
              }}
              firstName={firstName}
              lastName={lastName}
              email={email}
              height="3.75rem"
              width="3.75rem"
            />
            <Typography variant="body1" fontWeight={500} mb={0.5}>
              {fullName}
            </Typography>
            <Grid
              container
              alignItems="center"
              justifyContent="center"
              flexDirection="column"
              mb={2}
            >
              <Grid container gap={1} alignItems="center" justifyContent="center">
                <Typography variant="body2" color={darkGray}>
                  {email}
                </Typography>
              </Grid>
              {emailChangeConfirmationRequired && (
                <Box mt={2} mb={1} maxWidth="280px">
                  <Alert severity="warning">
                    Email updated! Please check you email to confirm.
                  </Alert>
                </Box>
              )}
            </Grid>
          </Box>
          {!userIsAdmin && (
            <Box mb={1}>
              <CustomButton
                color="secondary"
                onClick={() => dispatch(setEditSettingsModalOpen(true))}
                variant="outlined"
                trackingLabel="Edit Settings"
              >
                <Typography variant="body1" fontWeight={500}>
                  Edit Settings
                </Typography>
              </CustomButton>
            </Box>
          )}
          <Divider />
          <MenuItem
            onClick={() => void handleLogOut()}
            sx={{ marginTop: "1rem", padding: "0.5rem" }}
          >
            <LogoutIcon sx={{ marginRight: "0.875rem" }} />
            Log out
          </MenuItem>
        </Box>
      </Menu>
    </Box>
  );
};
