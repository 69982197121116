import { useEffect, useMemo, useState } from "react";

import { orderBy } from "lodash";

import { Box, Typography } from "@mui/material";

import { useBulkSaveStaffDetails } from "~/api/staffDetails/mutations";
import CustomModal from "~/common/components/Modal";
import { useAppDispatch, useAppSelector } from "~/common/hooks/useRedux";
import { useToast } from "~/common/hooks/useToast";

import { useAppConfigQuery } from "#/features/User/queries";
import {
  Attribute,
  AttributeKey,
  StaffDetails,
  TBulkSaveStaffDetails,
  useInvalidateQuery,
  useListStaffDetailsQuery,
} from "@/api";
import { CustomButton, CustomSelect } from "@/common/components";
import { ICustomSelectItem } from "@/common/components/TrackedComponents/Select/types";
import { NOT_EXISTING_UUID } from "@/common/constants";
import {
  useCurrentFacilityId,
  useCurrentSelectedUnitId,
  useFilterBy,
  useMap,
} from "@/common/hooks";
import { Uuid } from "@/common/types";

import { setIsEditAttributeEligibilityModalOpen } from "../../store";

const emptyStaffDetailsDtosArray: StaffDetails.DTO[] = [];

export const EditAttributeEligibilityModal = () => {
  // High- level state
  const dispatch = useAppDispatch();
  const { showSuccess, showError } = useToast();
  const invalidateQuery = useInvalidateQuery();

  // Local state
  const isEditAttributeEligibilityOpen = useAppSelector(
    (state) => state.roster.editAttributeEligibilityModal.isOpen,
  );
  const handleSecondaryBtnClick = () => {
    dispatch(setIsEditAttributeEligibilityModalOpen(false));
  };

  // Unit Data
  const unitsDTOs = useAppConfigQuery().data?.accessibleUnits;
  const currentFacilityId = useCurrentFacilityId();
  const currentUnitId = useCurrentSelectedUnitId();

  // Filter units by current facility
  const facilityUnits = useFilterBy(unitsDTOs, (unit) => unit.facilityId === currentFacilityId, [
    currentFacilityId,
  ]);
  // All options for custom select
  const unitsCustomSelect: ICustomSelectItem<Uuid>[] = useMap(facilityUnits, (unit) => {
    return { ...unit, value: unit.id, label: unit.name, item: unit.id } as ICustomSelectItem<Uuid>;
  });
  // Selected Value
  const [selectedUnitId, setSelectedUnitId] = useState<Uuid | undefined>(undefined);

  // Attributes / Positions Data
  const unitDTO = facilityUnits?.find((unit) => unit.id === selectedUnitId);
  const attributesCustomSelect: ICustomSelectItem<string>[] = useMap(
    unitDTO?.attributes || [],
    (attribute) => {
      return {
        ...attribute,
        value: attribute.key,
        label: attribute.name,
        item: attribute.key,
      } as ICustomSelectItem<string>;
    },
  );
  // Selected value
  const [selectedAttributeKey, setSelectedAttributeKey] = useState<string | undefined>(undefined);

  // Staff Data
  // Include only active staff from HOME unit (currentUnitId)
  const { data: staffDetailDtos = emptyStaffDetailsDtosArray, isLoading: isStaffLoading } =
    useListStaffDetailsQuery(
      { homeUnitIds: [currentUnitId || NOT_EXISTING_UUID], active: [true] },
      { skip: !currentUnitId },
    );
  // All options for custom select
  const staffItemsCustomSelect: ICustomSelectItem<Uuid>[] = useMap(staffDetailDtos, (staff) => {
    return {
      ...staff,
      value: staff.userId,
      label: `${staff.user.lastName} ${staff.user.firstName}, ${staff.staffType.name}`,
      item: staff.userId,
    } as ICustomSelectItem<Uuid>;
  });
  // Selected values
  // Default selected based on data
  const initialUsers = useMemo(() => {
    return staffDetailDtos
      .filter((staff) => staff.attributeKeys.includes((selectedAttributeKey || "") as AttributeKey))
      .map((staff) => staff.userId);
  }, [staffDetailDtos, selectedAttributeKey]);
  const [selectedUsers, setFilteredUsers] = useState<Uuid[]>(initialUsers);

  // Set current unit as default
  useEffect(() => {
    setSelectedUnitId(currentUnitId);
  }, [currentUnitId]);

  // Update filteredUsers when selectedUnit changes
  useEffect(() => {
    setFilteredUsers(initialUsers);
  }, [initialUsers]);

  // Update selectedAttribute when selectedUnit changes
  useEffect(() => {
    setSelectedAttributeKey(undefined);
  }, [selectedUnitId]);

  // Content
  const modalContent = (
    <Box mt={3}>
      <CustomSelect<Uuid>
        items={unitsCustomSelect}
        label="Select unit"
        value={selectedUnitId}
        onChange={(event) => setSelectedUnitId(event.target.value)}
        sx={{ mb: 3 }}
      />
      <CustomSelect<string>
        items={attributesCustomSelect}
        label="Select positions"
        value={selectedAttributeKey}
        onChange={(event) => setSelectedAttributeKey(event.target.value)}
        sx={{ mb: 3 }}
      />
      <Typography sx={{ mb: 2 }}>
        Select or deselect staff to be eligible for the positions to unit above:
      </Typography>
      <Box maxWidth={"500px"}>
        <CustomSelect<Uuid>
          multiple
          checked
          customDisplayOption={`${selectedUsers.length} Users Selected`}
          items={staffItemsCustomSelect}
          label="Staff eligible to for positions to unit"
          value={selectedUsers}
          onChange={(event) => {
            const values = Array.isArray(event.target.value)
              ? event.target.value
              : [event.target.value];
            setFilteredUsers(values as Uuid[]);
          }}
          sx={{ mb: 3 }}
          disabled={!selectedAttributeKey || !selectedUnitId}
          displayEmpty
        />
      </Box>
      <CustomButton
        label={"Select All"}
        onClick={() => setFilteredUsers(staffDetailDtos.map((staff) => staff.userId))}
        type="submit"
      />
    </Box>
  );

  // Mutation to alter data
  const { mutate: updateStaffDetails, isPending: isSubmitting } = useBulkSaveStaffDetails({
    onSuccess: () => {
      showSuccess("Position eligibility updated successfully.");
      invalidateQuery(useListStaffDetailsQuery);
    },
    onError: () => {
      showError("Failed to update position eligibility.");
    },
  });

  const saveAttributeEligibility = () => {
    if (selectedUnitId) {
      const staffDetailsToChange: TBulkSaveStaffDetails = [];

      // Add attribute to staff newly eligible
      selectedUsers.forEach((userId) => {
        if (!initialUsers.includes(userId)) {
          staffDetailsToChange.push({
            userId,
            attributeKeys: [
              ...(staffDetailDtos.find((staff) => staff.userId === userId)?.attributeKeys || []),
              selectedAttributeKey,
            ] as Attribute.DTO["key"][],
          });
        }
      });

      // Remove attribute from staff no longer eligible
      initialUsers.forEach((userId) => {
        if (!selectedUsers.includes(userId)) {
          const currentAttributeKeys =
            staffDetailDtos.find((staff) => staff.userId === userId)?.attributeKeys || [];
          staffDetailsToChange.push({
            userId,
            attributeKeys: currentAttributeKeys.filter(
              (attrKey) => attrKey !== selectedAttributeKey,
            ),
          });
        }
      });

      updateStaffDetails({
        staffDetails: staffDetailsToChange,
      });
    }
  };

  if (!currentUnitId || isStaffLoading) return null;

  return (
    <CustomModal
      isOpen={isEditAttributeEligibilityOpen}
      primaryBtnText="Save"
      modalContent={modalContent}
      modalHeaderText={"Edit Position Eligibility"}
      onSubmit={saveAttributeEligibility}
      onSecondaryBtnClick={handleSecondaryBtnClick}
      // disabled until you edit something aka change the selected users
      // (can't just check length because could be same length but different users)
      primaryDisabled={
        !selectedUnitId ||
        JSON.stringify(orderBy(selectedUsers)) ===
          JSON.stringify(orderBy(initialUsers) || isSubmitting)
      }
    />
  );
};
