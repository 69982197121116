import {
  BrandedAlias,
  ISODateString,
  Millisecond as M7Milliseconds,
  Second as M7Seconds,
  TimeString as M7TimeString,
  Timezone as M7Timezone,
  Uuid as M7Uuid,
  YyyyMmDd as M7YyyyMmDd,
} from "@m7-health/shared-utils";
import { List, ListIterateeCustom } from "lodash";

import { SxProps, Theme } from "@mui/material";

import { StaffCategory } from "~/api";

import { IStaffCategory as IStaffCategoryApi, IStaffType as IStaffTypeApi } from "@/api";

import { Dayjs } from "./packages/dayjs";

export interface ITable<T> {
  data: Array<T>;
  total: number;
  take: number;
  skip: number;
}

export interface CustomError {
  statusCode: number;
  message: string | Array<{ error: string; field: string }>;
  error: string;
  type: "customError";
}

export const isCustomError = (error: any): error is CustomError => {
  return error.type === "customError";
};

export interface IDictionaryValue {
  label: string;
  value: string;
}

export interface IShiftTypeDictionaryValue extends IDictionaryValue {
  schedulerAssignableShift: boolean;
  staffPreferableShift: boolean;
}

export type EStaffTypeKey = IStaffTypeApi["key"];
export type EStaffCategoryKey = IStaffCategoryApi["key"];
export type EStaffTypeName = IStaffTypeApi["name"];
export type EStaffCategoryName = IStaffCategoryApi["name"];

export type Uuid = M7Uuid | string;
/** @deprecated use ISODateString from m7Shared lib instead */
export type dateString = ISODateString;
export type TDateFilter = {
  value: YyyyMmDd | dateString;
  operator?: "eq" | "gt" | "lt" | "gte" | "lte";
};
export type seconds = M7Seconds;
export type milliseconds = M7Milliseconds;
export type YyyyMmDd = M7YyyyMmDd | string;
/** @deprecated use Timezone from shared-utils */
export type Timezone = M7Timezone;
// TODO: add to shared-utils
export type MmDdYy = BrandedAlias<string> | string;

/* TimeString custom type
 * Time string must be in the format: "HH:MM:SS.mmm"
 * valid times: "00:00:00.000" - "23:59:59.999"
 * TS limitation: also supported times: "0:0:0.0", "0.02:0:0.0", "42.42:1:42.42"
 * invalid times: "abc", "12 pm", "12:00", "12:12:12"
 */

// we can't use zeroTo60 for hours and seconds because it will create a crazy big
//  union type: 60 * 60 * 24 = 86400.
// But it allows us to enforce it on the seconds + enforce milliseconds
//  even though ms can still be 0 or 1111 which are both invalid.
type zeroTo5 = 0 | 1 | 2 | 3 | 4 | 5;
type zeroTo9 = zeroTo5 | 6 | 7 | 8 | 9;
type zeroTo60 = `${zeroTo5}${zeroTo9}`;
export type LegacyTimeString =
  | `${number}:${number}:${zeroTo60}.${number}`
  | `${number}:${number}:${zeroTo60}`;
export type TimeString = M7TimeString | LegacyTimeString;

export type TSx = SxProps<Theme>;
export const emptySx: TSx = {} as const;
export const emptyArray = [] as const;

// Monkey patch lodash filter to allow a list of maybe T to be filtered to a list of T!
// _.filter([0, "", undefined, null, false, 1]) => [1]
type Falsy = false | 0 | "" | null | undefined;
declare module "lodash" {
  interface LoDashStatic {
    filter<T>(collection: List<T>, predicate?: undefined): Exclude<T, Falsy>[];
    filter<T>(
      collection: List<T> | Falsy,
      predicate?: ListIterateeCustom<T, boolean>,
    ): Exclude<T, undefined | null>[];

    keyBy<T, K extends keyof T>(collection: List<T> | null | undefined, iteratee?: K): KeyBy<T, K>;
    groupBy<T, K extends keyof T>(
      collection: List<T> | null | undefined,
      iteratee?: K,
    ): {
      [key in T[K] & (string | number | symbol)]: T[];
    };
    groupBy<T, K>(
      collection: List<T> | null | undefined,
      iteratee: (arg: T) => K,
    ): {
      [key in K & (string | number | symbol)]: T[];
    };

    entries<T, K extends keyof T>(object?: T): Array<[K, T[K]]>;
    keys<T, K extends keyof T>(object: T): Array<K>;
  }
}

export type AnyDate = string | number | Date | Dayjs | null | undefined;

export type IStaffType = {
  key: EStaffTypeKey;
  name: EStaffTypeName;
  staffCategoryKey: EStaffCategoryKey | StaffCategory.EKey;
  usedForTargetLevel: boolean;
};

export type IStaffCategory = {
  key: EStaffCategoryKey;
  name: EStaffCategoryName;
  usedForTargetLevel: boolean;
};

export type KeyBy<T, K extends keyof T> =
  NonNullable<T[K]> extends string | number | symbol
    ? {
        [key in NonNullable<T[K]>]: T;
      }
    : never;

export type NotNullKeyBy<T, K extends keyof T> = T[K] extends string | number | symbol
  ? {
      [key in T[K]]: T;
    }
  : never;

/**
 * Extracts the leaf values from a nested object in a union type.
 */
export type LeafValues<T> = T extends object ? { [K in keyof T]: LeafValues<T[K]> }[keyof T] : T;

export type OptionalKeys<T> = NonNullable<
  {
    [K in keyof T]: T[K] | undefined extends T[K] ? K : never;
  }[keyof T]
>;
export type RequiredKeys<T> = NonNullable<
  {
    [K in keyof T]: T[K] | undefined extends T[K] ? never : K;
  }[keyof T]
>;

export type OmitOptional<T> = Omit<NonNullable<T>, OptionalKeys<T>>;

export type TMaybeArray<T> = T | T[];

export { type BrandedAlias as brandedAlias };

export type ExtractStrict<T, U extends T> = Extract<T, U>;

const anyEmptyArray: unknown[] = [];
export const TEmptyArray = <T>() => anyEmptyArray as T[];
