import { TextField, Typography } from "@mui/material";

import { TDataComponent } from "./types";

export const StringField: TDataComponent<string> = {
  editor: ({ value, updateCell, onBlur, focused }) => {
    return (
      <>
        <TextField
          autoFocus={focused}
          value={value || ""}
          onChange={(event) => updateCell(event.target.value)}
          size="small"
          variant="standard"
          onBlur={onBlur}
        />
      </>
    );
  },
  readonly: ({ value }) => <Typography>{value}</Typography>,
};
