import { ECommunicationMethod, EUnitPermissionAreas, ISODateString } from "@m7-health/shared-utils";

import { IPreferencesTemplate, IUser, StaffDetails } from "~/api";
import { IPreferenceRequirementRuleSet } from "~/api/preferenceRequirementRuleSet";
import { Uuid } from "~/common/types";
import { IShift } from "~/routes/api/types";

import { User } from "@/api";

import { IBulkCreateUsersState } from "./store/bulkCreateUsers";

export interface IStaff {
  attributeKeys: string[];
  chargeEligibility: boolean;
  contractEndDate: string;
  email: string;
  employmentStartDate: string;
  employmentType: StaffDetails.EEmploymentType;
  firstName: string;
  // TODO: Should be Uuid, required. If somewhere a user can not have an id (e.g. when creating a new user),
  //  use an extended type from this one.
  id?: Uuid;
  lastName: string;
  maxConsecutiveShifts: number;
  maxRequestsOffPerSchedule: number;
  maxShiftsPerWeek: number;
  minShiftsPerWeek: number;
  moreThanOneDayBetweenShifts: boolean;
  onOrientation: boolean;
  orientationEndDate: string;
  preceptor: boolean;
  phoneNumber: string;
  role: User.ERole.staff;
  shiftType: EStaffShiftType;
  staffTypeName: string;
  status: EStaffStatus;
  totalShiftsRequiredPerSchedule: number;
  totalWeekendShiftsRequiredPerSchedule: number;
  workingMultipleShiftTypes: boolean;
  unitAssignment: string[];
  staffUnitAssignment: string[];
  openShiftsToView: IShift["id"][] | null;
  homeUnitId?: Uuid;
  sendResetPasswordMethod?: ECommunicationMethod;
}

export interface IScheduler {
  email: string;
  firstName: string;
  id?: string;
  lastName: string;
  phoneNumber: string;
  role: User.ERole.scheduler;
  unitAssignment: string[];
  schedulerUnitAssignment: string[];
}

export interface IEditUser {
  chargeEligibility?: boolean;
  contractEndDate?: string;
  employmentStartDate?: string;
  email: string;
  employmentType?: StaffDetails.EEmploymentType;
  firstName: string;
  id: string;
  lastName: string;
  moreThanOneDayBetweenShifts?: boolean;
  maxConsecutiveShifts?: number;
  maxRequestsOffPerSchedule?: number;
  minShiftsPerWeek?: number;
  maxShiftsPerWeek?: number;
  totalShiftsRequiredPerSchedule?: number;
  totalWeekendShiftsRequiredPerSchedule?: number;
  preferenceRequirementRuleSetIds?: Uuid[];
  onOrientation?: boolean;
  orientationEndDate?: string;
  phoneNumber: string;
  preceptor?: boolean;
  roles: User.ERole[];
  shiftType?: EStaffShiftType;
  status?: string;
  units: string[];
  staffUnitAssignment: string[];
  schedulerUnitAssignment: string[];
  userType?: User.ERole;
  staffTypeName: string;
  rosters: IBareRosterItem[];
  preferencesTemplateId?: IPreferencesTemplate["id"];
}

export interface IBareRosterItem {
  deletedAt: ISODateString | null;
  unitId: Uuid;
  roleId: number;
  userId: Uuid;
  staffDetailsId: number;
  id: number;
  // Values that exists but unused, so not typed
  // Unit
  // notificationPreferences: ,
  // entityPreferences: ,
}

export interface IRosterPermission {
  id: string;
  rosterId: string;
  area: string;
  permission: string;
  roster: IRosterItem;
}

export interface IGetRosterPermissions {
  data: IRosterPermission[];
}

export type TCreateStaffDto = Omit<IStaff, "unitAssignment" | "staffUnitAssignment"> & {
  unitIds: string[];
};
export type TCreateSchedulerDto = Omit<IScheduler, "unitAssignment" | "schedulerUnitAssignment"> & {
  unitIds: string[];
};

export interface IRosterItem {
  chargeEligibility?: boolean;
  contractEndDate?: string;
  email: string;
  emailVerified: boolean;
  employmentStartDate?: string;
  employmentType?: StaffDetails.EEmploymentType;
  experience?: string;
  externalStaffIdentifier?: string | null;
  firstName: string;
  id: string;
  unitId?: string;
  lastName: string;
  maxConsecutiveShifts?: number;
  maxRequestsOffPerSchedule?: number;
  maxShiftsPerWeek?: number;
  minShiftsPerWeek?: number;
  postDateStatusUpdateDate?: ISODateString | null;
  postDateStatusUpdateType?: "inactive" | "suspended" | null;
  moreThanOneDayBetweenShifts?: boolean;
  name?: string;
  note?: string;
  onOrientation?: boolean;
  orientationEndDate?: string | null;
  phoneNumber?: string;
  preceptor?: boolean;
  roles?: string;
  shiftType?: EStaffShiftType;
  staffTypeName?: string;
  status?: EStaffStatus;
  totalShiftsRequiredPerSchedule?: number;
  totalWeekendShiftsRequiredPerSchedule?: number;
  userType: User.ERole.staff | User.ERole.scheduler | User.ERole.kiosk;
  softDeletedDate?: string;
  workingMultipleShiftTypes?: boolean;
  attributeKeys?: string[];
  homeUnitId?: Uuid;
  roleId?: number;
  lastLoggedInAt?: ISODateString | null;
}

// ALL details of staffs in schedule, not all of this goes to scheduleSchemaResponse
// should be used as DTO when we use staff data that comes from roster itself (vs. IStaffMinimalDetailsDto)

// compare with IStaffMinimalDetailsDto
export interface IStaffWithDetailsDto {
  chargeEligibility: boolean;
  contractEndDate: string;
  email: string;
  emailVerified: boolean;
  employmentStartDate: string;
  employmentType: StaffDetails.EEmploymentType;
  experience: string;
  externalStaffIdentifier: string | null;
  firstName: string;
  id: string;
  lastName: string;
  maxConsecutiveShifts: number;
  maxRequestsOffPerSchedule: number;
  maxShiftsPerWeek: number;
  minShiftsPerWeek: number;
  moreThanOneDayBetweenShifts: boolean;
  note: string;
  onOrientation: boolean;
  orientationEndDate?: string | null;
  phoneNumber: string;
  preceptor: boolean;
  shiftType: EStaffShiftType;
  staffTypeName: string;
  status: EStaffStatus;
  totalShiftsRequiredPerSchedule: number;
  totalWeekendShiftsRequiredPerSchedule: number;
  userType: User.ERole.staff;
  softDeletedDate?: string;
  workingMultipleShiftTypes?: boolean;
  attributeKeys?: string[];
  homeUnitId: Uuid;
  // list of units that the staff is a part of, including its home unit
  unitAssignment?: Uuid[];
}

export type TStaffWithDetailsNoUnitAssignmentDto = Omit<IStaffWithDetailsDto, "unitAssignment">;

export interface ISchedulerDto {
  email: string;
  emailVerified: boolean;
  firstName: string;
  id: string;
  lastName: string;
  userType: User.ERole.scheduler;
}

export interface IKioskDto {
  email: string;
  emailVerified: boolean;
  firstName: string;
  id: string;
  lastName: string;
  userType: User.ERole.kiosk;
}

export type TRosterItem = IStaffWithDetailsDto | ISchedulerDto | IKioskDto;

export const isStaff = (item: TRosterItem): item is IStaffWithDetailsDto => {
  return (item as IStaffWithDetailsDto).status !== undefined;
};

export interface INoteModalData {
  firstName?: string;
  id?: string;
  lastName?: string;
  note?: string;
}

export interface ISuspendModalData {
  id: string;
  name: string;
  skipPostDate?: boolean;
}
export interface IRestoredModalData {
  id: string;
  name: string;
}

export interface ISingleSendSMSModalData {
  firstName: string;
  lastName: string;
  id: string;
  label?: string;
}

export type RosterIdsByEntityOption = {
  [entityOptionName: string]: number[];
};

export interface IRosterState {
  addUserModal: {
    isOpen: boolean;
  };
  staffRosterFiltersModal: {
    isOpen: boolean;
  };
  massSendSmsModal: {
    isOpen: boolean;
  };
  singleSendSmsModal: {
    isOpen: boolean;
    preSelectedUserToSms: ISingleSendSMSModalData | undefined;
  };
  editSettingsModal: {
    isOpen: boolean;
    selectedSMSRosterIds: number[];
    selectedEmailRosterIds: number[];
    selectedRosterIdsByEntityOption: RosterIdsByEntityOption;
  };
  editUserModal: {
    id: string;
    isOpen: boolean;
    userType: User.ERole.staff | User.ERole.scheduler | null;
  };
  noteModal: {
    data: INoteModalData;
    isOpen: boolean;
    variant: "add" | "edit";
  };
  statusChangeModal: {
    isOpen: boolean;
    data: {
      name: string;
      id: string;
      status: EStaffStatus;
      postDatedExistingStatus: "inactive" | "suspended" | null;
      postDatedExistingDate: ISODateString | null;
    };
  };
  suspendAccountModal: {
    isOpen: boolean;
    data: {
      skipPostDate?: boolean;
      name: string;
      id: string;
    };
  };
  updateSuspensionDateModal: {
    isOpen: boolean;
    data?: {
      name: string;
      id: string;
      date: ISODateString;
      status: "inactive" | "suspended" | null;
    };
  };
  updateStatusDateModal: {
    isOpen: boolean;
    data?: {
      name: string;
      id: string;
      date: ISODateString;
      status: "inactive" | "suspended" | null;
    };
  };
  restoreAccountModal: {
    isOpen: boolean;
    data: {
      name: string;
      id: string;
    };
  };
  sendResetPasswordModal: {
    isOpen: boolean;
    userIds?: IUser["id"][];
  };
  bulkUsersUploadModal: { isOpen: boolean };
  editFloatEligibilityModal: { isOpen: boolean };
  editAttributeEligibilityModal: { isOpen: boolean };
  updateRuleSetsModal: { isOpen: boolean };
  updateRuleSetModal: { isOpen: boolean; ruleSetToUpdate?: IPreferenceRequirementRuleSet | null };
  updateRuleSetUsersModal: { isOpen: boolean; ruleSetToUpdate?: IPreferenceRequirementRuleSet };
  updatePreferencesTemplatesModal: { isOpen: boolean };
  updatePreferencesTemplateModal: {
    isOpen: boolean;
    preferencesTemplateToUpdate?: IPreferencesTemplate | null;
  };
  updatePreferencesTemplateUsersModal: {
    isOpen: boolean;
    preferencesTemplateToUpdate?: IPreferencesTemplate;
  };
  applyPreferencesTemplatesModal: {
    isOpen: boolean;
  };
  rosterTable: {
    pageSize: number;
  };
  permissions: {
    permissionsReadyToSubmit: boolean;
    permissionsByUnit: Record<string, Record<EUnitPermissionAreas, string>>;
    houseViewPermission: string;
    schedulerRosterPermission: string;
    shiftReportsPermission: string;
  };
  bulkCreateUsers: IBulkCreateUsersState;
  staffRosterfilters: {
    staffTypes: string[];
    employmentTypes: string[];
  };
  bulkUpdateStaff: {
    isActive: boolean;
  };
}

export interface IRosterListSelectFields<T> {
  label: string;
  value: T;
}

export enum EStaffStatus {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  Active = "active",
  // eslint-disable-next-line @typescript-eslint/naming-convention
  Inactive = "inactive",
}

// TODO ?? replace with DeprecatedTShift?
export enum EStaffShiftType {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  Day = "day",
  // eslint-disable-next-line @typescript-eslint/naming-convention
  Night = "night",
  // eslint-disable-next-line @typescript-eslint/naming-convention
  Both = "both",
}

export enum EStaffRosterPageTabs {
  homeUnitUsers = "home_unit_users",
  suspendedUsers = "suspended_users",
  otherUnitUsers = "other_unit_users",
}

export enum ESchedulerRosterTabs {
  activeSchedulers = "active_schedulers",
  suspendedSchedulers = "suspended_schedulers",
}

export enum ESchedulerRosterPages {
  schedulerDetails = "scheduler_details",
  schedulerPermissions = "scheduler_permissions",
}

export enum EStaffRosterPages {
  staffDetails = "staff_details",
}
