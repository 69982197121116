import { ParserError } from "../DataParser";

import { TDataParser } from "./types";

export const booleanParser: TDataParser<boolean> = (value) => {
  if (value === null || value === undefined || value === "") return null;

  const maybeBoolean = value.toString().toLowerCase();
  if (["true", "t", "y", "yes", "on"].includes(maybeBoolean)) return true;

  if (["false", "f", "n", "no", "off"].includes(maybeBoolean)) return false;

  throw new ParserError("Invalid boolean value");
};
