import { useMemo } from "react";

import { Uuid } from "~/common/types";

import { IStaffAttribute } from "../api/types";
import { useAllStaffAttributesQuery, useStaffAttributesForUnitCustomSelectQuery } from "../queries";

export const useStaffAttributesByKeyForUnit = (unitId?: Uuid): Record<string, IStaffAttribute> => {
  const { data: staffAttributesForUnit = [] } = useStaffAttributesForUnitCustomSelectQuery(unitId);

  return useMemo(
    () =>
      staffAttributesForUnit.reduce(
        (acc, attribute) => {
          acc[attribute.key] = attribute;
          return acc;
        },
        {} as Record<string, IStaffAttribute>,
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [JSON.stringify(staffAttributesForUnit)],
  );
};

export const useAllStaffAttributesByKey = (): Record<string, IStaffAttribute> => {
  const { data: allStaffAttributes } = useAllStaffAttributesQuery();

  return useMemo(
    () =>
      (allStaffAttributes || []).reduce(
        (acc, attribute) => {
          acc[attribute.key] = attribute;
          return acc;
        },
        {} as Record<string, IStaffAttribute>,
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [JSON.stringify(allStaffAttributes)],
  );
};
