import { useCallback, useEffect, useMemo, useState } from "react";

import { throttle } from "lodash";

const getScrollableElement = () =>
  document
    .getElementsByClassName("bulk-create-users-table")?.[0]
    ?.getElementsByClassName("table-container")[0];
const itemRowHeight = 42;

export const useLazyLoadRows = (rowsLength: number) => {
  const [rowDisplayStart, setRowDisplayStart] = useState<number>(0);
  const [rowDisplayEnd, setRowDisplayEnd] = useState<number>(0);
  const [scrollPosition, setScrollPosition] = useState({
    y: 0,
  });

  // Constants
  const screenHeight = Math.max(
    getScrollableElement()?.clientHeight || 0,
    document.documentElement.clientHeight || 0,
    window.innerHeight || 0,
  );
  const offsetY = screenHeight;
  const rowsToRender = Math.floor((screenHeight * 1.2) / itemRowHeight);

  // Main function
  const setDisplayPositions = useCallback(
    (scrollY: number) => {
      // we want to start rendering a bit above the visible screen
      const scrollHeightOffset = Math.floor(scrollY - offsetY / 4);

      // start position should never be less than 0
      const rowDisplayStartPosition = Math.round(
        Math.max(0, Math.floor(scrollHeightOffset / itemRowHeight)),
      );

      // end position should never be larger than our data array
      const rowDisplayEndPosition = rowDisplayStartPosition + rowsToRender;

      setRowDisplayStart(rowDisplayStartPosition);
      setRowDisplayEnd(rowDisplayEndPosition);
    },
    [offsetY, rowsToRender],
  );

  // On scroll, trigger position updates
  useEffect(() => {
    const scrollableElement = getScrollableElement();

    const onScroll = throttle(() => {
      const scrollTop = scrollableElement?.scrollTop || 0;
      setScrollPosition({ y: scrollTop });
      setDisplayPositions(scrollTop);
    }, 100);

    scrollableElement?.addEventListener("scroll", onScroll);

    return () => {
      scrollableElement?.removeEventListener("scroll", onScroll);
    };
  }, [setDisplayPositions, rowsLength]);

  // triggers on initial render
  useEffect(() => {
    setDisplayPositions(scrollPosition.y);
  }, [scrollPosition, setDisplayPositions, rowsLength]);

  return useMemo(
    () => ({
      rowDisplayStart,
      rowDisplayEnd,
      itemRowHeight,
      rowsToRender,
      screenHeight,
      offsetY,
      rowsLength,
    }),
    [offsetY, rowDisplayEnd, rowDisplayStart, rowsToRender, screenHeight, rowsLength],
  );
};
export type TLazyLoadingIndexes = ReturnType<typeof useLazyLoadRows>;
