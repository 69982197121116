import { isEqual } from "lodash";

import { useAppSelector } from "@/common/hooks";

import NoteModal from "../StaffRoster/NoteModal";

import { ApplyPreferencesTemplateModal } from "./ApplyPreferencesTemplateModal/ApplyPreferencesTemplateModal";
import BulkUserUploadModal from "./BulkUserUploadModal";
import EditAttributeEligibilityModal from "./EditAttributeEligibilityModal";
import EditFloatEligibilityModal from "./EditFloatEligibilityModal";
import RestoreUserModal from "./RestoreUserModal/RestoreUserModal";
import { SendResetPasswordModal } from "./SendResetPasswordModal/SendResetPasswordModal";
import { StaffRosterFiltersModal } from "./StaffRosterFiltersModal";
import StaffUserModal from "./StaffUserModal";
import StatusChangeModal from "./StatusChangeModal";
import SuspendUserModal from "./SuspendUserModal";
import { UpdatePreferencesTemplateModal } from "./UpdatePreferencesTemplateModal/UpdatePreferencesTemplateModal";
import { UpdatePreferencesTemplatesModal } from "./UpdatePreferencesTemplatesModal/UpdatePreferencesTemplatesModal";
import { UpdatePreferencesTemplateUsersModal } from "./UpdatePreferencesTemplateUsersModal/UpdatePreferencesTemplateUsersModal";
import { UpdateRuleSetModal } from "./UpdateRuleSetModal/UpdateRuleSetModal";
import { UpdateRuleSetsModal } from "./UpdateRuleSetsModal/UpdateRuleSetsModal";
import { UpdateRuleSetUsersModal } from "./UpdateRuleSetUsersModal/UpdateRuleSetUsersModal";
import UpdateStatusDateModal from "./UpdateStatusDateModal";
import UpdateSuspensionDateModal from "./UpdateSuspensionDateModal";

// Also used in scheduler grid, might change in the future, so we keep them here for now
export {
  UpdatePreferencesTemplateModal,
  UpdatePreferencesTemplatesModal,
  UpdatePreferencesTemplateUsersModal,
};

export const RosterModals = () => {
  const {
    isUpdateRuleSetsModalOpen,
    isUpdateRuleSetModalOpen,
    isUpdateRuleSetUsersModalOpen,
    isEditUserModalOpen,
    isAddUserModalOpen,
    isUpdatePreferencesTemplateModalOpen,
    isUpdatePreferencesTemplatesModalOpen,
    isUpdatePreferencesTemplateUsersModalOpen,
    isApplyPreferencesTemplateModal,
    isSendResetPasswordModalOpen,
    isStaffRosterFiltersModalOpen,
  } = useAppSelector(
    (state) => ({
      isEditUserModalOpen: state.roster.editUserModal.isOpen,
      isAddUserModalOpen: state.roster.addUserModal.isOpen,
      isUpdateRuleSetsModalOpen: state.roster.updateRuleSetsModal.isOpen,
      isUpdateRuleSetModalOpen: state.roster.updateRuleSetModal.isOpen,
      isUpdateRuleSetUsersModalOpen: state.roster.updateRuleSetUsersModal.isOpen,
      isUpdatePreferencesTemplateModalOpen: state.roster.updatePreferencesTemplateModal.isOpen,
      isUpdatePreferencesTemplatesModalOpen: state.roster.updatePreferencesTemplatesModal.isOpen,
      isUpdatePreferencesTemplateUsersModalOpen:
        state.roster.updatePreferencesTemplateUsersModal.isOpen,
      isApplyPreferencesTemplateModal: state.roster.applyPreferencesTemplatesModal.isOpen,
      isSendResetPasswordModalOpen: state.roster.sendResetPasswordModal.isOpen,
      isStaffRosterFiltersModalOpen: state.roster.staffRosterFiltersModal.isOpen,
    }),
    isEqual,
  );

  return (
    <>
      {(isEditUserModalOpen || isAddUserModalOpen) && <StaffUserModal />}
      <NoteModal />
      <StatusChangeModal />
      <BulkUserUploadModal />
      <SuspendUserModal />
      <RestoreUserModal />
      <EditFloatEligibilityModal />
      <EditAttributeEligibilityModal />
      <UpdateStatusDateModal />
      <UpdateSuspensionDateModal />

      {/* Rule sets */}
      {isUpdateRuleSetsModalOpen && <UpdateRuleSetsModal />}
      {isUpdateRuleSetModalOpen && <UpdateRuleSetModal />}
      {isUpdateRuleSetUsersModalOpen && <UpdateRuleSetUsersModal />}

      {/* Preferences templates */}
      {isUpdatePreferencesTemplateModalOpen && <UpdatePreferencesTemplateModal />}
      {isUpdatePreferencesTemplatesModalOpen && <UpdatePreferencesTemplatesModal />}
      {isUpdatePreferencesTemplateUsersModalOpen && <UpdatePreferencesTemplateUsersModal />}
      {isApplyPreferencesTemplateModal && <ApplyPreferencesTemplateModal />}
      {isSendResetPasswordModalOpen && <SendResetPasswordModal />}
      {isStaffRosterFiltersModalOpen && <StaffRosterFiltersModal />}
    </>
  );
};
