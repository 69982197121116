import { PayloadAction } from "@reduxjs/toolkit";

import { IFacility, StaffCategory } from "@/api";

import { TTimeTargetLevels } from "../components/sections/FacilityConfiguration/types";

import { THouseViewState } from ".";

export type TFacilityConfigurationState = {
  selectedStaffCategoryKey: StaffCategory.EKey;
  timeTargetLevels: TTimeTargetLevels;
};

export const FacilityConfigurationActionsInitState: TFacilityConfigurationState = {
  selectedStaffCategoryKey: StaffCategory.EKey.other,
  timeTargetLevels: {},
};

export const FacilityConfigurationActions = {
  setFacilitySelectedStaffCategoryKey: (
    { facilityConfiguration }: THouseViewState,
    { payload }: PayloadAction<StaffCategory.EKey>,
  ) => {
    facilityConfiguration.selectedStaffCategoryKey = payload;
  },

  setFacilityTimeTargetLevels: (
    { facilityConfiguration }: THouseViewState,
    { payload }: PayloadAction<React.SetStateAction<TTimeTargetLevels>>,
  ) => {
    if (typeof payload === "function") {
      facilityConfiguration.timeTargetLevels = payload(facilityConfiguration.timeTargetLevels);
    } else {
      facilityConfiguration.timeTargetLevels = payload;
    }
  },

  cancelAllFacilityConfigurationChanges: (
    { facilityConfiguration }: THouseViewState,
    { payload }: PayloadAction<IFacility>,
  ) => {
    facilityConfiguration.timeTargetLevels =
      payload.configuration?.data?.facilityTimeTargetLevelsByStaff ?? {};
  },
};
