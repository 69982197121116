import { useCallback, useMemo } from "react";

import { useQueryClient } from "@tanstack/react-query";

import AutorenewIcon from "@mui/icons-material/Autorenew";
import { Box, Typography } from "@mui/material";

import CustomButton from "~/common/components/TrackedComponents/Button";
import { localDayJs } from "~/common/packages/dayjs";
import { TSx, emptySx } from "~/common/types";

export const PageRefresher = ({ sx = emptySx, lastUpdated }: { lastUpdated?: Date; sx?: TSx }) => {
  const internalLastUpdated = useMemo(() => lastUpdated ?? new Date(), [lastUpdated]);
  const lastUpdatedString = useMemo(
    () => localDayJs(internalLastUpdated).calendar(),
    [internalLastUpdated],
  );
  const queryClient = useQueryClient();
  const lastUpdatedFormatted =
    (lastUpdatedString?.[0]?.toLowerCase() ?? "") + lastUpdatedString?.slice(1);

  const invalidateAllQueries = useCallback(() => {
    return Promise.all([
      queryClient.invalidateQueries(),
      queryClient.resetQueries(),
      queryClient.clear(),
    ]);
  }, [queryClient]);

  return (
    <Box mt={0.5} width={"100%"} sx={{ display: "flex", flexDirection: "row-reverse", ...sx }}>
      <Box sx={{}}>
        <CustomButton
          data-testid="refresh-page"
          size="small"
          iconOnly={true}
          trackingLabel="Refresh schedule"
          startIcon={<AutorenewIcon fontSize="small" />}
          sx={{
            top: "1px",
            ml: "2px",
            padding: "2px",
            border: "1px solid black",
            borderRadius: "5px",
            transform: "scale(0.8)",
          }}
          onClick={() => {
            void invalidateAllQueries().then(() => {
              window.location.reload();
            });
          }}
        />
      </Box>
      {lastUpdatedFormatted && (
        <Box sx={{}}>
          <Typography
            sx={{
              fontSize: "13px",
              userSelect: "none",
              textAlign: "right",
              padding: "5px 0",
            }}
          >
            Last refreshed {lastUpdatedFormatted}
          </Typography>
        </Box>
      )}
    </Box>
  );
};
