import { useCallback, useEffect, useMemo, useState } from "react";

import { isEqual } from "lodash";

import { IUnit, useInvalidateQuery, useListUnitsQuery } from "~/api";
import { useInvalidateLegacyQuery } from "~/api/_shared/hooks";
import { useAppConfigQuery, useStaffDetailsQuery } from "~/features/User/queries";

import { httpClient } from "../packages/httpClient";
import { setCurrentUnit } from "../store";

import { useCurrentRole } from "./useCurrentRole";
import { useAppDispatch, useAppSelector } from "./useRedux";

/**
 * Custom hook that returns the current unit ID based on the HEADER.
 * @returns The current unit ID or undefined if not found.
 */
export const useCurrentUnitId = () => {
  const [unitId, setUnitId] = useState<string | null>(httpClient.getUnitId());

  useEffect(() => {
    const handleUnitIdChange = () => setUnitId(httpClient.getUnitId());
    httpClient.eventEmitter.on("unitIdChanged", handleUnitIdChange);
    handleUnitIdChange();

    return () => {
      httpClient.eventEmitter.removeListener("unitIdChanged", handleUnitIdChange);
    };
  }, []);

  return (unitId as IUnit["id"]) || undefined;
};

/**
 * Custom hook that returns the current unit ID based on the user's roles, selected unit, and other data.
 * if staff: will get the home unit.
 * if scheduler: will get the selected unit you are on.
 * @returns The current unit or undefined if not found.
 */
export const useCurrentSelectedUnitId = () => {
  return useCurrentUnit()?.id;
};

/**
 * @returns The current unit set by the 'useSetCurrentUnit' hook.
 */
export const useCurrentUnit = () => {
  return useAppSelector((state) => state.common.currentUnit);
};

/**
 * Use this to refresh units in the store, for example when you update unit config.
 */
export const useRefreshUnitsStore = () => {
  const invalidateQuery = useInvalidateQuery();
  const invalidateLegacyQuery = useInvalidateLegacyQuery();

  return useCallback(() => {
    invalidateQuery(useListUnitsQuery);
    invalidateLegacyQuery("appConfig");
  }, [invalidateQuery, invalidateLegacyQuery]);
};

/**
 * Hook that Watches for changes in the selected unit and sets the current unit
 *  based on the user's roles.
 *
 * if staff: will get the home unit.
 * if scheduler: will get the selected unit you are on.
 *
 *
 * @returns  nothing. It sets up an effect that publish the current
 *  unit to the store when it changes.
 */
export const useSetCurrentUnit = () => {
  const dispatch = useAppDispatch();

  const selectedUnit = useAppSelector((state) => state.common.selectedUnit);
  const currentUnit = useAppSelector((state) => state.common.currentUnit);

  const { userIsStaff, userIsAdmin, userIsKiosk, userIsScheduler } = useCurrentRole();
  const { data: config, isLoading: isLoadingConfig } = useAppConfigQuery();
  const { data: unitsFromQuery } = useListUnitsQuery({}, { skip: userIsStaff });
  const units = useMemo(
    () => [...(unitsFromQuery || []), ...((config?.units as IUnit[] | undefined) || [])],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [config?.units, JSON.stringify(unitsFromQuery)],
  );
  const staffDetails = useStaffDetailsQuery(userIsStaff)?.data;

  const unitToSet = (() => {
    // If admin or scheduler, just return the selected unit from state
    if (userIsAdmin || userIsScheduler || userIsKiosk) {
      // make sure to refresh unit from api to get latest or find unit from config which will have the latest
      // else could get stale data or empty config for example
      const unitFromConfig = units.find((unit) => unit.id === selectedUnit?.id);
      return unitFromConfig || undefined;
    }

    return (
      (staffDetails?.homeUnitId && units?.find(({ id }) => id === staffDetails.homeUnitId)) ||
      undefined
    );
  })();

  useEffect(() => {
    if (isLoadingConfig) return;

    const sameId = unitToSet?.id === currentUnit?.id;
    const sameConfig = isEqual(unitToSet?.configuration, currentUnit?.configuration);
    const sameUnit = sameId && sameConfig;
    if (sameUnit) return;

    dispatch(setCurrentUnit(unitToSet || null));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    currentUnit?.id,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    JSON.stringify(currentUnit?.configuration),
    dispatch,
    unitToSet,
    isLoadingConfig,
  ]);
};
