import { useEffect, useState } from "react";

import { orderBy } from "lodash";

import { Box, Typography } from "@mui/material";

import { useInvalidateLegacyQuery } from "~/api/_shared/hooks";
import CustomModal from "~/common/components/Modal";
import { useAppDispatch, useAppSelector } from "~/common/hooks/useRedux";
import { useToast } from "~/common/hooks/useToast";

import { useAppConfigQuery } from "#/features/User/queries";
import {
  StaffDetails,
  TBulkSaveRosters,
  useBulkSaveUnitEligibility,
  useListStaffDetailsQuery,
} from "@/api";
import { CustomButton, CustomSelect } from "@/common/components";
import { ICustomSelectItem } from "@/common/components/TrackedComponents/Select/types";
import { BULK_DELETE_KEY, NOT_EXISTING_UUID } from "@/common/constants";
import {
  useCurrentFacilityId,
  useCurrentSelectedUnitId,
  useFilterBy,
  useMap,
} from "@/common/hooks";
import { Uuid } from "@/common/types";

import { setIsEditFloatEligibilityModalOpen } from "../../store";

const emptyStaffDetailsDtosArray: StaffDetails.DTO[] = [];

export const EditFloatEligibilityModal = () => {
  // High- level state
  const dispatch = useAppDispatch();
  const { showSuccess, showError } = useToast();
  const invalidateQuery = useInvalidateLegacyQuery();

  // Local state
  const isEditFloatEligibilityOpen = useAppSelector(
    (state) => state.roster.editFloatEligibilityModal.isOpen,
  );
  const handleSecondaryBtnClick = () => {
    dispatch(setIsEditFloatEligibilityModalOpen(false));
  };

  // Unit Data
  const unitsDTOs = useAppConfigQuery().data?.accessibleUnits;
  const currentFacilityId = useCurrentFacilityId();
  const currentUnitId = useCurrentSelectedUnitId();

  // Filter units by current facility and exclude current unit
  const filteredUnits = useFilterBy(
    unitsDTOs,
    (unit) => unit.facilityId === currentFacilityId && unit.id !== currentUnitId,
    [currentUnitId, currentFacilityId],
  );
  // All options for custom select
  const unitsCustomSelect: ICustomSelectItem<Uuid>[] = useMap(filteredUnits, (unit) => {
    return { ...unit, value: unit.id, label: unit.name, item: unit.id } as ICustomSelectItem<Uuid>;
  });
  // Selected Value
  const [selectedUnitId, setSelectedUnitId] = useState<Uuid | undefined>(undefined);

  // Staff Data
  // Include only active staff from HOME unit (currentUnitId)
  const { data: staffDetailDtos = emptyStaffDetailsDtosArray, isLoading: isStaffLoading } =
    useListStaffDetailsQuery(
      { homeUnitIds: [currentUnitId || NOT_EXISTING_UUID], active: [true] },
      { skip: !currentUnitId },
    );

  // All options for custom select
  const staffItemsCustomSelect: ICustomSelectItem<Uuid>[] = useMap(staffDetailDtos, (staff) => {
    return {
      ...staff,
      value: staff.userId,
      label: `${staff.user.firstName} ${staff.user.lastName}, ${staff.staffType.name}`,
      item: staff.userId,
    } as ICustomSelectItem<Uuid>;
  });

  // Selected values
  const [initialUsers, setInitialUsers] = useState<Uuid[]>([]);
  const [selectedUsers, setFilteredUsers] = useState<Uuid[]>([]);

  // Update initialUsers and filteredUsers when data or selectedUnit changes
  useEffect(() => {
    const newInitialUsers = staffDetailDtos
      .filter((staff) =>
        staff.user.rosters
          ?.map((rosterDto) => rosterDto.unitId)
          .includes(selectedUnitId || NOT_EXISTING_UUID),
      )
      .map((staff) => staff.userId);
    setInitialUsers(newInitialUsers);
    setFilteredUsers(newInitialUsers);
  }, [staffDetailDtos, selectedUnitId]);

  // Content
  const modalContent = (
    <Box mt={3}>
      <CustomSelect<Uuid>
        items={unitsCustomSelect}
        label="Select unit"
        value={selectedUnitId}
        onChange={(event) => {
          setSelectedUnitId(event.target.value);
        }}
        sx={{ mb: 3 }}
      />
      <Typography sx={{ mb: 2 }}>
        Select or deselect staff to be eligible to float to unit above:
      </Typography>
      <Box maxWidth={"500px"}>
        <CustomSelect<Uuid>
          multiple
          checked
          customDisplayOption={`${selectedUsers.length} Users Selected`}
          items={staffItemsCustomSelect}
          label="Staff eligible to float to unit"
          value={selectedUsers}
          onChange={(event) => {
            const values = Array.isArray(event.target.value)
              ? event.target.value
              : [event.target.value];
            setFilteredUsers(values as Uuid[]);
          }}
          sx={{ mb: 3 }}
          disabled={!selectedUnitId}
          displayEmpty
        />
      </Box>
      <CustomButton
        label={"Select All"}
        onClick={() => setFilteredUsers(staffDetailDtos.map((staff) => staff.userId))}
        type="submit"
      />
    </Box>
  );

  // Mutation to alter data
  const { mutate: updateRoster, isPending: isSubmitting } = useBulkSaveUnitEligibility({
    onSuccess: () => {
      showSuccess("Float eligibility updated successfully.");
      invalidateQuery("roster");
    },
    onError: () => {
      showError("Failed to update float eligibility.");
    },
  });

  const saveFloatEligibility = () => {
    if (selectedUnitId) {
      const rostersToUpdate: TBulkSaveRosters = [];

      // Create roster entries for newly eligible staff
      selectedUsers.forEach((userId) => {
        if (!initialUsers.includes(userId)) {
          rostersToUpdate.push({ unitId: selectedUnitId, userId });
        }
      });

      // Delete roster entries for staff no longer eligible
      initialUsers.forEach((userId) => {
        if (!selectedUsers.includes(userId)) {
          rostersToUpdate.push({ unitId: selectedUnitId, userId, [BULK_DELETE_KEY]: true });
        }
      });

      updateRoster({
        rosters: rostersToUpdate,
      });
    }
  };

  if (!currentUnitId || isStaffLoading) return null;

  return (
    <CustomModal
      isOpen={isEditFloatEligibilityOpen}
      primaryBtnText="Save"
      modalContent={modalContent}
      modalHeaderText={"Edit Float Eligibility"}
      onSubmit={saveFloatEligibility}
      onSecondaryBtnClick={handleSecondaryBtnClick}
      // disabled until you edit something aka change the selected users
      // (can't just check length because could be same length but different users)
      primaryDisabled={
        !selectedUnitId ||
        JSON.stringify(orderBy(selectedUsers)) ===
          JSON.stringify(orderBy(initialUsers) || isSubmitting)
      }
    />
  );
};
