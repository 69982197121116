import { Typography } from "@mui/material";

import { IShiftType } from "@/api";
import { ShiftTypeFilterDropdown, ShiftV2 } from "@/common/components";

import { TDataComponent } from "./types";

export const ShiftTypeField: TDataComponent<IShiftType["key"]> = {
  editor: ({ value, updateCell, shiftTypes, onBlur, focused }) => {
    return (
      <ShiftTypeFilterDropdown
        trackingLabel={undefined}
        shiftTypes={shiftTypes}
        selectedOption={shiftTypes.find((shiftType) => shiftType.key === value)}
        isMultiSelect={false}
        selectOption={(newValue) => {
          updateCell(newValue?.key);
          onBlur();
        }}
        onClose={onBlur}
        open={focused || undefined}
        variant="standard"
        size="small"
        label=""
        sx={{
          marginTop: "0 !important",
        }}
      />
    );
  },
  readonly: ({ indexedShiftTypes, value }) => {
    const shiftType = value && indexedShiftTypes[value];
    if (!value || !shiftType) return <Typography>N/A</Typography>;

    return <ShiftV2 shiftType={shiftType} variant={"medium"} />;
  },
};
