import { entries } from "lodash";

import { StaffDetails } from "@/api";

import { ParserError } from "../DataParser";

import { flatCleanString } from "./helpers";
import { TDataParser } from "./types";

const employmentTypeValues = entries(StaffDetails.EEmploymentType).reduce(
  (acc, [key, value]) => {
    acc[flatCleanString(value)] = value;
    acc[flatCleanString(key)] = value;

    return acc;
  },
  {} as Record<string, StaffDetails.EEmploymentType>,
);

export const employmentTypeParser: TDataParser<StaffDetails.EEmploymentType> = (value) => {
  if (!value) return null;

  const stringValue = employmentTypeValues[flatCleanString(value)];

  if (!stringValue) throw new ParserError("Invalid employment type");

  return stringValue;
};
