import { LAvailableMuiIconsClassNames } from "~/common/constants";
import { dateString, milliseconds, TimeString, Uuid } from "~/common/types";
import { EShiftTypeDropdownHolder } from "~/routes/api/types";

import { IApprovalRequest } from "../approvalRequest";

// Shared DTO
export namespace SDTO {
  export interface base {
    id: Uuid;
  }

  export interface withTimestamps {
    createdAt: dateString;
    updatedAt: dateString;
  }

  export interface withSortPosition {
    sortPosition?: number;
  }

  export interface Request {
    approvalRequests: IApprovalRequest[];
  }

  export interface softDeletable {
    deletedAt: dateString | null;
  }

  export interface shiftType extends softDeletable {
    name: string;
    sortPosition?: number;
    startTime: TimeString;
    durationSeconds: number;

    // --> APPEARANCE
    displayableInDropdowns: EShiftTypeDropdownHolder[];
    printAbbreviation?: string;
    muiIconClassName?: LAvailableMuiIconsClassNames;
    iconColor?: string;
    // Scheduler POV
    scheduleViewDisplayName: string;
    scheduleViewColor: string;
    schedulerAssignableShift: boolean;
    // Staff POV
    staffViewDisplayName: string;
    staffViewColor: string;
    staffPreferableShift: boolean;
    // <--- APPEARANCE

    // Shift description
    isPatientCareShift: boolean;
    isWorkingShift: boolean;
    isPaidShift: boolean;
    /** @deprecated. With reqv2, we don't need this attribute anymore*/
    isCountedForRequirements: boolean;
    isCountedForRealTimeStaffingTarget: boolean;

    // Fairness rules
    violationIfReplacingNull: boolean;
    violationIfReplacedByNull: boolean;
    violationIfReplacedByPatientNonWorking: boolean;
    violationIfReplacedByNonPatientNonWorking: boolean;
    violationIfReplacedByPatientWorking: boolean;
    violationIfReplacedByNonPatientWorking: boolean;
  }
}

export type ApiResponse<T> = {
  data: T;
};
export type ApiEmptyResponse = Record<string, never>;
export type ApiListResponsePaginated<T> = { data: T[]; pagination: TPagination };
export type APIListResponse<T> = ApiResponse<T[]>;
export type PaginatedListQueryResponse<T> = { list: T[]; pagination: TPagination };
export type APIElementResponse<T> = ApiResponse<T>;
export type TPagination = {
  total: number;
  skip: number;
  take: number;
};

export type AugmentedQueryHook<
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  QueryType extends (...args: any[]) => any,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ApiFunctionType extends (...args: any[]) => Promise<any>,
> = QueryType & {
  queryKey: string;
  apiFunction: ApiFunctionType;
};

// Stale is in ms
export const staleFor = (number: number) => {
  const ms = number as milliseconds;
  const seconds = (1000 * number) as milliseconds;
  const minutes = (60 * 1000 * number) as milliseconds;
  const hours = (60 * 60 * 1000 * number) as milliseconds;

  return {
    hours,
    hour: hours,
    minutes,
    minute: minutes,
    seconds,
    second: seconds,
    ms,
    millisecond: ms,
    milliseconds: ms,
  };
};
