import { memo, useMemo, useState } from "react";

import { entries, invert, keys } from "lodash";

import { Check, Close, ReportGmailerrorred } from "@mui/icons-material";
import { Drawer, Stack, Typography } from "@mui/material";

import { CustomButton, CustomCollapse } from "@/common/components";

import { TIndexedErrors } from "../UserTable/hooks/useUpdateData";
import { TColumnError, TExpectedColumn } from "../UserTable/types";

import { RowErrors } from "./RowErrors";

const componentId = "bulk-create-users-user-table-errors-sidebar";
export const ErrorsSideBar = memo(
  ({
    errors,
    headers,
  }: {
    errors: {
      cells: TIndexedErrors;
      columns: TColumnError[];
    };
    headers: Record<TExpectedColumn, number>;
  }) => {
    // Open states. Sidebar > section [Columns/Rows] > row
    const [checksSideBarIsOpen, setChecksSideBarIsOpen] = useState(true);
    const [columnsSectionIsOpen, setColumnsSectionIsOpen] = useState(true);
    const [rowsSectionIsOpen, setRowsSectionIsOpen] = useState(true);

    const columnsAreValid = errors.columns.length === 0;
    const rowsWithErrorsCount = keys(errors.cells).length;
    const rowsAreValid = rowsWithErrorsCount === 0;
    // Columns errors are only when an unknown column is found in the CSV. We
    //  can still proceed with the overall workflow, but want to warn the user.
    const validityState = !rowsAreValid ? "error" : !columnsAreValid ? "warn" : "valid";

    const headerByIndex = useMemo(() => invert(headers), [headers]) as Record<
      string,
      TExpectedColumn
    >;

    return (
      <>
        <OpenButton
          state={validityState}
          onClick={() => setChecksSideBarIsOpen(!checksSideBarIsOpen)}
        />
        <Drawer
          className={componentId}
          data-testid={componentId}
          variant="persistent"
          open={checksSideBarIsOpen}
          ModalProps={{ keepMounted: true }}
        >
          <Stack sx={{ p: 1 }} direction="row" justifyContent="space-between" alignItems="center">
            <Typography variant="h6" children="Data Errors" />
            <CloseButton onClick={() => setChecksSideBarIsOpen(false)} />
          </Stack>

          {/* Columns Error section */}
          <CustomCollapse
            label={`Columns ${columnsAreValid ? "✅" : `❌ (${errors.columns?.length || 0} errors)`}`}
            isOpen={columnsSectionIsOpen}
            id={`columns-section`}
            onChange={() => setColumnsSectionIsOpen(!columnsSectionIsOpen)}
            disabled={columnsAreValid}
          >
            {errors.columns?.map((error) => (
              <Typography key={error.message} children={error.message} />
            ))}
          </CustomCollapse>

          {/* Rows Error section */}
          <CustomCollapse
            label={`Rows ${rowsAreValid ? "✅" : `❌ (${rowsWithErrorsCount} errors)`}`}
            isOpen={rowsSectionIsOpen}
            id={`rows-section`}
            onChange={() => setRowsSectionIsOpen(!rowsSectionIsOpen)}
            disabled={rowsAreValid}
          >
            {entries(errors.cells).map(([rowIndex, rowErrors]) => (
              <RowErrors
                key={"row-error-" + rowIndex.toString()}
                rowIndex={rowIndex}
                errors={rowErrors}
                headers={headerByIndex}
              />
            ))}
          </CustomCollapse>
        </Drawer>
      </>
    );
  },
);

const OpenButton = ({
  state,
  onClick,
}: {
  state: "error" | "warn" | "valid";
  onClick: () => void;
}) => (
  <CustomButton
    className="checks-sidebar-toggle"
    trackingLabel={null}
    startIcon={
      state === "valid" ? (
        <Check />
      ) : state === "warn" ? (
        <ReportGmailerrorred />
      ) : (
        <ReportGmailerrorred />
      )
    }
    variant="contained"
    color={state === "valid" ? "success" : state === "warn" ? "warning" : "error"}
    onClick={onClick}
  />
);

const CloseButton = ({ onClick }: { onClick: () => void }) => (
  <CustomButton
    trackingLabel={null}
    startIcon={<Close />}
    className="close-button"
    variant="text"
    onClick={onClick}
    iconOnly
  />
);
