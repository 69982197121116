import { ICustomSelectItem } from "~/common/components/TrackedComponents/Select/types";
import { axiosInstance } from "~/common/packages/httpClient";

import { ERoleId } from "#/features/User/types";
import { User } from "@/api";

import {
  EShiftStatus,
  ICreateLabel,
  ICreateNote,
  IGetNotes,
  IGetUnitStaffLabels,
  INote,
  IUnitStaffLabel,
  IUnitStaffLabelForCustomSelect,
  TStaffNotesByStaffId,
} from "../types";

const UNIT_STAFF_LABEL_ENDPOINT = "/unit-staff-label";
const SCHEDULES_ENDPOINT = "/schedules";

export const createLabelApi = async (body: ICreateLabel): Promise<IUnitStaffLabel> => {
  const { data } = await axiosInstance.post<IUnitStaffLabel>(UNIT_STAFF_LABEL_ENDPOINT, body);

  return data;
};

export const getUnitStaffLabelsApi = async (
  unitId: string,
): Promise<IUnitStaffLabelForCustomSelect[]> => {
  const {
    data: { data },
  } = await axiosInstance.get<IGetUnitStaffLabels>(`${UNIT_STAFF_LABEL_ENDPOINT}/${unitId}`);

  const dataDTO = data.map(({ name, key, unitId }) => ({
    name,
    key,
    unitId,
    label: name,
    value: key,
  }));

  return dataDTO;
};

// @deprecated use useListNotesQuery instead
export const getNotesFromSchedulerByDateApi = async (
  date: string,
  scheduleId: string,
): Promise<TStaffNotesByStaffId> => {
  const {
    data: { data },
  } = await axiosInstance.get<IGetNotes>(`${SCHEDULES_ENDPOINT}/notes/list/${scheduleId}`, {
    params: { date },
  });
  const initialStaffNotes: TStaffNotesByStaffId = {};
  data?.forEach((note) => {
    if (note.userId) {
      // skips staff notes (aka if noteTypeRoleId is not scheduler)
      if (note.noteTypeRoleId === ERoleId[User.ERole.scheduler]) {
        initialStaffNotes[note.userId] = note;
      }
    } else {
      initialStaffNotes["None"] = note;
    }
  });
  return initialStaffNotes;
};

export const updateNoteApi = async (
  body: ICreateNote,
): Promise<Pick<INote, "id" | "updatedAt">> => {
  // these select options are "" if none is selected... but here, want to actually pass null to DB
  if (body.unitStaffLabelKey === "") {
    body.unitStaffLabelKey = null;
  }
  if ((body.update as string) === "") {
    body.update = null;
  }

  const { data } = await axiosInstance.put<Pick<INote, "id" | "updatedAt">>(
    `${SCHEDULES_ENDPOINT}/notes`,
    body,
  );
  return data;
};

export const getUpdateFieldsApi = async (): Promise<ICustomSelectItem[]> => {
  const {
    data: { data },
  } = await axiosInstance.get<{ data: EShiftStatus[] }>("/schedules/notes/update-fields");

  const dataDTO = data.map((update) => ({
    label: update as string,
    value: update as string,
  }));

  return dataDTO;
};
