import { createTheme, PaletteColor } from "@mui/material/styles";

import { pxToRem } from "../utils/pxToRem";

import * as colors from "./colors";
import { fontFamily } from "./fonts";

export * from "./colors";

/*
 * to use a custom color in a component:
 * - add it to customColors
 * - add it to Palette and PaletteOptions
 * - add it to your component interface:
 *   declare module '@mui/material/<component>' {
      interface <component>PropsColorOverrides {
      <colorName>: true;
    }
  }
*/

const customColors: [string, string][] = [];
Object.entries(colors).forEach(([name, color]) => {
  customColors.push([name, color]);
});

// THEME EXTENDS PROPERTIES
declare module "@mui/material/styles" {
  interface TypographyVariants {
    shy: React.CSSProperties;
    small: React.CSSProperties;
  }

  interface TypographyVariantsOptions {
    shy?: React.CSSProperties;
    small?: React.CSSProperties;
  }

  interface Palette {
    danger: Palette["primary"];
    educationShift: Palette["primary"];
    nightShift: Palette["primary"];
    nightShiftDark: Palette["primary"];
    successMain: Palette["primary"];
    lightPurple: Palette["primary"];
    darkPurple: Palette["primary"];
  }

  interface PaletteOptions {
    danger?: PaletteOptions["primary"];
    educationShift?: PaletteOptions["primary"];
    nightShift?: PaletteOptions["primary"];
    nightShiftDark?: PaletteOptions["primary"];
    successMain?: PaletteOptions["primary"];
    lightPurple?: PaletteOptions["primary"];
    darkPurple?: PaletteOptions["primary"];
  }
}

/*
 * Component custom themes types augmentation
 */

declare module "@mui/material/Chip" {
  interface ChipPropsColorOverrides {
    danger: true;
    educationShift: true;
    nightShiftDark: true;
    nightShift: true;
    lightPurple: true;
    lavender: true;
  }
}

declare module "@mui/material/ToggleButtonGroup" {
  interface ToggleButtonGroupPropsColorOverrides {
    darkPurple: true;
  }
}

declare module "@mui/material/SvgIcon" {
  interface SvgIconPropsColorOverrides {
    danger: true;
    successMain: true;
  }
}

declare module "@mui/material/Typography" {
  interface TypographyPropsVariantOverrides {
    shy: true;
    small: true;
  }
}

declare module "@mui/material/Button" {
  interface ButtonPropsColorOverrides {
    darkPurple: true;
  }
}

declare module "@mui/material/ButtonGroup" {
  interface ButtonGroupPropsColorOverrides {
    darkPurple: true;
  }
}
declare module "@mui/material/IconButton" {
  interface IconButtonPropsColorOverrides {
    darkPurple: true;
  }
}

// Actual theme
let theme = createTheme({
  typography: {
    shy: {
      fontWeight: 500,
      fontSize: "0.875rem",
      color: colors.darkGray,
      fontFamily: fontFamily,
    },
    small: {
      fontSize: "0.875rem",
      fontFamily: fontFamily,
    },
    fontFamily: fontFamily,
    allVariants: {
      color: colors.black,
    },
    button: {
      textTransform: "none",
      fontSize: "0.875rem",
    },
  },
  palette: {
    primary: {
      main: colors.black,
      contrastText: colors.white,
    },
    secondary: {
      main: colors.darkGray,
    },
  },
  components: {
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          backgroundColor: colors.black,
          fontSize: pxToRem(11),
        },
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        label: {
          fontSize: "0.875rem",
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          color: colors.darkGray,
        },
      },
    },
    MuiLink: {
      defaultProps: {
        color: colors.darkGray,
        fontFamily: fontFamily,
        underline: "none",
      },
    },
    MuiSwitch: {
      styleOverrides: {
        root: {
          "& .MuiSwitch-colorPrimary.Mui-checked .MuiSwitch-thumb": {
            backgroundColor: colors.darkPurple,
          },
          "& .MuiSwitch-colorPrimary.Mui-checked.Mui-disabled .MuiSwitch-thumb": {
            backgroundColor: colors.lightGray,
          },
          "& .MuiSwitch-colorPrimary.Mui-checked + .MuiSwitch-track": {
            backgroundColor: `${colors.lightPurple} !important`,
          },
          "& + .MuiFormControlLabel-label": {
            marginLeft: "0.25rem",
          },
        },
      },
    },
    // hide default browser arrows to change number input value
    MuiTextField: {
      styleOverrides: {
        root: {
          "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
            display: "none",
          },
          "& input[type=number]": {
            MozAppearance: "textfield",
          },
        },
      },
    },
    MuiAlert: {
      styleOverrides: {
        standardWarning: {
          background: colors.warning,
          color: colors.warningText,
          fontWeight: 500,
          "& .MuiAlert-icon": {
            color: colors.warningText,
          },
        },
      },
    },
  },
});

// add custom colors
theme = createTheme(theme, {
  palette: {
    ...customColors.reduce(
      (colorsAcc, [name, color]) => {
        colorsAcc[name] = theme.palette.augmentColor({
          color: {
            main: color,
          },
          name: name,
        });
        return colorsAcc;
      },
      {} as { [key: string]: PaletteColor },
    ),
  },
});

export { theme };
