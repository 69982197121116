import { buildApiListQueryHook } from "../_shared/helpers";

import { StaffCategory } from "./types";

/**
 * @deprecated - use staff category hooks instead. Example: useUnitStaffCategories
 */
export const useListStaffCategoriesQuery = buildApiListQueryHook<
  StaffCategory.API.List.QueryParams,
  StaffCategory.API.List.Response
>(StaffCategory.API.List.PATH);
