import { useMemo } from "react";

import {
  getTzFormattedDate,
  m7DayJs,
  THouseViewTimeRange,
  Timezone,
} from "@m7-health/shared-utils";
import { isEqual } from "lodash";

import { IStaffShift, Schedule, ShiftType, StaffDetails } from "~/api";

import { useAppSelector, useCurrentFacility } from "@/common/hooks";
import { timeAdd, timeDifference, timeOverlap } from "@/common/utils/dates";
import { voidingShiftStatus } from "@/common/utils/shifts";

import { ITablesData, TShiftsByDate } from "../types";

interface IComputeTablesDataParams {
  schedules: Schedule.DTO[];
  shiftsByScheduleId: Record<string, IStaffShift[]>;
  staffDetailsByStaffId: Record<string, StaffDetails.DTO>;
  selectedStaffCategory: string;
  shiftTypesByScheduleIdByKey: Record<string, Record<string, ShiftType.Schedule.DTO>>;
  timezoneByUnitId: {
    [unitId: string]: Timezone;
  };
  timeRangesToShow: THouseViewTimeRange[];
}

/**
 * Computes tables data for house view based on provided schedules and shifts.
 * For each staff member, if any of their shifts overlap with the specified time ranges,
 * all of their shifts for that day will be included in the output.
 *
 * The function performs two passes:
 * 1. First pass identifies staff members who have any overlapping shifts for each schedule and date
 * 2. Second pass processes all shifts for staff members with overlaps, calculating overlap counts
 *    and collecting all their shifts for the day
 *
 * @param {IComputeTablesDataParams} params - The parameters for computing tables data.
 * @param {Schedule.DTO[]} params.schedules - Array of schedule DTOs.
 * @param {Record<string, IStaffShift[]>} params.shiftsByScheduleId - Staff shifts indexed by schedule ID.
 * @param {Record<string, StaffDetails.DTO>} params.staffDetailsByStaffId - Staff details indexed by staff ID.
 * @param {string} params.selectedStaffCategory - The selected staff category key.
 * @param {Record<string, Record<string, ShiftType.Schedule.DTO>>} params.shiftTypesByScheduleIdByKey - Shift types indexed by schedule ID and shift type key.
 * @param {Record<string, Timezone>} params.timezoneByUnitId - Timezones indexed by unit ID.
 * @param {THouseViewTimeRange[]} params.timeRangesToShow - Time ranges to show.
 * @returns {ITablesData} The computed tables data.
 */
function computeTablesData({
  schedules,
  shiftsByScheduleId,
  staffDetailsByStaffId,
  selectedStaffCategory,
  shiftTypesByScheduleIdByKey,
  timezoneByUnitId,
  timeRangesToShow,
}: IComputeTablesDataParams): ITablesData {
  const result: ITablesData = {};
  const staffWithOverlap: Record<string, Record<string, Set<string>>> = {};

  // Create a map of unitId to schedules for quick lookups
  const schedulesByUnitId = schedules.reduce(
    (acc, schedule) => {
      acc[schedule.unitId] ??= [];
      acc[schedule.unitId]!.push(schedule);
      return acc;
    },
    {} as Record<string, Schedule.DTO[]>,
  );

  // First pass: Check for overlaps
  for (const schedule of schedules) {
    const shifts = shiftsByScheduleId[schedule.id] || [];
    for (const shift of shifts) {
      const staff = staffDetailsByStaffId[shift.staffId];
      if (staff?.staffType.staffCategoryKey === selectedStaffCategory) {
        const shiftType = shiftTypesByScheduleIdByKey[shift.scheduleId]?.[shift.shiftTypeKey];
        if (!shiftType) continue;

        const timezone = timezoneByUnitId[schedule.unitId] || (m7DayJs.tz.guess() as Timezone);
        const dateKey = getTzFormattedDate(shift.date, timezone);

        // Find home unit schedules if different
        const homeUnitSchedules =
          staff.homeUnitId !== schedule.unitId ? schedulesByUnitId[staff.homeUnitId] || [] : [];

        timeRangesToShow.forEach((range) => {
          const staffShiftStart = shift.customStartTime || shiftType.startTime;
          const staffShiftEnd = timeAdd(
            staffShiftStart,
            shift.customDuration || shiftType.durationSeconds,
          );

          const overlapSeconds = timeOverlap(
            { from: staffShiftStart, to: staffShiftEnd },
            { from: range.startTime, to: range.endTime },
          );

          if (overlapSeconds > 0) {
            // Always add to current schedule
            staffWithOverlap[schedule.id] ??= {};
            staffWithOverlap[schedule.id]![dateKey] ??= new Set();
            staffWithOverlap[schedule.id]?.[dateKey]?.add(shift.staffId);

            // Add to all home unit schedules if they exist
            for (const homeSchedule of homeUnitSchedules) {
              staffWithOverlap[homeSchedule.id] ??= {};
              staffWithOverlap[homeSchedule.id]![dateKey] ??= new Set();
              staffWithOverlap[homeSchedule.id]?.[dateKey]?.add(shift.staffId);
            }
          }
        });
      }
    }
  }

  // Second pass: Process all shifts
  for (const schedule of schedules) {
    const shifts = shiftsByScheduleId[schedule.id] || [];
    for (const shift of shifts) {
      const staff = staffDetailsByStaffId[shift.staffId];
      if (staff?.staffType.staffCategoryKey === selectedStaffCategory) {
        const shiftType = shiftTypesByScheduleIdByKey[shift.scheduleId]?.[shift.shiftTypeKey];
        if (!shiftType) continue;

        const timezone = timezoneByUnitId[schedule.unitId] || (m7DayJs.tz.guess() as Timezone);
        const dateKey = getTzFormattedDate(shift.date, timezone);

        // Find home unit schedules if different
        const homeUnitSchedules =
          staff.homeUnitId !== schedule.unitId ? schedulesByUnitId[staff.homeUnitId] || [] : [];

        // Process current schedule
        if (staffWithOverlap[schedule.id]?.[dateKey]?.has(shift.staffId)) {
          processShiftForSchedule(schedule.id, shift, dateKey);
        }

        // Process home unit schedules if they exist
        for (const homeSchedule of homeUnitSchedules) {
          if (staffWithOverlap[homeSchedule.id]?.[dateKey]?.has(shift.staffId)) {
            processShiftForSchedule(homeSchedule.id, shift, dateKey);
          }
        }
      }
    }
  }

  // Helper function to process shift for a schedule
  function processShiftForSchedule(scheduleId: string, shift: IStaffShift, dateKey: string) {
    const shiftsBySchedule = (result[scheduleId] ||= {});
    const shiftsByDate = (shiftsBySchedule[dateKey] ||= {
      shiftsByStaff: {},
    } as TShiftsByDate);

    const staffShiftsForDay = (shiftsByDate.shiftsByStaff[shift.staffId] ||= []);
    staffShiftsForDay.push(shift);

    const shiftType = shiftTypesByScheduleIdByKey[shift.scheduleId]?.[shift.shiftTypeKey];
    if (!shiftType) return;

    timeRangesToShow.forEach((range) => {
      const timeRangeKey = `${range.startTime}-${range.endTime}`;
      const staffShiftStart = shift.customStartTime || shiftType.startTime;
      const staffShiftEnd = timeAdd(
        staffShiftStart,
        shift.customDuration || shiftType.durationSeconds,
      );

      const overlapSeconds = timeOverlap(
        { from: staffShiftStart, to: staffShiftEnd },
        { from: range.startTime, to: range.endTime },
      );

      // only count shift if it's not voiding and is counted for real time staffing target
      // and if it's the shift for the current schedule
      if (
        overlapSeconds > 0 &&
        !voidingShiftStatus(shift.status) &&
        shiftType.isCountedForRealTimeStaffingTarget &&
        shift.scheduleId === scheduleId
      ) {
        const daysToAdd =
          overlapSeconds / 3600 / (timeDifference(range.startTime, range.endTime) / 3600);
        shiftsByDate[timeRangeKey] = (shiftsByDate[timeRangeKey] || 0) + daysToAdd;
      }
    });
  }

  // Clean up empty staff entries
  for (const scheduleId in result) {
    for (const dateKey in result[scheduleId]) {
      const shiftsByStaff = result?.[scheduleId]?.[dateKey]?.shiftsByStaff;
      for (const staffId in shiftsByStaff) {
        if (shiftsByStaff?.[staffId]?.length === 0) {
          delete shiftsByStaff[staffId];
        }
      }
    }
  }

  return result;
}

/** Computing data for the tables */
// Data indexed by
// -> scheduleId
//  -> day
//    -> timeRange: count
//    -> staffId: shift
export function useComputeTablesData({
  schedules,
  shiftsByScheduleId,
  staffDetailsByStaffId,
  selectedStaffCategory,
  shiftTypesByScheduleIdByKey,
  timezoneByUnitId,
}: Omit<IComputeTablesDataParams, "timeRangesToShow">): ITablesData {
  const { customTimeRange: selectedCustomTimeRange } = useAppSelector(
    (state) => ({
      customTimeRange: state.houseView.pageFilters.customTimeRange,
    }),
    isEqual,
  );
  const facility = useCurrentFacility();
  const allFacilityTimeRanges = facility?.configuration.settings?.houseViewTimeRanges;

  return useMemo(() => {
    // whenever these change, recompute the tables data
    if (!selectedCustomTimeRange || !allFacilityTimeRanges) {
      return {};
    }

    const timeRangesToShow =
      selectedCustomTimeRange.customAbbreviation === "All"
        ? allFacilityTimeRanges
        : [selectedCustomTimeRange];

    return computeTablesData({
      schedules,
      shiftsByScheduleId,
      staffDetailsByStaffId,
      selectedStaffCategory,
      shiftTypesByScheduleIdByKey,
      timezoneByUnitId,
      timeRangesToShow,
    });
  }, [
    schedules,
    shiftsByScheduleId,
    staffDetailsByStaffId,
    selectedStaffCategory,
    shiftTypesByScheduleIdByKey,
    timezoneByUnitId,
    selectedCustomTimeRange,
    allFacilityTimeRanges,
  ]);
}
