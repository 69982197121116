import { useEffect, useState } from "react";

import { ISODateString } from "@m7-health/shared-utils";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { isEqual } from "lodash";

import { Stack } from "@mui/material";

import CustomModal from "~/common/components/Modal";
import { useErrors } from "~/common/hooks/useErrors";
import { useAppDispatch, useAppSelector } from "~/common/hooks/useRedux";
import { useToast } from "~/common/hooks/useToast";
import { Dayjs } from "~/common/packages/dayjs";

import { CustomDatePicker, CustomSwitch } from "@/common/components";
import { useCurrentTimezone } from "@/common/hooks";
import { localDayJs } from "@/common/packages/dayjs";
import { getTzDayjs, getTzUSFormattedDate } from "@/common/utils/dates";

import { changeStaffStatus, suspendAccount } from "../../api";
import { closeUpdateSuspensionDate } from "../../store";
import { EStaffStatus } from "../../types";

const UpdateStatusDateModal = () => {
  /** HIGH LEVEL */
  const dispatch = useAppDispatch();
  const { handleErrors } = useErrors();
  const queryClient = useQueryClient();
  const { showSuccess } = useToast();
  const timezone = useCurrentTimezone();

  const { statusChangeModalState, modalData } = useAppSelector(
    (state) => ({
      statusChangeModalState: state.roster.updateSuspensionDateModal.isOpen,
      modalData: state.roster.updateSuspensionDateModal.data,
    }),
    isEqual,
  );
  /** STATES */
  const [timeline, setTimeline] = useState({
    newDate: true,
    cancel: false,
  });

  const [statusChangeDate, setStatusChangeDate] = useState<Dayjs | undefined>(undefined);
  useEffect(() => {
    if (!timezone || statusChangeDate || !modalData) return;
    setStatusChangeDate(getTzDayjs(modalData.date, timezone));
  }, [timezone, statusChangeDate, modalData]);

  const today = localDayJs().tz(timezone).format("YYYY-MM-DD");
  const formattedDate = statusChangeDate?.format("YYYY-MM-DD") || "";

  const resetState = () => {
    setStatusChangeDate(undefined);
    setTimeline({ newDate: true, cancel: false });
  };
  const onSettled = () => {
    void queryClient.invalidateQueries({ queryKey: ["roster"] });
  };

  const { mutate: suspendUser, isPending: isLoading } = useMutation({
    mutationFn: suspendAccount,
    onSuccess: () => {
      dispatch(closeUpdateSuspensionDate());
      resetState();
      showSuccess("Suspended user successfully");
    },
    onError: handleErrors,
    onSettled,
  });

  const { mutateAsync: changeStaffStatusMutation, isPending: isChangeStaffStatusApiLoading } =
    useMutation({ mutationFn: changeStaffStatus });

  const confirmStatusChange = () => {
    if (!modalData || !statusChangeDate) return;

    let params: Omit<Parameters<typeof changeStaffStatusMutation>[0], "staffId" | "status"> = {
      postDateStatusUpdateDate: statusChangeDate.toISOString() as ISODateString,
      postDateStatusUpdateType: "suspended",
    };

    if (timeline.cancel || formattedDate === today)
      params = {
        postDateStatusUpdateDate: null,
        postDateStatusUpdateType: null,
      };

    changeStaffStatusMutation({
      staffId: modalData.id,
      status: EStaffStatus.Active,
      ...params,
    })
      .then(() => {
        if (!timeline.cancel && formattedDate === today) {
          suspendUser({ id: modalData.id });
        } else {
          dispatch(closeUpdateSuspensionDate());
          showSuccess("Planned suspension date updated successfully");
          resetState();
        }
      })
      .catch(handleErrors)
      .finally(onSettled);
  };

  const handleSecondaryBtnClick = () => {
    resetState();
    dispatch(closeUpdateSuspensionDate());
  };

  if (!modalData || !statusChangeModalState) return null;

  const variantTitle = (
    <>
      <b>{modalData.name}</b> is set to be suspended on{" "}
      <b>{getTzUSFormattedDate(modalData.date, timezone)}</b>. You are able to update the date the
      user will be suspended, or cancel the planned suspension below.
      <br />
      <br />
      <Stack direction="row" spacing={2}>
        {statusChangeDate && (
          <CustomDatePicker
            disabled={timeline.cancel}
            className="post-date-update"
            name="statusChangeDate"
            label="Suspension effective from:"
            value={statusChangeDate}
            disablePast
            onChange={(newDate) => {
              if (newDate) {
                setTimeline({ newDate: true, cancel: false });
                setStatusChangeDate(newDate);
              }
            }}
            timezone={timezone}
          />
        )}
        <CustomSwitch
          name="toggle-cancel-status-change"
          label="Cancel planned suspension"
          checked={timeline.cancel}
          onChange={(value) => setTimeline({ newDate: !value, cancel: value })}
        />
      </Stack>
    </>
  );

  return (
    <CustomModal
      isOpen={true}
      primaryBtnText="Confirm"
      modalHeaderText="Suspension Date Change"
      onSecondaryBtnClick={handleSecondaryBtnClick}
      onSubmit={confirmStatusChange}
      primaryDisabled={isChangeStaffStatusApiLoading || isLoading}
      variant="warning"
      variantText={
        timeline.cancel ? (
          "You are about to cancel planned suspension"
        ) : formattedDate === today ? (
          <>
            Selected date is <b>today</b>. The suspension will take effect immediately: this user
            will be removed from the roster. All past data related to this user will be saved and
            the user can be restored, if needed. All of the staff's future shifts and preferences
            will be removed from current and future schedules.
          </>
        ) : undefined
      }
      variantTitle={variantTitle}
    />
  );
};

export default UpdateStatusDateModal;
