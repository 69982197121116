import { useEffect, useMemo, useRef, useState } from "react";

import { isEqual } from "lodash";

import { useAppSelector, useToast } from "@/common/hooks";

import { TExtractedData } from "../../types";

import { DataExtractor } from "./DataExtractor";

export const useParseData = () => {
  const toaster = useToast();

  const { shiftTypes, staffTypes, timezone, uploadedFile } = useAppSelector(
    (state) => ({
      shiftTypes: state.roster.bulkCreateUsers.unitData.shiftTypes,
      staffTypes: state.roster.bulkCreateUsers.unitData.staffTypes,
      timezone: state.roster.bulkCreateUsers.unitData.timezone,
      uploadedFile: state.roster.bulkCreateUsers.uploadedFile,
    }),
    isEqual,
  );

  const [data, setData] = useState<TExtractedData>(null);
  const lastRanWith = useRef<File | null>(null);

  useEffect(() => {
    if (!staffTypes?.length || !timezone || !uploadedFile || !toaster || !shiftTypes?.length)
      return;
    if (lastRanWith.current === uploadedFile) return;

    lastRanWith.current = uploadedFile;
    const extractor = new DataExtractor(staffTypes, shiftTypes, timezone, toaster);
    void extractor.extract(uploadedFile).then((extractedData) => {
      setData(extractedData);
    });
  }, [uploadedFile, staffTypes, shiftTypes, timezone, toaster]);

  return useMemo(
    () => ({
      data,
      shiftTypes,
      staffTypes,
    }),
    [data, shiftTypes, staffTypes],
  );
};
