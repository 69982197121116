import { useSearchParams } from "react-router-dom";

import { isEqual, isInteger } from "lodash";

import { useListStaffRosterQuery } from "@/api";
import { NOT_EXISTING_UUID } from "@/common/constants";
import { useAppSelector, useCurrentSelectedUnitId } from "@/common/hooks";

import { ERosterUserOptions } from "../StaffRoster";

/**
 * Custom hook to fetch a list of staff members for the staff roster page based on various parameters.
 *
 * @param {Array<boolean>} suspended - An array indicating whether to include suspended users.
 * @param {ERosterUserOptions} [userOption=ERosterUserOptions.all] - The user option to filter the staff list.
 * @returns {object} The result of the `useListStaffRosterQuery` containing the staff list and query status.
 *
 * @description
 * This hook utilizes the `useListStaffRosterQuery` to fetch a list of staff members for the staff roster page from the API.
 * It considers the current unit ID, pagination, and filters from the application state.
 * The query is skipped if no unit ID is available.
 *
 * @example
 * const { data, isLoading } = useListStaff([true], ERosterUserOptions.home);
 */
export const useListStaff = (
  suspended: Array<boolean>,
  userOption: ERosterUserOptions = ERosterUserOptions.all,
) => {
  const unitId = useCurrentSelectedUnitId();
  const { pageSize, filters } = useAppSelector(
    (state) => ({
      pageSize: state.roster.rosterTable.pageSize,
      filters: state.roster.staffRosterfilters,
    }),
    isEqual,
  );

  const [searchParams] = useSearchParams();
  const pageParam = Number(searchParams.get("page"));

  const page = !isNaN(pageParam) && isInteger(pageParam) && pageParam >= 1 ? pageParam : 1;
  const skip = (page - 1) * pageSize;

  return useListStaffRosterQuery(
    {
      unitId: unitId || NOT_EXISTING_UUID,
      skip,
      take: pageSize,
      suspended,
      userOption,
      // Staff Roster filters
      employmentTypes: filters.employmentTypes,
      staffTypes: filters.staffTypes,
    },
    {
      skip: !unitId,
    },
  );
};
