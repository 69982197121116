import { Dayjs, getTzFormattedDate } from "@m7-health/shared-utils";

import { Typography } from "@mui/material";

import { CustomDatePicker } from "@/common/components";

import { TDataComponent } from "./types";

export const DateField: TDataComponent<Dayjs> = {
  editor: ({ onBlur, value, updateCell, timezone, focused }) => {
    return (
      <CustomDatePicker
        autoFocus={focused}
        name="dateField"
        label={null}
        value={value}
        timezone={timezone}
        onChange={(newValue) => updateCell(newValue || undefined)}
        slotProps={{
          textField: {
            variant: "standard",
            size: "small",
            onBlur: (event) => {
              // If click on the date picker, don't blur
              if (event.relatedTarget?.ariaLabel?.startsWith("Choose date")) return;

              onBlur();
            },
            autoFocus: focused,
          },
        }}
        onClose={onBlur}
      />
    );
  },
  readonly: ({ value, timezone }) => (
    <Typography>{value ? getTzFormattedDate(value, timezone) : "N/A"}</Typography>
  ),
};
