import { Box } from "@mui/material";

import { SideBar, UserTable } from "./components";

import "./BulkCreateUsersPage.scss";

const componentId = "bulk-create-users-page";
export const BulkCreateUsersPage = () => {
  return (
    <Box data-testid={componentId} className={componentId}>
      <SideBar />
      <UserTable />
    </Box>
  );
};
