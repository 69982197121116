import { entries, sortBy } from "lodash";

import { useDeepMemo } from "../useDeepMemo";

/** This hook is a memoized wrapper around lodash's entries function that allows you to get the entries of an object.
 *
 * @example results = useEntries(users);
 */
export const useEntries = <T>(
  object: T,
  options: {
    sortBy?: Parameters<typeof sortBy>[1];
  } = {
    sortBy: undefined,
  },
): [keyof T, T[keyof T]][] =>
  useDeepMemo(() => {
    let results = entries(object);
    if (options.sortBy) results = sortBy(results, options.sortBy);
    return results;
  }, [object, options.sortBy]);
