import { Dayjs, getTzDayjs } from "@m7-health/shared-utils";

import { ParserError } from "../DataParser";

import { TDataParser } from "./types";

export const dayJsParser: TDataParser<Dayjs> = (value, dataParser) => {
  if (!value) return null;

  const stringValue = value?.toString() || "";
  try {
    const dayJsValue = getTzDayjs(stringValue, dataParser.timezone);
    if (dayJsValue.isValid()) return dayJsValue;
  } catch {}

  throw new ParserError("Invalid date");
};
