import { useEffect } from "react";

import { isEqual } from "lodash";

import { useAppSelector } from "~/common/hooks/useRedux";

import { User } from "@/api";
import { useCurrentRole } from "@/common/hooks";
import { httpClient } from "@/common/packages/httpClient";

/**
 * Defines the priority order for default roles upon login.
 * Lower numbers indicate higher priority.
 *
 * @type {Record<User.ERole, number>}
 */
const defaultRoleOnLogInPriority: Record<User.ERole, number> = {
  [User.ERole.admin]: 0,
  [User.ERole.scheduler]: 1,
  [User.ERole.staff]: 2,
  [User.ERole.kiosk]: 3,
};

export const useSetCurrentRole = () => {
  const availableRoles = useAppSelector((state) => state.user.userData.roles, isEqual);
  const currentRole = useCurrentRole().userIs;

  useEffect(() => {
    const sortedRoles = [...availableRoles].sort(
      (aRole, bRole) => defaultRoleOnLogInPriority[aRole] - defaultRoleOnLogInPriority[bRole],
    );
    // if don't yet have a current role, set the correct role in order of priority
    // else if the current role is not in the list of roles, set the correct role in order of priority (eg if someone is removed from a role)
    if (!currentRole) {
      httpClient.setCurrentRole(sortedRoles[0]);
    } else if (!availableRoles.includes(currentRole)) {
      httpClient.setCurrentRole(sortedRoles[0]);
    }
  }, [availableRoles, currentRole]);
};
