import PlatformUpdatesMessage from "~/common/components/AppWideModal/PlatformUpdatesMessage";
import { EditSettings } from "~/common/components/EditSettings/EditSettings";
import { SendMassSms } from "~/common/components/SendMassSms/SendMassSms";
import { SendSingleSms } from "~/common/components/SendSingleSms/SendSingleSms";
import { useAppFlags } from "~/common/hooks/useAppFlags";
import { useAppSelector } from "~/common/hooks/useRedux";

/**
 * This component is mounted at the root of the app and is used to display modals that are not
 *  tied to a specific page, or can be displayed on any page.
 *
 * eg: Mass send Text, logout modal, etc.
 */

export const AppWideModalContainer = () => {
  const { platformUpdatesInProgress: arePlatformUpdatesInProgress } = useAppFlags();

  const modals = [
    {
      component: PlatformUpdatesMessage,
      isOpen: arePlatformUpdatesInProgress?.isOpen || false,
      name: "App-wide message modal",
    },
    {
      component: SendMassSms.Modal,
      isOpen: useAppSelector((state) => state.roster.massSendSmsModal.isOpen),
      name: "Send mass sms modal",
    },
    {
      component: SendSingleSms.Modal,
      isOpen: useAppSelector((state) => state.roster.singleSendSmsModal.isOpen),
      name: "Send single sms modal",
    },
    {
      component: EditSettings.Modal,
      isOpen: useAppSelector((state) => state.roster.editSettingsModal.isOpen),
      name: "Edit Settings Modal",
    },
  ].filter(({ isOpen }) => isOpen);

  return (
    <>
      {modals.map(({ component: Component }) => (
        <Component key={Component.name} />
      ))}
    </>
  );
};
