import { useEffect } from "react";

import { EPermissionVerbs, EUnitPermissionAreas } from "@m7-health/shared-utils";
import { entries, groupBy, sortBy } from "lodash";

import { useUnitQuery } from "~/routes/queries";

import {
  useAppDispatch,
  useCheckUserPermissions,
  useCurrentRole,
  useCurrentUnitId,
  useCurrentUserPermissions,
  useIsFacilityBasedView,
} from "@/common/hooks";
import { setSelectedUnit } from "@/common/store";
import { Uuid } from "@/common/types";
import { useResetAllStates } from "@/common/utils/resetAllStates";

// Based on permissions,
export const useSetFallbackUnit = () => {
  const dispatch = useAppDispatch();
  const resetAllState = useResetAllStates();
  const userCan = useCheckUserPermissions();

  const currentUnitId = useCurrentUnitId();
  const { data: units } = useUnitQuery();
  const { permissionsDataByUnit } = useCurrentUserPermissions();
  const { userIsScheduler } = useCurrentRole();

  const currentViewUsesFacilitySelector = useIsFacilityBasedView();
  let skipOptimalUnitId = !userIsScheduler;

  // If user isn't on facility base tab, no fallback
  if (!currentViewUsesFacilitySelector) skipOptimalUnitId = true;

  // If user already have some permission on current unit, no fallback
  if (
    [
      EUnitPermissionAreas.scheduleGrid,
      EUnitPermissionAreas.fairness,
      EUnitPermissionAreas.approvals,
      EUnitPermissionAreas.staffRoster,
      EUnitPermissionAreas.dailyReport,
      EUnitPermissionAreas.openShifts,
    ].some((permission) => userCan("read", permission))
  )
    skipOptimalUnitId = true;

  // Reduce permissionsByUnit to a object with unitId as key and truthy permissions as value
  // Then pick the unit where the user has:
  //  - most manage permissions
  //  - then most read-only permissions
  const optimalUnitId = skipOptimalUnitId
    ? undefined
    : sortBy(
        entries(permissionsDataByUnit).reduce(
          (acc, [unitId, permissions]) => {
            const truthyPermissions: EPermissionVerbs[] = [];
            entries(permissions).forEach(
              ([area, value]) =>
                ![EUnitPermissionAreas.houseView, EUnitPermissionAreas.schedulerRoster].includes(
                  area,
                ) &&
                value &&
                truthyPermissions.push(value),
            );
            if (truthyPermissions.length) acc.push([unitId, groupBy(truthyPermissions)]);

            return acc;
          },
          [] as [
            Uuid,
            Partial<{
              [key in EPermissionVerbs]: EPermissionVerbs[];
            }>,
          ][],
        ),
        ([_, permissions]) => [
          permissions?.[EPermissionVerbs.manage]?.length || 0,
          permissions?.[EPermissionVerbs.readOnly]?.length || 0,
        ],
      ).reverse()[0]?.[0];

  // Core logic:
  // On url change - if user land on non-unit page, and have no permission on current unit
  // then set the unit to the optimal unit
  useEffect(() => {
    if (!optimalUnitId) return;
    if (currentUnitId === optimalUnitId) return;
    if (currentViewUsesFacilitySelector) {
      const unitToSelect = units?.find(({ id }) => id === optimalUnitId);
      if (unitToSelect) {
        resetAllState();
        dispatch(setSelectedUnit(unitToSelect));
      }
    }
  }, [
    currentUnitId,
    currentViewUsesFacilitySelector,
    dispatch,
    optimalUnitId,
    permissionsDataByUnit,
    resetAllState,
    units,
    userCan,
  ]);

  return optimalUnitId;
};
