// eslint-disable-next-line deprecate/import
import {
  ToggleButton as MuiToggleButton,
  ToggleButtonProps,
  ToggleButtonTypeMap,
} from "@mui/material";
import { OverridableComponent } from "@mui/material/OverridableComponent";

import { useAppTracking } from "~/modules/mixpanel/Context";
import { Mxp } from "~/modules/mixpanel/types";

export const ToggleButton: OverridableComponent<
  ToggleButtonTypeMap<{ trackingLabel: string | null }>
> = ({
  trackingLabel,
  ...props
}: {
  trackingLabel: string | null;
} & ToggleButtonProps) => {
  const track = useAppTracking();

  const onClickWrapper: (typeof props)["onClick"] = (...args) => {
    if (trackingLabel) {
      track(Mxp.Event.elementClicked, {
        [Mxp.Property.element.type]: Mxp.ElementsTypes.link,
        [Mxp.Property.element.label]: trackingLabel,
      });
    }
    props?.onClick?.(...args);
  };

  return <MuiToggleButton {...props} onClick={onClickWrapper} />;
};
