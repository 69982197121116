import { useEffect, useMemo, useState } from "react";

import { User } from "@/api";

import { httpClient } from "../packages/httpClient";

// Hook that listens to the current role from the httpClient and updates the state whenever it changes
// Should only ever be used within the useCurrentRole hook below
const useCurrentM7HeaderRole = () => {
  const [role, setRole] = useState<User.ERole | undefined>(httpClient.getCurrentRole());

  useEffect(() => {
    const handleRoleChange = () => setRole(httpClient.getCurrentRole());
    httpClient.eventEmitter.on("currentRoleChanged", handleRoleChange);
    handleRoleChange();

    return () => {
      httpClient.eventEmitter.removeListener("currentRoleChanged", handleRoleChange);
    };
  }, []);

  return role;
};

/**
 * Custom hook to get the current role of the user.
 *
 * This hook listens to the current role from the httpClient and updates the state whenever it changes.
 * It returns an object containing the current role and boolean flags indicating the specific role of the user.
 *
 * @returns {Object} An object containing the current role and boolean flags for each specific role.
 * @property {User.ERole | undefined} userIs - The current role of the user.
 * @property {boolean} userIsAdmin - Indicates if the user is an admin.
 * @property {boolean} userIsScheduler - Indicates if the user is a scheduler.
 * @property {boolean} userIsStaff - Indicates if the user is a staff member.
 * @property {boolean} userIsKiosk - Indicates if the user is a kiosk.
 */
export const useCurrentRole = () => {
  const currentRole = useCurrentM7HeaderRole();

  return useMemo(() => {
    return {
      userIs: currentRole,
      userIsAdmin: currentRole === User.ERole.admin,
      userIsScheduler: currentRole === User.ERole.scheduler,
      userIsStaff: currentRole === User.ERole.staff,
      userIsKiosk: currentRole === User.ERole.kiosk,
    };
  }, [currentRole]);
};
