import { useMemo } from "react";

import { filter, isEqual, map } from "lodash";

import { useListStaffDetailsQuery, useListUsersQuery } from "@/api";
import { NOT_EXISTING_UUID } from "@/common/constants";
import { useCurrentUnitId } from "@/common/hooks";
import { useAppSelector } from "@/common/hooks/useRedux";

import { EStaffStatus } from "../../types";

/**
 * Get users for the send reset password modal
 *
 * If users in store, make a user list query and use that
 * Otherwise, grab all HOME UNIT staff and through details query
 */
export const useGetUsers = () => {
  const unitId = useCurrentUnitId();

  // Store data
  const storeUserIds = useAppSelector(
    (state) => state.roster.sendResetPasswordModal.userIds,
    isEqual,
  );

  // Queries
  const queryParams = useMemo(
    () =>
      storeUserIds?.length ? { userIds: storeUserIds } : { unitIds: [unitId || NOT_EXISTING_UUID] },
    [storeUserIds, unitId],
  );
  const { data: userFromParams } = useListUsersQuery(queryParams, {
    skip: !storeUserIds?.length,
  });
  const { data: homeUnitStaff } = useListStaffDetailsQuery(
    { homeUnitIds: [unitId || NOT_EXISTING_UUID] },
    { skip: !unitId || !!storeUserIds?.length },
  );

  // Computed response
  return useMemo(() => {
    if (storeUserIds?.length) {
      return userFromParams || [];
    }
    return map(
      filter(
        homeUnitStaff,
        (details) => details.status === EStaffStatus.Active && !details.user.isSoftDeleted,
      ),
      "user",
    );
  }, [storeUserIds, userFromParams, homeUnitStaff]);
};
