import { get, values } from "lodash";

import { TextField, Typography } from "@mui/material";

import { StaffDetails } from "@/api";
import { Autocomplete } from "@/common/components";

import { TDataComponent } from "./types";

const options = values(StaffDetails.EEmploymentType);

export const EmploymentTypeField: TDataComponent<StaffDetails.EEmploymentType> = {
  editor: ({ value, updateCell, onBlur, focused }) => {
    return (
      <Autocomplete<StaffDetails.EEmploymentType>
        autoFocus={focused}
        open={focused}
        options={options}
        value={value}
        slotProps={{
          popper: {
            onClick: (event) => {
              updateCell(
                get(event.target, "textContent") as unknown as StaffDetails.EEmploymentType,
              );
              onBlur();
            },
          },
        }}
        clearIcon={null}
        renderInput={(params) => (
          <TextField
            {...params}
            size="small"
            variant="standard"
            onBlur={onBlur}
            autoFocus={focused}
          />
        )}
        trackingLabel={null}
      />
    );
  },
  readonly: ({ value }) => <Typography>{value || "N/A"}</Typography>,
};
