// eslint-disable-next-line deprecate/import
import { Link as MuiLink, LinkProps, LinkTypeMap } from "@mui/material";
import { OverridableComponent } from "@mui/material/OverridableComponent";

import { useAppTracking } from "~/modules/mixpanel/Context";
import { Mxp } from "~/modules/mixpanel/types";

export const Link: OverridableComponent<LinkTypeMap<{ trackingLabel: string | null }>> = ({
  trackingLabel,
  ...props
}: {
  trackingLabel: string | null;
} & LinkProps) => {
  const track = useAppTracking();

  const onClickWrapper: (typeof props)["onClick"] = (...args) => {
    if (trackingLabel) {
      track(Mxp.Event.elementClicked, {
        [Mxp.Property.element.type]: Mxp.ElementsTypes.link,
        [Mxp.Property.element.label]: trackingLabel,
      });
    }
    props?.onClick?.(...args);
  };

  return <MuiLink {...props} onClick={onClickWrapper} />;
};
