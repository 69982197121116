import { buildApiMutationHook } from "../_shared/helpers";

import { Facility } from "./types";

export const useUpdateFacilityMutation = buildApiMutationHook<
  Facility.API.Update.QueryParams,
  Facility.API.Update.Response
>(Facility.API.Update.PATH, "patch");

export const useCreateFacilityMutation = buildApiMutationHook<
  Facility.API.Create.QueryParams,
  Facility.API.Create.Response
>(Facility.API.Create.PATH, "post");
