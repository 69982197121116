import { Timezone } from "@m7-health/shared-utils";

import { IShiftType, IStaffType } from "@/api";
import { useToast } from "@/common/hooks";

import { TExtractedData } from "../../types";

import { DataParser } from "./DataParser";
import { FileReader } from "./FileReader";

/**
 * Extracts data from a file, parses it, transforms cells data
 *   and returns the result and errors.
 */
export class DataExtractor {
  private readonly fileReader: FileReader;
  private readonly dataParser: DataParser;

  constructor(
    staffTypes: IStaffType[],
    shiftTypes: IShiftType[],
    timezone: Timezone,
    private readonly toaster: ReturnType<typeof useToast>,
  ) {
    this.fileReader = new FileReader(toaster);
    this.dataParser = new DataParser(staffTypes, shiftTypes, timezone);
  }

  async extract(file: File | null): Promise<TExtractedData> {
    try {
      // 1 - read the file
      const unparsedData = await this.fileReader.read(file);
      if (!unparsedData) return null;

      // 2 - parse the data
      const data = this.dataParser.parseData(unparsedData);
      const isValid = !data.columnErrors?.length && !data.cellErrors?.length;

      // 3 - return the data
      return {
        ...data,
        isValid,
      };
    } catch (error) {
      this.toaster.showError((error as Error).message);
      return null;
    }
  }
}
