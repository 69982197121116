import { useEffect, useRef, useState } from "react";

import { useMutation } from "@tanstack/react-query";

import { Sms } from "@mui/icons-material";
import { Box, TextField, Typography } from "@mui/material";

import { StaffDetails } from "~/api/staffDetails/types";
import CustomModal from "~/common/components/Modal";
import CustomSelect from "~/common/components/TrackedComponents/Select";
import { useErrors } from "~/common/hooks/useErrors";
import { useAppDispatch, useAppSelector } from "~/common/hooks/useRedux";
import { useToast } from "~/common/hooks/useToast";
import { emptySx, EStaffCategoryName, EStaffTypeName, TSx } from "~/common/types";
import { sendMassSmsApi } from "~/features/Roster/api";
import { setMassSendSmsModalOpen } from "~/features/Roster/store";
import { useStaffCategoriesTargetLevelQuery, useStaffTypesAndCategories } from "~/routes/queries";

import { useListStaffDetailsQuery } from "@/api";
import { NOT_EXISTING_UUID } from "@/common/constants";
import { useCurrentUnit, useCurrentRole } from "@/common/hooks";

import CustomButton from "../TrackedComponents/Button";

const emptyStaffDetailsDtosArray: StaffDetails.DTO[] = [];

const Button = ({ sx = emptySx }: { sx?: TSx }) => {
  const dispatch = useAppDispatch();

  const { userIsAdmin, userIsScheduler } = useCurrentRole();

  const hasMassSendSmsPermission = userIsAdmin || userIsScheduler;
  const handleMassSendSmsModal = () => {
    dispatch(setMassSendSmsModalOpen(true));
  };

  if (!hasMassSendSmsPermission) return <></>;

  return (
    <CustomButton
      sx={{ width: "150px", ...sx }}
      label="Send Unit Text"
      onClick={handleMassSendSmsModal}
      startIcon={<Sms />}
    />
  );
};

const Modal = () => {
  const dispatch = useAppDispatch();
  const { handleErrors } = useErrors();
  const { showSuccess, showWarning } = useToast();

  const selectedUnit = useCurrentUnit();
  const isOpen = useAppSelector((state) => state.roster.massSendSmsModal.isOpen);
  const { mutate, isPending: isLoading } = useMutation({
    mutationFn: sendMassSmsApi,
    onSuccess: (data) => {
      dispatch(setMassSendSmsModalOpen(false));
      // Check errors array
      const errors = data.errors;
      if (errors.length) {
        showWarning(
          `Text sent. The message failed to be delivered to ${errors.length} phone number${errors.length > 1 ? "s" : ""}.`,
        );
      } else {
        showSuccess("Text sent successfully");
      }
      message.current = "";
    },
    onError: handleErrors,
  });

  const [selectedCategories, setSelectedCategories] = useState<EStaffCategoryName[]>([]);
  const [selectedEmploymentTypes, setSelectedEmploymentTypes] = useState<
    StaffDetails.EEmploymentType[]
  >([]);
  const [filteredStaff, setFilteredStaff] = useState<StaffDetails.DTO[]>([]);
  const [contentIsEmpty, setContentIsEmpty] = useState(true);
  const message = useRef("");
  const { data: staffDetailsDtos = emptyStaffDetailsDtosArray } = useListStaffDetailsQuery(
    { homeUnitIds: [selectedUnit?.id || NOT_EXISTING_UUID], active: [true] },
    { skip: !selectedUnit?.id },
  );
  // Dropdown to select users by type
  const { data: allDisplayableCategories = [] } = useStaffCategoriesTargetLevelQuery();
  const { data: staffTypesCategoriesHashMap = {} } = useStaffTypesAndCategories();
  const nonZeroCategories = staffDetailsDtos.reduce(
    (acc, user) => {
      const mappedCategory = staffTypesCategoriesHashMap[user.staffType.name];
      if (mappedCategory) {
        acc[mappedCategory] = true;
      }
      return acc;
    },
    {} as Record<EStaffTypeName | EStaffCategoryName, boolean>,
  );
  const categoryItems = allDisplayableCategories
    .filter((category) => nonZeroCategories[category.name])
    .map((category) => ({
      value: category.name,
      label: category.name,
      item: category.name,
    }));

  const employmentTypeItems = Object.values(StaffDetails.EEmploymentType).map((employmentType) => ({
    value: employmentType,
    label: employmentType,
    item: employmentType,
  }));
  const activeStaffItems = staffDetailsDtos
    .map((staff) => ({
      value: staff.userId,
      label: `${staff.user.firstName} ${staff.user.lastName}, ${staff.staffType.name}`,
      item: `${staff.user.firstName} ${staff.user.lastName}`,
    }))
    .sort((a, b) => a.label.localeCompare(b.label));

  /* eslint-enable react-hooks/exhaustive-deps */

  useEffect(() => {
    setFilteredStaff(
      staffDetailsDtos.filter((staff) => {
        const mappedCategory = staffTypesCategoriesHashMap[staff.staffType.name];
        const employmentType = staff.employmentType;
        return (
          mappedCategory &&
          selectedCategories.includes(mappedCategory as EStaffCategoryName) &&
          selectedEmploymentTypes.includes(employmentType)
        );
      }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    selectedCategories,
    staffDetailsDtos,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    JSON.stringify(staffTypesCategoriesHashMap),
    selectedEmploymentTypes,
  ]);

  const confirmSending = () => {
    if (
      window.confirm(
        `You are about to send an Text to ${
          filteredStaff.length
        } staff members.\n${selectedCategories.join(", ")}`,
      )
    ) {
      const userIds = filteredStaff.map((staff) => staff.userId);
      mutate({
        userIds: userIds,
        messageContent: message.current,
      });
    }
  };

  const handleSecondaryBtnClick = () => {
    dispatch(setMassSendSmsModalOpen(false));
  };

  return (
    <CustomModal
      isOpen={isOpen}
      withBlurBackground={false}
      sx={{ overflowY: "auto" }}
      primaryBtnText="Send Text"
      modalHeaderText={"Send Text to Staff"}
      onSecondaryBtnClick={handleSecondaryBtnClick}
      onSubmit={confirmSending}
      primaryDisabled={isLoading || filteredStaff.length === 0 || contentIsEmpty}
      variant="warning"
      variantText={
        <>
          You are about to send an Text to
          <u>
            <b> {filteredStaff.length} </b>
          </u>{" "}
          staff members.
          <br />
          <i style={{ fontWeight: "400" }}>
            Text will be sent from {selectedUnit?.facility?.twilioPhoneNumber}
          </i>
        </>
      }
      modalContent={
        <Box mt={3}>
          <CustomSelect<EStaffCategoryName>
            multiple
            checked
            items={categoryItems}
            label="Select category"
            value={selectedCategories}
            onChange={(event) => {
              const values = (
                Array.isArray(event.target.value) ? event.target.value : [event.target.value]
              ) as EStaffCategoryName[];
              setSelectedCategories(values);
            }}
            sx={{ mb: 3 }}
          />
          <CustomSelect<StaffDetails.EEmploymentType>
            multiple
            checked
            items={employmentTypeItems}
            label="Select employment type"
            value={selectedEmploymentTypes}
            onChange={(event) => {
              const values = (
                Array.isArray(event.target.value) ? event.target.value : [event.target.value]
              ) as StaffDetails.EEmploymentType[];
              setSelectedEmploymentTypes(values);
            }}
            sx={{ mb: 3 }}
          />
          <Typography sx={{ mb: 2 }}>Select or deselect receiving staff to include:</Typography>
          <Box maxWidth={"500px"}>
            <CustomSelect<string>
              multiple
              checked
              customDisplayOption={`${filteredStaff.length} Users Selected`}
              items={activeStaffItems}
              label="Receiving Staff"
              value={filteredStaff.map((staff) => staff.userId)}
              onChange={(event) => {
                const values = Array.isArray(event.target.value)
                  ? event.target.value
                  : [event.target.value];
                const newFilteredUsers = staffDetailsDtos.filter((user) =>
                  values.includes(user.userId),
                );
                setFilteredStaff(newFilteredUsers);
              }}
              sx={{ mb: 3 }}
            />
          </Box>

          <TextField
            fullWidth
            multiline
            onChange={(event) => {
              message.current = event.target.value;
              setContentIsEmpty(event.target.value === "");
            }}
            placeholder="Enter your message here"
            minRows={8}
          />
        </Box>
      }
    />
  );
};

export const SendMassSms = {
  Button,
  Modal,
};
