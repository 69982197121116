import { useEffect, useMemo } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { useAppDispatch, useAppSelector } from "@/common/hooks/useRedux";
import { setHistory } from "@/common/store";

const useTrackLocationHistory = () => {
  const dispatch = useAppDispatch();
  const location = useLocation();

  useEffect(() => {
    dispatch(setHistory(location));
  }, [location, dispatch]);
};

const useHistory = () => {
  const navigate = useNavigate();

  const history = useAppSelector((state) => state.common.history);

  return useMemo(
    () => ({
      history,
      goBack: () => navigate(-1),
    }),
    [history, navigate],
  );
};

export { useTrackLocationHistory, useHistory };
