import { useMemo, useState } from "react";

import { get } from "lodash";

import { Box, FormControl, Stack, Typography } from "@mui/material";

import {
  IPreferenceRequirementRuleSet,
  IShiftType,
  IUnitShiftType,
  useListPreferenceRequirementRuleSetsQuery,
  useListUnitShiftTypeQuery,
} from "@/api";
import { CustomModal } from "@/common/components";
import { BULK_DELETE_KEY, NOT_EXISTING_UUID } from "@/common/constants";
import { useCurrentUnit, useOrderedPreferenceRequirement } from "@/common/hooks";
import { darkGray } from "@/common/theming";

import { useActions } from "./actions";
import { RuleElements, RuleSetElements } from "./Elements";
import { useHumanReadableWeekendBoundaries, useIsValid } from "./hooks";

const emptyUnitShiftTypes: IUnitShiftType[] = [];
const emptyExistingRuleSets: IPreferenceRequirementRuleSet[] = [];

export const UpdateRuleSetModal = () => {
  const unit = useCurrentUnit();
  const unitId = unit?.id;
  const weekendBoundaries = useHumanReadableWeekendBoundaries(unit);

  const { data: shiftTypes = emptyUnitShiftTypes } = useListUnitShiftTypeQuery(
    { unitIds: [unitId || NOT_EXISTING_UUID] },
    { skip: !unitId },
  );
  const { data: existingRuleSets = emptyExistingRuleSets } =
    useListPreferenceRequirementRuleSetsQuery(
      { unitIds: [unitId || NOT_EXISTING_UUID] },
      { skip: !unitId },
    );

  const [showError, setShowError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const {
    actions: { createNewRule, deleteRule, updateRule, save, cancel },
    ruleState: [ruleSet, setRuleSet],
  } = useActions(unitId);

  const orderedRules = useOrderedPreferenceRequirement(ruleSet.preferenceRequirementRules);
  const isValid = useIsValid(ruleSet);

  // Form
  const modalContent = useMemo(() => {
    return (
      <FormControl>
        <RuleSetElements.RuleSetFields
          existingRuleSets={existingRuleSets}
          showError={showError}
          ruleSet={ruleSet}
          setRuleSet={setRuleSet}
        />
        <Box className="rule-container" sx={{ maxHeight: "70vh", overflow: "auto" }}>
          <RuleSetElements.RuleSetDescription ruleSet={ruleSet} setRuleSet={setRuleSet} />
          <Box>
            <Typography variant="h6">Shift Requirement</Typography>
            {orderedRules.regularRequirements?.map((rule, index) => {
              if (get(rule, BULK_DELETE_KEY)) return null;

              return (
                <RuleElements.RuleWrapper index={index} key={rule.id}>
                  <Stack direction="row" spacing={2} m={1}>
                    <RuleElements.RuleLabel
                      rule={rule}
                      updateRule={updateRule}
                      showError={showError}
                    />
                  </Stack>
                  <Stack direction="row" spacing={2} m={1} alignItems="center">
                    <RuleElements.Phrase content={"Required to work between"} width={"140px"} />
                    <RuleElements.MinMax
                      rule={rule}
                      updateRule={updateRule}
                      showError={showError}
                    />
                    <RuleElements.TargetTypePicker rule={rule} updateRule={updateRule} />
                    <RuleElements.Phrase content={"of"} width={"20px"} />
                    <RuleElements.ShiftTypePicker
                      rule={rule}
                      shiftTypes={shiftTypes}
                      updateRule={updateRule}
                      showError={showError}
                    />
                    <RuleElements.FrequencyPicker rule={rule} updateRule={updateRule} />
                    <RuleElements.DeleteRule deleteRule={deleteRule} rule={rule} />
                  </Stack>
                </RuleElements.RuleWrapper>
              );
            })}
            <RuleElements.AddRule createNewRule={createNewRule} label="Add Requirement" />
          </Box>
          <Box>
            <Typography variant="h6" mt={1} sx={{ display: "flex", alignItems: "center" }}>
              Weekend Requirements
              <Typography
                variant="body2"
                children={weekendBoundaries}
                sx={{ ml: 2, fontStyle: "italic" }}
                color={darkGray}
              />
            </Typography>
            {orderedRules.weekendRequirements.map((rule, index) => {
              if (get(rule, BULK_DELETE_KEY)) return null;

              return (
                <RuleElements.RuleWrapper index={index} key={rule.id}>
                  <Stack direction="row" spacing={2} m={1}>
                    <RuleElements.RuleLabel
                      rule={rule}
                      updateRule={updateRule}
                      showError={showError}
                    />
                  </Stack>
                  <Stack direction="row" spacing={2} m={1} alignItems="center">
                    <RuleElements.Phrase content={"Required to work between"} width={"140px"} />
                    <RuleElements.MinMax
                      rule={rule}
                      updateRule={updateRule}
                      showError={showError}
                    />
                    <RuleElements.TargetTypePicker rule={rule} updateRule={updateRule} />
                    <RuleElements.Phrase content={"of"} width={"20px"} />
                    <RuleElements.ShiftTypePicker
                      rule={rule}
                      shiftTypes={shiftTypes}
                      updateRule={updateRule}
                      showError={showError}
                    />
                    <RuleElements.FrequencyPicker rule={rule} updateRule={updateRule} />
                    <RuleElements.DeleteRule deleteRule={deleteRule} rule={rule} />
                  </Stack>
                </RuleElements.RuleWrapper>
              );
            })}
            <RuleElements.AddRule
              createNewRule={() => createNewRule({ targetIsWeekend: true })}
              label="Add Weekend Requirement"
            />
          </Box>
          <Box>
            <Typography variant="h6">Block Requirements</Typography>
            {orderedRules.blockRequirements.map((rule, index) => {
              if (get(rule, BULK_DELETE_KEY)) return null;

              return (
                <RuleElements.RuleWrapper index={index} key={rule.id}>
                  <Stack direction="row" spacing={2} m={1}>
                    <RuleElements.RuleLabel
                      rule={rule}
                      updateRule={updateRule}
                      showError={showError}
                    />
                  </Stack>
                  <Stack direction="row" spacing={2} m={1} alignItems="center">
                    <RuleElements.Phrase content={"Allowed to request between"} width={"140px"} />
                    <RuleElements.MinMax
                      rule={rule}
                      updateRule={updateRule}
                      showError={showError}
                    />
                    <RuleElements.TargetTypePicker
                      rule={rule}
                      updateRule={updateRule}
                      disabled={true}
                    />
                    <RuleElements.Phrase content={"of"} width={"20px"} />
                    <RuleElements.ShiftTypePicker
                      rule={rule}
                      shiftTypes={shiftTypes}
                      updateRule={updateRule}
                      showError={showError}
                      readonly
                    />
                    <RuleElements.FrequencyPicker rule={rule} updateRule={updateRule} />
                    <RuleElements.DeleteRule deleteRule={deleteRule} rule={rule} />
                  </Stack>
                </RuleElements.RuleWrapper>
              );
            })}
            {!orderedRules.blockRequirements.filter((rule) => !get(rule, BULK_DELETE_KEY))
              .length && (
              <RuleElements.AddRule
                createNewRule={() =>
                  createNewRule({
                    targetShiftTypeKeys: ["block" as IShiftType["key"]],
                  })
                }
                label="Add Block Requirement"
              />
            )}
          </Box>
        </Box>
      </FormControl>
    );
  }, [
    existingRuleSets,
    showError,
    ruleSet,
    setRuleSet,
    createNewRule,
    orderedRules,
    updateRule,
    shiftTypes,
    deleteRule,
    weekendBoundaries,
  ]);

  return (
    <CustomModal
      trackingLabel="preference-requirement-form"
      isOpen
      modalContent={
        <>
          {modalContent}
          {showError && !isValid && errorMessage}
        </>
      }
      isLoading={isLoading}
      primaryBtnText="Save"
      secondaryBtnText="Cancel"
      closeDisabled
      primaryDisabled={!isValid && showError}
      onSecondaryBtnClick={cancel}
      sx={{ width: "80wv" }}
      onSubmit={() => {
        if (isValid) {
          setIsLoading(true);
          save()
            .catch(() => setShowError(true))
            .finally(() => setIsLoading(false));
        } else setShowError(true);
      }}
    />
  );
};

const errorMessage = (
  <Typography
    color="error"
    children={"Please fill all required fields"}
    sx={{
      position: "absolute",
      top: "80px",
      left: "40px",
    }}
  />
);
