import { useCallback, useEffect, useMemo, useState } from "react";

import {
  find,
  includes,
  intersection,
  isEqual,
  isUndefined,
  map,
  omitBy,
  sortBy,
  uniqBy,
  values,
} from "lodash";

import {
  ISchedule,
  IShiftType,
  IWorkingHours,
  StaffCategory,
  StaffDetails,
  StaffShift,
  User,
} from "~/api";
import { OVERTIME_IN_SECONDS } from "~/common/constants";
import { useAppDispatch, useAppSelector } from "~/common/hooks/useRedux";
import { KeyBy } from "~/common/types";
import { houseViewStore, THouseViewState } from "~/features/HouseView/store";
import { EHVEmploymentTypeFilter } from "~/features/HouseView/store/findStaffToWorkActions";
import { useAppConfigQuery } from "~/features/User/queries";
import { IShift } from "~/routes/api/types";

import { EStaffStatus } from "#/features/Roster/types";
import { useAppFlags } from "@/common/hooks";
import { isOnMobile } from "@/common/utils/isOnMobile";

import { eligibleToWork } from "../helpers/eligibleToWork";

const filtersDefaults: THouseViewState["findStaffToWork"]["filters"] = {
  hideOvertime: true,
  employmentTypes: values(EHVEmploymentTypeFilter),
  unit: ["HOME", "OTHER"] as ("HOME" | "OTHER")[],
  shiftTypeKeys: [null],
  preferredWorkingHours: [],
};

/**
 * Set default filters from unit config
 */
export const useFindStaffToWorkFilters = ({
  shiftTypeByScheduleByType,
}: {
  shiftTypeByScheduleByType: Record<ISchedule["id"], KeyBy<IShift, "key">>;
}) => {
  const dispatch = useAppDispatch();

  const { useHouseviewUnitConfigDefaultFilters } = useAppFlags();
  const filters = useAppSelector((state) => state.houseView.findStaffToWork.filters) || undefined;
  const availableCategories = useAppConfigQuery().data?.staffCategories;
  const filterDefaultStaffCategory = useAppSelector(
    (state) =>
      isOnMobile()
        ? null
        : availableCategories?.find(
            (category) => category.key === state.houseView.pageFilters.selectedStaffCategory,
          )?.key,
    isEqual,
  );
  const filtersAreEmpty = filters === undefined;

  // Retrieve unit config
  const selectedUnitId = useAppSelector((state) => state.houseView.pageFilters.selectedUnitId);
  const unitConfig = useAppConfigQuery().data?.accessibleUnits.find(
    (unit) => unit.id === selectedUnitId,
  )?.configuration;

  // Retrieve shifts types to display on the filter based on current unit
  // Non working, non patient care, non paid shift types, and not approved time off
  // TODO! This must be all shift types from units from shifts, not only the current unit
  const shiftTypesFiltersToDisplay = useMemo(() => {
    const shiftTypes = uniqBy(
      values(shiftTypeByScheduleByType)
        .flat()
        .map((shiftTypesByKey) => values(shiftTypesByKey))
        .flat(),
      "key",
    );

    return shiftTypes.filter(eligibleToWork) || [];
  }, [shiftTypeByScheduleByType]);

  const NwNpNpCareShiftKeys = useMemo(
    () => map(shiftTypesFiltersToDisplay, "key"),
    [shiftTypesFiltersToDisplay],
  );
  const [filtersAreDirty, setFiltersAreDirty] = useState(false);
  const updateFilters = useMemo(
    () => (callback: Parameters<typeof houseViewStore.state.setFindStaffToWorkFilters>[0]) => {
      setFiltersAreDirty(true);
      dispatch(houseViewStore.state.setFindStaffToWorkFilters(callback));
    },
    [dispatch, setFiltersAreDirty],
  );

  const resetFilters = useCallback(() => {
    const unitDefaults = unitConfig?.defaults?.houseView?.filters || {};
    const shiftTypeKeys =
      // Make sure the unit defaults are overlapping with actual shift types
      unitDefaults?.shiftTypeKeys?.filter(
        (shiftTypeKey) =>
          // keep nulls
          !shiftTypeKey ||
          // and overlapping shift types
          NwNpNpCareShiftKeys.includes(shiftTypeKey),
        // else use the defaults
      ) ||
      filtersDefaults?.shiftTypeKeys ||
      [];
    setFiltersAreDirty(false);

    dispatch(
      houseViewStore.state.setFindStaffToWorkFilters((oldFilters) => ({
        ...filtersDefaults,
        ...(useHouseviewUnitConfigDefaultFilters ? omitBy(unitDefaults, isUndefined) : {}),
        staffCategories: filterDefaultStaffCategory
          ? [filterDefaultStaffCategory]
          : (unitDefaults.staffCategories as StaffCategory.EKey[]) || [],
        shiftTypeKeys,
        modalIsOpen: oldFilters?.modalIsOpen ?? unitDefaults.modalIsOpen ?? false,
      })),
    );
  }, [
    dispatch,
    unitConfig,
    NwNpNpCareShiftKeys,
    filterDefaultStaffCategory,
    useHouseviewUnitConfigDefaultFilters,
  ]);

  // Set default filters if not set,
  // Also reset preferredWorking filter when selectedTimeRange changes
  useEffect(() => {
    if (filtersAreEmpty) {
      resetFilters();
    } else {
      updateFilters((oldFilters) => ({
        ...oldFilters,
        preferredWorkingHours: [],
        staffCategories: filterDefaultStaffCategory ? [filterDefaultStaffCategory] : [],
      }));
    }
    // We don't need to run this effect when resetFilters changes,
    // Only dependency on filtersAreEmpty is enough
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filtersAreEmpty, filterDefaultStaffCategory]);

  return useMemo(
    () => ({
      filters,
      updateFilters,
      resetFilters,
      filtersAreDirty,
      shiftTypesFiltersToDisplay,
    }),
    [filters, shiftTypesFiltersToDisplay, filtersAreDirty, updateFilters, resetFilters],
  );
};

export const useApplyFindStaffToWorkFilters = (
  staff: User.DTO[],
  staffShiftByStaffId: KeyBy<StaffShift.DTO, "staffId">,
  staffDetailsByStaffId: KeyBy<StaffDetails.DTO, "userId">,
  staffWorkingHoursByStaffId: KeyBy<IWorkingHours, "staffId">,
  shiftTypeByScheduleByType: Record<ISchedule["id"], KeyBy<IShift, "key">>,
) => {
  const filters = useAppSelector((state) => state.houseView.findStaffToWork.filters);
  const selectedUnitId = useAppSelector((state) => state.houseView.pageFilters.selectedUnitId);

  return useMemo(() => {
    if (!filters) return staff;
    if (!staff.length) return staff;

    const {
      hideOvertime,
      employmentTypes: employmentTypeSelectedFilters,
      unit,
      shiftTypeKeys,
      preferredWorkingHours,
      staffCategories,
    } = filters;

    const filteredStaffs = staff.filter((user) => {
      if (user.deletedAt || user.isSoftDeleted || user.softDeletedDate) return false;

      const staffDetails = staffDetailsByStaffId[user.id];
      const staffWorkingHours = staffWorkingHoursByStaffId[user.id];
      const staffShift = staffShiftByStaffId[user.id];
      const staffShiftType =
        (staffShift &&
          shiftTypeByScheduleByType[staffShift?.scheduleId]?.[staffShift?.shiftTypeKey]) ||
        null;

      if (!staffDetails || staffDetails.status === EStaffStatus.Inactive) {
        return false;
      }

      // Apply staff category filter
      if (
        staffCategories?.length &&
        !staffCategories.includes(staffDetails.staffType.staffCategoryKey)
      ) {
        return false;
      }

      // Apply preferred working hours filter
      if (preferredWorkingHours?.length) {
        if (
          !intersection(preferredWorkingHours as string[], staffDetails.preferredWorkingHours || [])
            .length
        )
          return false;
      }

      // Apply shift type filter
      if (
        shiftTypeKeys?.length &&
        !shiftTypeKeys.includes((staffShiftType?.key as IShiftType["key"]) || null)
      ) {
        return false;
      }

      // Apply overtime filter
      if (hideOvertime && (staffWorkingHours?.cumulatedSeconds || 0) >= OVERTIME_IN_SECONDS) {
        return false;
      }

      // Apply employment type filter
      if (employmentTypeSelectedFilters?.length) {
        if (!staffDetails.employmentType) return false;

        // Available filter is s subset of possible employment type. Any other employment type is considered "other"
        // First we filter out the "other" employment type
        const availableFiltersWithoutOther = values(EHVEmploymentTypeFilter).filter(
          (type) => type !== EHVEmploymentTypeFilter.other,
        );

        // Then we check where the current staff employment falls
        const translatedStaffEmploymentType =
          // Or the staff employment taps into a defined filter
          find(
            availableFiltersWithoutOther,
            (notOtherAvailableFilter) =>
              (staffDetails.employmentType as string) === notOtherAvailableFilter,
          ) ||
          // Or it falls into the "other" category
          EHVEmploymentTypeFilter.other;

        if (!includes(employmentTypeSelectedFilters, translatedStaffEmploymentType)) return false;
      }

      // Apply unit filter
      if (unit?.length) {
        if (!unit.includes("HOME") && staffDetails.homeUnitId === selectedUnitId) {
          return false;
        }
        if (!unit.includes("OTHER") && staffDetails.homeUnitId !== selectedUnitId) {
          return false;
        }
      }

      // All good

      return true;
    });

    return sortBy(filteredStaffs, ["lastName", "firstName"]);
  }, [
    filters,
    staff,
    staffDetailsByStaffId,
    staffShiftByStaffId,
    staffWorkingHoursByStaffId,
    shiftTypeByScheduleByType,
    selectedUnitId,
  ]);
};
