import { lazy } from "react";
import { createBrowserRouter } from "react-router-dom";

import { EUnitPermissionAreas } from "@m7-health/shared-utils";
import { values } from "lodash";

import { BookmarkAdd, Groups3, LocalOffer, MonetizationOn } from "@mui/icons-material";
import CrisisAlertIcon from "@mui/icons-material/CrisisAlert";
import MonitorHeartIcon from "@mui/icons-material/MonitorHeart";
import MultipleStopIcon from "@mui/icons-material/MultipleStop";
import SettingsIcon from "@mui/icons-material/Settings";

import { StaffDetails } from "~/api/staffDetails/types";
import CatchAllPage from "~/common/pages/CatchAllPage";
import ErrorPage from "~/common/pages/ErrorPage";
import {
  ESchedulerRosterPages,
  ESchedulerRosterTabs,
  EStaffRosterPages,
  EStaffRosterPageTabs,
} from "~/features/Roster/types";
import { MixpanelProvider } from "~/modules/mixpanel/Provider";
import { Mxp } from "~/modules/mixpanel/types";
import ProtectedRoute from "~/routes/components/ProtectedRoute";
import RouterRoot from "~/routes/components/RouterRoot";

import { BulkCreateUsersPage } from "#/features/Roster/BulkCreateUsers/BulkCreateUsersPage";
import { ESchedulePageTabs } from "#/features/SchedulerGrid/types";
import { User } from "@/api";

import {
  ADMIN_PERMISSIONS,
  KIOSK_PERMISSIONS,
  PERMISSION_ALLOW_ALL,
  STAFF_PERMISSIONS,
  TSingularPermission,
} from "./components/ProtectedRoute/types";

const FairnessPage = lazy(() => import("../features/Fairness"));
const LoginPage = lazy(() => import("../features/User/LoginPage"));
const ProfilePage = lazy(() => import("../features/User/ProfilePage"));
const SchedulerRosterPage = lazy(() => import("../features/Roster/SchedulerRoster"));
const StaffRoster = lazy(() => import("../features/Roster/StaffRoster"));
const StatisticsPage = lazy(() => import("../features/Statistics"));
const SupportPage = lazy(() => import("../features/Support"));
const OpenShiftsPage = lazy(() => import("../features/OpenShifts"));
const DailyReportsPage = lazy(() => import("../features/DailyReports"));
const ShiftSwapRequestPage = lazy(() => import("../features/ShiftSwapRequest"));
const HouseViewPage = lazy(() => import("../features/HouseView"));
const SchedulerGridPage = lazy(() => import("#/features/SchedulerGrid"));
const AdminPanelPage = lazy(() => import("#/features/AdminPanel"));
const CxDashboardPage = lazy(() => import("#/features/CxDashboard"));
const CalendarPage = lazy(() => import("#/features/CalendarV2"));
const HolidaySchedulePage = lazy(() => import("#/features/HolidaySchedule"));
const ShiftReportsPage = lazy(() => import("#/features/ShiftReports"));

export const FALLBACK_ROUTE = "/_default";

export interface IBasicRoute {
  id: string;
  path: string;
  name: string;
  icon?: JSX.Element;
  children?: IBasicRoute[];
  roles?: User.ERole[];
  legacyPaths?: string[];
}

export interface IMainRoute extends IBasicRoute {
  component: JSX.Element;
  roles: User.ERole[];
  allowedEmploymentType?: StaffDetails.EEmploymentType[];
  permissions: (EUnitPermissionAreas | TSingularPermission)[];
}

export const ADMIN_SUB_ROUTES = {
  actions: {
    id: "15.1",
    path: "/admin/unit/actions",
    name: "Actions",
    icon: <MultipleStopIcon />,
  },
  questionnaires: {
    id: "15.2",
    path: "/admin/unit/questionnaires",
    name: "Questionnaires",
    icon: <MonitorHeartIcon />,
  },
  shiftTypes: {
    id: "15.3",
    path: "/admin/unit/shift-types",
    name: "Shifts Settings",
    icon: <BookmarkAdd />,
  },
  positions: {
    id: "15.4",
    path: "/admin/unit/attributes",
    name: "Positions",
    icon: <LocalOffer />,
  },
  unitTargetLevels: {
    id: "15.5",
    path: "/admin/unit/target-levels",
    name: "Target Levels",
    icon: <CrisisAlertIcon />,
  },
  unitConfig: {
    id: "15.6",
    path: "/admin/unit/configuration",
    name: "Unit Settings",
    icon: <SettingsIcon />,
  },
  facilityConfig: {
    id: "15.7",
    path: "/admin/unit/facility-configuration",
    name: "Facility:Config",
    icon: <SettingsIcon />,
  },
  staffTypes: {
    id: "15.8",
    path: "/admin/unit/staff-types",
    name: "Staff Types",
    icon: <Groups3 />,
  },
  incentiveLevels: {
    id: "15.9",
    path: "/admin/unit/incentive-levels",
    name: "Incentive Levels",
    icon: <MonetizationOn />,
  },
} satisfies Record<string, IBasicRoute>;

export const routes = {
  account: {
    id: "1",
    path: "/",
    name: "Login",
    component: <LoginPage />,
    roles: [],
    permissions: [PERMISSION_ALLOW_ALL],
  },
  calendar: {
    id: "9",
    path: "/calendar",
    name: "Calendar",
    component: <CalendarPage />,
    roles: [User.ERole.staff],
    permissions: [STAFF_PERMISSIONS],
  },
  fairness: {
    id: "3",
    path: "/fairness",
    name: "Fairness",
    component: <FairnessPage />,
    roles: [User.ERole.admin, User.ERole.scheduler, User.ERole.staff],
    permissions: [EUnitPermissionAreas.fairness],
  },
  profile: {
    id: "4",
    path: "/profile",
    name: "Profile",
    component: <ProfilePage />,
    roles: [User.ERole.staff],
    permissions: [STAFF_PERMISSIONS],
  },
  staffRosterUnit: {
    id: "16",
    path: "/roster/staff",
    name: "Staff",
    component: <StaffRoster />,
    roles: [User.ERole.admin, User.ERole.scheduler],
    permissions: [EUnitPermissionAreas.staffRoster],
    children: [
      {
        id: "16.1",
        path: `/roster/staff/:unitId/${EStaffRosterPageTabs.homeUnitUsers}`,
        name: "Home Unit Users",
        legacyPaths: [
          "/roster",
          "/roster/:unitId",
          "/roster/:unitId/current_users",
          `/roster/:unitId/${EStaffRosterPageTabs.homeUnitUsers}`,
        ],
        children: [
          {
            id: "16.1.1",
            path: `/roster/staff/:unitId/${EStaffRosterPageTabs.homeUnitUsers}/${EStaffRosterPages.staffDetails}/:staffId`,
            name: "Staff Profile",
          },
        ],
      },
      {
        id: "16.2",
        path: `/roster/staff/:unitId/${EStaffRosterPageTabs.suspendedUsers}`,
        name: "Suspended Users",
        legacyPaths: [`/roster/:unitId/${EStaffRosterPageTabs.suspendedUsers}`],
        children: [
          {
            id: "16.2.1",
            path: `/roster/staff/:unitId/${EStaffRosterPageTabs.suspendedUsers}/${EStaffRosterPages.staffDetails}/:staffId`,
            name: "Staff Profile",
          },
        ],
      },
      {
        id: "16.3",
        path: `/roster/staff/:unitId/${EStaffRosterPageTabs.otherUnitUsers}`,
        name: "Floats from Other Units",
        legacyPaths: [`/roster/:unitId/${EStaffRosterPageTabs.otherUnitUsers}`],
        children: [
          {
            id: "16.3.1",
            path: `/roster/staff/:unitId/${EStaffRosterPageTabs.otherUnitUsers}/${EStaffRosterPages.staffDetails}/:staffId`,
            name: "Staff Profile",
          },
        ],
      },
    ],
  },
  CSVUploadUsers: {
    id: "16",
    path: "/roster/bulk-create",
    name: "Bulk Create",
    component: <BulkCreateUsersPage />,
    roles: [User.ERole.admin],
    permissions: [EUnitPermissionAreas.staffRoster],
  },
  schedulerRoster: {
    id: "17",
    path: "/roster/scheduler",
    name: "Scheduler",
    component: <SchedulerRosterPage />,
    roles: [User.ERole.admin, User.ERole.scheduler],
    permissions: [EUnitPermissionAreas.schedulerRoster],
    children: [
      {
        id: "17.1",
        path: `/roster/scheduler/:facilityId/${ESchedulerRosterPages.schedulerDetails}`,
        name: "Add scheduler details",
        children: [
          {
            id: "17.1.1",
            path: `/roster/scheduler/:facilityId/${ESchedulerRosterPages.schedulerDetails}/:schedulerId`,
            name: "Edit scheduler details",
          },
        ],
      },
      {
        id: "17.2",
        path: `/roster/scheduler/:facilityId/${ESchedulerRosterPages.schedulerPermissions}`,
        name: "Add scheduler permissions",
        children: [
          {
            id: "17.2.1",
            path: `/roster/scheduler/:facilityId/${ESchedulerRosterPages.schedulerPermissions}/:schedulerId`,
            name: "Edit scheduler permissions",
          },
        ],
      },
      {
        id: "17.3",
        path: `/roster/scheduler/:facilityId/${ESchedulerRosterTabs.activeSchedulers}`,
        name: "Active schedulers",
      },
      {
        id: "17.4",
        path: `/roster/scheduler/:facilityId/${ESchedulerRosterTabs.suspendedSchedulers}`,
        name: "Suspended schedulers",
      },
    ],
  },
  schedule: {
    id: "7",
    path: "/schedule",
    name: "Schedule",
    component: <SchedulerGridPage />,
    roles: [User.ERole.admin, User.ERole.scheduler, User.ERole.kiosk],
    permissions: [EUnitPermissionAreas.scheduleGrid, KIOSK_PERMISSIONS],
    children: [
      {
        id: "7.1",
        path: `/schedule/${ESchedulePageTabs.past}`,
        name: "Past schedules",
        children: [
          {
            id: "7.1.1",
            path: `/schedule/${ESchedulePageTabs.past}/:scheduleId`,
            name: "Past schedule details",
          },
        ],
        roles: [User.ERole.admin, User.ERole.scheduler],
      },
      {
        id: "7.2",
        path: `/schedule/${ESchedulePageTabs.current}`,
        name: "Current schedule",
        roles: [User.ERole.admin, User.ERole.scheduler],
      },
      {
        id: "7.3",
        path: `/schedule/${ESchedulePageTabs.planning}`,
        name: "Planned schedules",
        children: [
          {
            id: "7.3.1",
            path: `/schedule/${ESchedulePageTabs.planning}/:scheduleId`,
            name: "Planned schedule details",
          },
        ],
        roles: [User.ERole.admin, User.ERole.scheduler, User.ERole.kiosk],
      },
    ],
  },
  holidaySchedule: {
    id: "8",
    path: "/holiday-schedule",
    name: "Holiday Schedule",
    component: <HolidaySchedulePage />,
    roles: [User.ERole.admin, User.ERole.scheduler, User.ERole.kiosk],
    permissions: [EUnitPermissionAreas.scheduleGrid, KIOSK_PERMISSIONS],
  },

  statistics: {
    id: "9",
    path: "/statistics",
    name: "Statistics",
    component: <StatisticsPage />,
    roles: [User.ERole.staff],
    allowedEmploymentType: [
      StaffDetails.EEmploymentType.fullTime,
      StaffDetails.EEmploymentType.partTime,
      StaffDetails.EEmploymentType.travelStaff,
      StaffDetails.EEmploymentType.inHouseContract,
      StaffDetails.EEmploymentType.flex,
      StaffDetails.EEmploymentType.contract,
    ],
    permissions: [STAFF_PERMISSIONS],
  },
  support: {
    id: "10",
    path: "/support",
    name: "Support",
    component: <SupportPage />,
    roles: [User.ERole.staff],
    permissions: [STAFF_PERMISSIONS],
  },
  openShifts: {
    id: "11",
    path: "/open-shifts",
    name: "Open Shifts",
    component: <OpenShiftsPage />,
    roles: [User.ERole.admin, User.ERole.scheduler],
    permissions: [EUnitPermissionAreas.openShifts],
  },
  dailyReports: {
    id: "12",
    path: "/daily-reports",
    name: "Daily Reports",
    component: <DailyReportsPage />,
    roles: [User.ERole.admin, User.ERole.scheduler],
    permissions: [EUnitPermissionAreas.dailyReport],
  },
  shiftSwapRequest: {
    id: "13",
    path: "/shift-swap-request",
    name: "Shift Swap Request",
    component: <ShiftSwapRequestPage />,
    roles: [User.ERole.staff, User.ERole.admin, User.ERole.scheduler],
    permissions: [EUnitPermissionAreas.approvals, STAFF_PERMISSIONS],
  },
  houseView: {
    id: "14",
    path: "/house-view",
    name: "House View",
    component: <HouseViewPage />,
    roles: [User.ERole.admin, User.ERole.scheduler],
    permissions: [EUnitPermissionAreas.houseView],
  },
  adminPanelCxDashboard: {
    id: "18",
    path: "/admin/cx-dashboard",
    name: "CX Dashboard",
    roles: [User.ERole.admin],
    component: <CxDashboardPage />,
    permissions: [ADMIN_PERMISSIONS],
  },
  adminPanelUnit: {
    id: "15",
    path: "/admin/unit",
    name: "Settings",
    roles: [User.ERole.admin, User.ERole.scheduler],
    component: <AdminPanelPage />,
    permissions: [ADMIN_PERMISSIONS, EUnitPermissionAreas.unitSettings],
    children: values(ADMIN_SUB_ROUTES),
  },
  shiftReports: {
    id: "19",
    path: "/shift-reports",
    name: "Shift Reports",
    component: <ShiftReportsPage />,
    roles: [User.ERole.admin, User.ERole.scheduler],
    permissions: [EUnitPermissionAreas.shiftReports],
  },
  // Shouldn't we have this? I reintroduced it by error, but was deleted in a previous PR.
  // Yet it feels like it should be here
  // defaultStaff: {
  //   id: "90",
  //   path: FALLBACK_ROUTE,
  //   name: "Calendar",
  //   component: <CalendarPage />,
  //   roles: [User.ERole.staff],
  //   permissions: [STAFF_PERMISSIONS],
  // },
  // defaultSchedulerAdmin: {
  //   id: "91",
  //   path: FALLBACK_ROUTE,
  //   name: "Roster",
  //   component: <SchedulePage />,
  //   roles: [User.ERole.admin, User.ERole.scheduler],
  //   permissions: [ADMIN_PERMISSIONS, EUnitPermissionAreas.scheduleGrid],
  // },
  // defaultKiosk: {
  //   id: "92",
  //   path: FALLBACK_ROUTE,
  //   name: "Schedule",
  //   component: <SchedulePage />,
  //   roles: [User.ERole.kiosk],
  //   permissions: [KIOSK_PERMISSIONS],
  // },
  // defaultRoleRoute has a fallthrough route for each role
  // below is last resort route for all roles
  fallthrough: {
    id: "99",
    path: "*",
    name: "Fallthrough",
    component: <CatchAllPage />,
    roles: values(User.ERole),
    permissions: [PERMISSION_ALLOW_ALL],
  },
} satisfies { [routeKey: string]: IMainRoute };

const router = createBrowserRouter([
  {
    path: "",
    element: <RouterRoot />,
    errorElement: <ErrorPage />,
    children: Object.values(routes).map(
      ({
        component,
        path,
        roles,
        name,
        children,
        permissions,
        allowedEmploymentType,
      }: IMainRoute) => ({
        path,
        element: (
          <MixpanelProvider properties={{ [Mxp.Property.layout.page]: name }}>
            <ProtectedRoute
              roles={roles}
              permissions={permissions}
              allowedEmploymentType={allowedEmploymentType}
            >
              {component}
            </ProtectedRoute>
          </MixpanelProvider>
        ),
        children: children,
      }),
    ),
  },
]);

export default router;
