import { keyBy, map } from "lodash";

import { useAppConfigQuery } from "#/features/User/queries";
import { IStaffCategory, IStaffType, IUnit } from "@/api";

import { useDeepMemo } from "./useDeepMemo";
import { useKeyBy, useMapBy } from "./utils";

const emptyStaffType = [] as IStaffType[];
const emptyStaffCategory = [] as IStaffCategory[];

/**
 *
 * @param unitId
 * @returns
 */
export const useUnitStaffCategories = (unitId?: IUnit["id"]) => {
  const keysFromConfig = useAppConfigQuery().data?.units?.find(
    ({ id }) => id === unitId,
  )?.staffCategoryKeys;
  const staffCategories = useStaffCategories();

  return useDeepMemo(() => {
    const filteredCategories = staffCategories.filter(
      (category) => keysFromConfig && keysFromConfig.includes(category.key),
    );
    return {
      values: filteredCategories,
      byKey: keyBy(filteredCategories, "key"),
      keys: map(filteredCategories, "key"),
    };
  }, [staffCategories, keysFromConfig]);
};

export const useUnitStaffTypes = (unitId?: IUnit["id"]) => {
  const staffCategories = useUnitStaffCategories(unitId);
  const allStaffTypes = useStaffTypes();

  return useDeepMemo(() => {
    const staffTypes = allStaffTypes.filter((staffType) =>
      staffCategories.keys.includes(staffType.staffCategoryKey),
    );

    return {
      values: staffTypes,
      byKey: keyBy(staffTypes, "key"),
      keys: map(staffTypes, "key"),
    };
  }, [staffCategories, allStaffTypes]);
};
export const useStaffTypes = () => {
  const staffTypes = useAppConfigQuery().data?.staffTypes || emptyStaffType;
  return staffTypes;
};

export const useStaffCategories = () => {
  const staffCategories = useAppConfigQuery().data?.staffCategories || emptyStaffCategory;
  return staffCategories;
};

export const useStaffTypeKeys = () => {
  const staffTypes = useStaffTypes();
  return useMapBy(staffTypes, "key");
};

export const useStaffCategoryKeys = () => {
  const staffCategories = useStaffCategories();
  return useMapBy(staffCategories, "key");
};

export const useStaffTypeByKey = () => {
  const staffTypes = useStaffTypes();
  return useKeyBy(staffTypes, "key");
};

export const useStaffCategoryByKey = () => {
  const staffCategories = useStaffCategories();
  return useKeyBy(staffCategories, "key");
};
