// eslint-disable-next-line deprecate/import
import { Radio as MuiRadio, RadioProps } from "@mui/material";

import { useAppTracking } from "~/modules/mixpanel/Context";
import { Mxp } from "~/modules/mixpanel/types";

export const Radio = ({
  trackingLabel,
  ...props
}: {
  trackingLabel: string;
} & RadioProps) => {
  const track = useAppTracking();

  // For now we only track on click, no property change
  const onClickWrapper: (typeof props)["onClick"] = (...args) => {
    track(Mxp.Event.elementClicked, {
      [Mxp.Property.element.type]: Mxp.ElementsTypes.radio,
      [Mxp.Property.element.label]: trackingLabel,
    });
    props.onClick?.(...args);
  };

  return <MuiRadio {...props} onClick={onClickWrapper} />;
};
