import { get, map } from "lodash";

import { TextField, Typography } from "@mui/material";

import { IStaffType } from "@/api";
import { Autocomplete } from "@/common/components";

import { TDataComponent } from "./types";

export const StaffTypeNameField: TDataComponent<IStaffType["name"]> = {
  editor: ({ value, updateCell, staffTypes, onBlur, focused }) => {
    return (
      <Autocomplete<IStaffType["name"]>
        autoFocus={focused}
        open={focused || undefined}
        options={map(staffTypes, "name")}
        value={value}
        clearIcon={null}
        slotProps={{
          popper: {
            onClick: (event) => {
              updateCell(get(event.target, "textContent") as unknown as IStaffType["name"]);
              onBlur();
            },
          },
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            size="small"
            variant="standard"
            onBlur={onBlur}
            autoFocus={focused}
          />
        )}
        trackingLabel={null}
      />
    );
  },
  readonly: ({ value }) => <Typography>{value || "N/A"}</Typography>,
};
