import { useEffect, useState } from "react";

import { useAppConfigQuery } from "~/features/User/queries";

import { httpClient } from "../packages/httpClient";

/**
 * Hook that returns the current facility object based on the facility ID from HTTP HEADERS.
 * The facility ID is extracted from the HTTP headers and used to find the matching facility
 * in the app configuration.
 * @returns The current facility object or undefined if not found
 */
export const useCurrentFacility = () => {
  const { data: config } = useAppConfigQuery();
  const facilityId = useCurrentFacilityId();

  return config?.facilities.find((facility) => facility.id === facilityId) || undefined;
};

/**
 * Hook that manages and returns the current facility ID from HTTP HEADERS.
 * Listens for changes to the facility ID in headers via the httpClient event emitter
 * and updates the state accordingly.
 * @returns The current facility ID from headers or null if not set
 */
export const useCurrentFacilityId = () => {
  const [facilityId, setFacilityId] = useState<string | null>(httpClient.getFacilityId());

  useEffect(() => {
    const handleFacilityIdChange = () => setFacilityId(httpClient.getFacilityId());
    httpClient.eventEmitter.on("facilityIdChanged", handleFacilityIdChange);
    handleFacilityIdChange();

    return () => {
      httpClient.eventEmitter.removeListener("facilityIdChanged", handleFacilityIdChange);
    };
  }, []);

  return facilityId;
};
