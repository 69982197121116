import { ERoleId, IUser } from "~/features/User/types";
import { IUnitBasic } from "~/routes/api/types";

import { BULK_DELETE_KEY } from "@/common/constants";

import { APIElementResponse, APIListResponse } from "../_shared/types";
import { User } from "../user";

export type IRoster = Roster.DTO;

export namespace Roster {
  export enum ENotificationType {
    email = "email",
    sms = "sms",
  }

  export enum ENotificationEntityOption {
    timeOffRequest = "TimeOffRequest",
    shiftSwapRequest = "ShiftSwapRequest",
    dropShiftRequest = "DropShiftRequest",
    openShiftRequest = "OpenShiftRequest",
  }
  export interface DTO {
    id: number;
    unitId: IUnitBasic["id"];
    userId: IUser["id"];
    roleId: ERoleId;
    notificationPreferences: ENotificationType[];
    entityPreferences: ENotificationEntityOption[];
  }

  export namespace API {
    export const ROOT_PATH = "/roster";
    export namespace List {
      export const PATH = ROOT_PATH;
      export interface QueryParams {
        userIds?: User.DTO["id"][];
        unitIds?: User.DTO["id"][];
        /** @param homeUsers - Filter by home unit
         *  - if true is provided, includes roster entries of users home unit
         *  - if false is provided, includes roster entries of users from other units
         *  - if not or both are provided, no filter is applied
         */
        homeUsers?: boolean[];
      }

      export type Response = APIListResponse<DTO>;
    }

    export namespace Update {
      export const PATH = (params: QueryParams) => `${ROOT_PATH}/${params.id}`;
      export type QueryParams = Pick<DTO, "id" | "notificationPreferences" | "entityPreferences">;

      export type Response = APIElementResponse<DTO>;
    }

    export namespace BulkSaveUnitEligibility {
      export const PATH = ROOT_PATH + "/bulk-save";
      export interface QueryParams {
        rosters: TBulkSaveRosters;
      }
      export type Response = APIListResponse<DTO>;
    }
  }
}

type TRosterToCreate = Pick<Roster.DTO, "unitId" | "userId">;
type TRosterToDelete = Pick<Roster.DTO, "unitId" | "userId"> & {
  [BULK_DELETE_KEY]: boolean;
};

export type TBulkSaveRosters = (TRosterToCreate | TRosterToDelete)[];
