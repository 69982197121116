import { useLocation } from "react-router-dom";

const facilityBasedViews = [
  "/house-view",
  "/shift-reports",
  "/admin/unit/facility-configuration",
  "/roster/scheduler/",
];
export const useIsFacilityBasedView = () => {
  const location = useLocation();

  return isFacilityBasedView(location.pathname);
};

export const isFacilityBasedView = (pathname: string) => {
  return facilityBasedViews.some((view) => pathname.startsWith(view));
};
