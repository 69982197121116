import { useCallback, useEffect, useMemo, useState } from "react";

import { Uuid } from "@m7-health/shared-utils";
import { filter, flatten, intersection, orderBy, values } from "lodash";

import { Grid, List, ListItem, Typography } from "@mui/material";

import {
  IPreferencesTemplate,
  IStaffDetails,
  useApplyPreferencesTemplateMutation,
  useInvalidateQuery,
  useListPreferenceRequestsQuery,
  useListPreferencesTemplatesQuery,
  useListStaffDetailsQuery,
} from "@/api";
import {
  AutocompleteStaffSelector,
  CustomModal,
  PreferencesTemplateLabel,
} from "@/common/components";
import { NOT_EXISTING_UUID } from "@/common/constants";
import {
  useAppDispatch,
  useAppSelector,
  useCurrentUnitId,
  useErrors,
  useToast,
} from "@/common/hooks";

import { closeModal, openModal } from "../../store";

export const ApplyPreferencesTemplateModal = () => {
  const currentUnitId = useCurrentUnitId();
  const currentScheduleId = useAppSelector((state) => state.schedulerGrid.schedule?.id);
  const dispatch = useAppDispatch();
  const { handleErrors } = useErrors();
  const { showSuccess } = useToast();
  const invalidateQuery = useInvalidateQuery();

  // Queries
  const { data: preferencesTemplates } = useListPreferencesTemplatesQuery(
    { unitIds: [currentUnitId || NOT_EXISTING_UUID] },
    { skip: !currentUnitId },
  );
  const orderedPreferencesTemplates = useMemo(
    () =>
      orderBy(
        filter(preferencesTemplates, ({ userIds }) => userIds.length > 0),
        ["createdAt", "label"],
      ),
    [preferencesTemplates],
  );
  const { data: staffDetails } = useListStaffDetailsQuery(
    { homeUnitIds: [currentUnitId || NOT_EXISTING_UUID], active: [true] },
    { skip: !currentUnitId },
  );

  const { mutateAsync: applyPreferencesTemplates, isPending: isApplyingPreferencesTemplates } =
    useApplyPreferencesTemplateMutation({});

  // State
  const [selectedStaffIdsByTemplate, setSelectedStaffIdsByTemplate] = useState<
    Record<IPreferencesTemplate["id"], string[]>
  >({});

  useEffect(() => {
    const availableUserIds = staffDetails?.map(({ userId }) => userId) || [];
    setSelectedStaffIdsByTemplate(
      orderedPreferencesTemplates.reduce(
        (acc, preferencesTemplate) => {
          acc[preferencesTemplate.id] = intersection(preferencesTemplate.userIds, availableUserIds);
          return acc;
        },
        {} as Record<IPreferencesTemplate["id"], string[]>,
      ),
    );
  }, [orderedPreferencesTemplates, staffDetails]);

  const autocompleteStaffOptions = useMemo(() => {
    return orderedPreferencesTemplates.reduce(
      (acc, preferencesTemplate) => {
        acc[preferencesTemplate.id] =
          staffDetails?.filter((staff) =>
            preferencesTemplate.userIds.includes(staff.userId as Uuid),
          ) || [];
        return acc;
      },
      {} as Record<IPreferencesTemplate["id"], IStaffDetails[]>,
    );
  }, [staffDetails, orderedPreferencesTemplates]);

  // Actions
  const closeModalAction = useCallback(() => {
    dispatch(closeModal("applyPreferencesTemplatesModal"));
    dispatch(openModal("updatePreferencesTemplatesModal"));
  }, [dispatch]);
  const applyPreferencesTemplatesAction = useCallback(async () => {
    if (!currentScheduleId) return;

    try {
      const selectedStaffIds = flatten(values(selectedStaffIdsByTemplate));
      await applyPreferencesTemplates({
        scheduleId: currentScheduleId,
        staffIds: selectedStaffIds,
      });
      showSuccess("Preference templates applied successfully");
      dispatch(closeModal("applyPreferencesTemplatesModal"));
      invalidateQuery(useListPreferenceRequestsQuery);
    } catch (error) {
      handleErrors(error);
    }
  }, [
    applyPreferencesTemplates,
    currentScheduleId,
    dispatch,
    handleErrors,
    invalidateQuery,
    selectedStaffIdsByTemplate,
    showSuccess,
  ]);

  const modalContent = useMemo(() => {
    return (
      <>
        <Grid container sx={{ overflow: "auto", maxHeight: "calc(100vh - 220px)" }}>
          <Typography children="Apply templates to current schedule" />

          <List>
            {orderedPreferencesTemplates.map((preferencesTemplate) => (
              <ListItem key={preferencesTemplate.id}>
                <PreferencesTemplateLabel
                  preferencesTemplate={preferencesTemplate}
                  sx={{ width: "80px" }}
                />

                <AutocompleteStaffSelector
                  label="Staff"
                  trackingLabel={null}
                  sx={{ width: "100%" }}
                  staffOptions={autocompleteStaffOptions[preferencesTemplate.id] || []}
                  selectedStaffIds={selectedStaffIdsByTemplate[preferencesTemplate.id] || []}
                  setSelectedStaffIds={(staffIds) => {
                    setSelectedStaffIdsByTemplate((prev) => ({
                      ...prev,
                      [preferencesTemplate.id]: staffIds,
                    }));
                  }}
                />
              </ListItem>
            ))}
          </List>
        </Grid>
      </>
    );
  }, [autocompleteStaffOptions, orderedPreferencesTemplates, selectedStaffIdsByTemplate]);

  return (
    <CustomModal
      isOpen={true}
      customWidth="80vw"
      modalHeaderText="Apply Preference Templates"
      primaryBtnText="Apply"
      secondaryBtnText="Close"
      isLoading={isApplyingPreferencesTemplates}
      modalContent={modalContent}
      onClose={closeModalAction}
      onSecondaryBtnClick={closeModalAction}
      onSubmit={() => void applyPreferencesTemplatesAction()}
    />
  );
};
