import { useState } from "react";

import { ECommunicationMethod } from "@m7-health/shared-utils";
import { useMutation, useQueryClient } from "@tanstack/react-query";

import { Email, Sms, SpeakerNotesOff } from "@mui/icons-material";
import { Grid, Stack, ToggleButtonGroup, Typography } from "@mui/material";

import CustomDropzone from "~/common/components/CustomDropzone";
import CustomModal from "~/common/components/Modal";
import SelectedFile from "~/common/components/SelectedFile";
import { ESelectedFileVariant } from "~/common/components/SelectedFile/types";
import { useAppDispatch, useAppSelector } from "~/common/hooks/useRedux";
import { useToast } from "~/common/hooks/useToast";

import { ToggleButton } from "@/common/components";
import { useAppFlags } from "@/common/hooks";
import { darkPurple } from "@/common/theming";

import { postBulkUserUpload } from "../../api";
import { setIsBulkUsersUploadModalOpen } from "../../store";

const VALIDATION_ISSUES_ERROR_CODE = "validation_issues";

export const BulkUserUploadModal = () => {
  const dispatch = useAppDispatch();
  const queryClient = useQueryClient();
  const { showSuccess, showError } = useToast();
  const { newSmsOnboarding } = useAppFlags();

  const [uploadedFiles, setUploadedFiles] = useState<File[]>([]);
  const [communicationMethod, setCommunicationMethod] = useState<ECommunicationMethod | "">("");

  const bulkUsersUploadModalState = useAppSelector(
    (state) => state.roster.bulkUsersUploadModal.isOpen,
  );
  const selectedUnit = useAppSelector((state) => state.common.currentUnit);

  // Custom error handler for bulk user upload
  const onError = ({ error, statusCode, message }: any) => {
    if (error === VALIDATION_ISSUES_ERROR_CODE && statusCode === 400 && Array.isArray(message)) {
      message.forEach(({ error: msg }: { error: string }) => {
        showError(msg);
      });
      return;
    }

    if (typeof message === "string") {
      showError(message);
      return;
    }

    showError(
      "An unexpected error occurred when uploading the file. Check your email or contact the administrator for more information.",
    );
  };

  const onSettled = () => {
    void queryClient.invalidateQueries({ queryKey: ["roster"] });
  };

  const { mutate: bulkUserUpload, isPending: isBulkUserUploadPending } = useMutation({
    mutationFn: postBulkUserUpload,
    onSuccess: () => {
      showSuccess(
        "File is being processed. The email with more information will be sent to your account after the process is finished.",
      );

      setUploadedFiles([]);

      dispatch(setIsBulkUsersUploadModalOpen(false));
    },
    onError,
    onSettled,
  });

  const handleSecondaryBtnClick = () => {
    dispatch(setIsBulkUsersUploadModalOpen(false));
  };

  const submitBulkUserUploadFile = () => {
    if (uploadedFiles[0])
      bulkUserUpload({
        unitId: selectedUnit?.id || "",
        file: uploadedFiles[0],
        sendResetPasswordMethod: communicationMethod || undefined,
      });
  };

  const modalContent = (
    <Grid container flexDirection="column">
      <Grid item mb={2}>
        <Typography fontSize="0.875rem">
          Download upload template, fill it accordingly to the file structure and upload it below.
        </Typography>
      </Grid>
      <Grid item mb={2}>
        <Stack direction="row" gap={1}>
          <SelectedFile
            path="/Bulk_template.csv"
            fileName="Bulk_template.csv"
            variant={ESelectedFileVariant.Highlighted}
          />
          <SelectedFile path="/Filled_example.csv" fileName="Filled_example.csv" />
        </Stack>
      </Grid>
      <Grid item mb={2}>
        <CustomDropzone uploadedFiles={uploadedFiles} setUploadedFiles={setUploadedFiles} />
      </Grid>
      {newSmsOnboarding && (
        <Stack
          direction="column"
          gap={1}
          sx={{ position: "absolute", right: "32px", bottom: "32px" }}
        >
          <Typography fontSize="0.875rem" fontStyle="italic" color={darkPurple}>
            "Reset Password" method
          </Typography>
          <ToggleButtonGroup
            value={communicationMethod}
            exclusive
            onChange={(_, value: ECommunicationMethod) => setCommunicationMethod(value)}
            color={"darkPurple"}
            sx={{ svg: { pl: 1 } }}
          >
            <ToggleButton value={""} aria-label="none" trackingLabel="none">
              None <SpeakerNotesOff />
            </ToggleButton>
            <ToggleButton
              value={ECommunicationMethod.email}
              aria-label="email"
              trackingLabel="email"
            >
              Email <Email />
            </ToggleButton>
            <ToggleButton value={ECommunicationMethod.sms} aria-label="sms" trackingLabel="sms">
              SMS <Sms />
            </ToggleButton>
          </ToggleButtonGroup>
        </Stack>
      )}
    </Grid>
  );

  return (
    <CustomModal
      isOpen={bulkUsersUploadModalState}
      primaryBtnText="Bulk create"
      modalContent={modalContent}
      modalHeaderText={"Bulk users upload"}
      onSecondaryBtnClick={handleSecondaryBtnClick}
      onSubmit={submitBulkUserUploadFile}
      primaryDisabled={uploadedFiles.length === 0 || isBulkUserUploadPending}
    />
  );
};
