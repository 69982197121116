import { Controller } from "react-hook-form";

import { Timezone } from "@m7-health/shared-utils";
import { get } from "lodash";
import upperFirst from "lodash/upperFirst";

import { FormControl, FormHelperText } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

import { Dayjs } from "~/common/packages/dayjs";
import { redAlert } from "~/common/theming/colors";

import { ICustomDatePickerControlledProps, ICustomDatePickerProps } from "./types";

const onChangeWrapper = (
  date: Dayjs | undefined | null,
  onChange: (date: Dayjs | null | undefined) => void,
  timezone?: Timezone,
) => {
  if (!date?.isValid()) return;

  // When manual input from null value, the localizer don't properly set timezone
  //  so we check and set it if needed
  if (timezone && !get(date, "$x.$timezone")) date = date?.tz(timezone);
  onChange(date?.startOf("day") || null);
};

export const CustomDatePickerControlled = ({
  control,
  disabled,
  name,
  ...rest
}: ICustomDatePickerControlledProps<Dayjs | undefined | null>) => {
  return (
    <Controller
      name={name || ""}
      control={control}
      render={({ field: { onChange, value } }) => (
        <CustomDatePicker
          {...rest}
          disabled={disabled}
          onChange={(date) => onChangeWrapper(date, onChange, rest.timezone)}
          value={value}
          name={name}
        />
      )}
    />
  );
};

export const CustomDatePicker = ({
  disabled,
  onChange,
  value,
  errors,
  name,
  label,
  slotProps,
  ...rest
}: ICustomDatePickerProps<Dayjs | null | undefined>) => {
  const errorMessage = name ? upperFirst((errors?.[name]?.message as string) ?? "") : null;

  return (
    <FormControl>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DatePicker
          disabled={disabled}
          value={value}
          onChange={(date) => onChangeWrapper(date, onChange, rest.timezone)}
          label={label}
          slotProps={{
            ...slotProps,
            textField: {
              error: !!errorMessage,
              size: "small",
              ...(slotProps?.textField || {}),
            },
          }}
          {...rest}
        />
      </LocalizationProvider>
      {errorMessage && <FormHelperText sx={{ color: redAlert }}>{errorMessage}</FormHelperText>}
    </FormControl>
  );
};
