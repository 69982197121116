import { useState, useMemo } from "react";

import { isEqual, map } from "lodash";

import { Box } from "@mui/material";

import CustomModal from "~/common/components/Modal";
import { CustomSelect } from "~/common/components/TrackedComponents";
import { useAppDispatch, useAppSelector } from "~/common/hooks/useRedux";

import { useAppConfigQuery } from "#/features/User/queries";

import { rosterUserEmploymentStatus } from "../../constants";
import { setStaffRosterFilters, setStaffRosterFiltersModalOpen } from "../../store";
import { IRosterState } from "../../types";

export const StaffRosterFiltersModal = () => {
  const dispatch = useAppDispatch();
  const { data: config } = useAppConfigQuery();
  const { isOpen, filters } = useAppSelector(
    (state) => ({
      isOpen: state.roster.staffRosterFiltersModal.isOpen,
      filters: state.roster.staffRosterfilters,
    }),
    isEqual,
  );

  const [selectedFilters, setSelectedFilters] =
    useState<IRosterState["staffRosterfilters"]>(filters);

  const staffTypeItems = useMemo(
    () =>
      map(config?.staffTypes, (staffType) => ({
        label: staffType.name + (staffType.description ? ` (${staffType.description})` : ""),
        value: staffType.key,
        item: staffType.key,
      })),
    [config?.staffTypes],
  );

  const confirm = () => {
    dispatch(setStaffRosterFilters(selectedFilters));
    dispatch(setStaffRosterFiltersModalOpen(false));
  };

  const handleSecondaryBtnClick = () => {
    dispatch(setStaffRosterFiltersModalOpen(false));
  };

  return (
    <CustomModal
      isOpen={isOpen}
      withBlurBackground={false}
      sx={{ overflowY: "auto" }}
      primaryBtnText="Apply"
      modalHeaderText={"Staff Roster Filters"}
      onSecondaryBtnClick={handleSecondaryBtnClick}
      onSubmit={confirm}
      modalContent={
        <Box mt={3}>
          <Box maxWidth={"500px"}>
            <CustomSelect<string>
              multiple
              checked
              items={staffTypeItems}
              label="Staff Types"
              value={selectedFilters.staffTypes}
              onChange={(event) => {
                const values = Array.isArray(event.target.value)
                  ? event.target.value
                  : [event.target.value];
                setSelectedFilters((currentFilters) => ({ ...currentFilters, staffTypes: values }));
              }}
              sx={{ mb: 3 }}
            />

            <CustomSelect
              multiple
              checked
              items={rosterUserEmploymentStatus}
              label="Employment Type"
              value={selectedFilters.employmentTypes}
              onChange={(event) => {
                const values = Array.isArray(event.target.value)
                  ? event.target.value
                  : [event.target.value];
                setSelectedFilters((currentFilters) => ({
                  ...currentFilters,
                  employmentTypes: values,
                }));
              }}
              sx={{ mb: 3 }}
            />
          </Box>
        </Box>
      }
    />
  );
};
