import { useCallback, useEffect, useMemo, useState } from "react";

import { ECommunicationMethod } from "@m7-health/shared-utils";
import { isEqual, map } from "lodash";

import { Email, Sms } from "@mui/icons-material";
import { Box, Stack, ToggleButtonGroup, Typography } from "@mui/material";

import { IUser } from "~/api/user/types";

import { useSendResetPassword } from "@/api";
import {
  AutocompleteStaffSelector,
  CustomModal,
  CustomSwitch,
  ToggleButton,
} from "@/common/components";
import { useFilterBy, useKeyBy, useToast } from "@/common/hooks";
import { useAppDispatch, useAppSelector } from "@/common/hooks/useRedux";

import { closeModal, setUsersToSendResetPassword } from "../../store";

import { useGetUsers } from "./useGetUsers";

export const SendResetPasswordModal = () => {
  const { showError, showSuccess } = useToast();
  const dispatch = useAppDispatch();

  // Store data
  const storeUserIds = useAppSelector(
    (state) => state.roster.sendResetPasswordModal.userIds,
    isEqual,
  );

  // Queries
  const users = useGetUsers();
  const { mutateAsync: sendResetPassword } = useSendResetPassword({});

  // State
  const [isLoading, setIsLoading] = useState(false);
  const [userIds, setUserIds] = useState<IUser["id"][]>([]);
  const [communicationMethod, setCommunicationMethod] = useState<ECommunicationMethod>(
    ECommunicationMethod.email,
  );
  const [sendResetToNotLoggedUsers, setSendResetToNotLoggedUsers] = useState(false);

  // Computed values
  const singleUserMode = storeUserIds?.length === 1;
  const usersById = useKeyBy(users, "id");
  const notLoggedInUsers = useFilterBy(users, (user) => !user.lastLoggedInAt, []);

  useEffect(() => {
    setUserIds(storeUserIds ?? []);
  }, [storeUserIds]);

  const closeAction = useCallback(() => {
    dispatch(closeModal("sendResetPasswordModal"));
    dispatch(setUsersToSendResetPassword([]));
  }, [dispatch]);

  const resetPasswordAction = useCallback(() => {
    void (async () => {
      setIsLoading(true);
      const userIdsToSendTo = sendResetToNotLoggedUsers ? map(notLoggedInUsers, "id") : userIds;

      try {
        await sendResetPassword({
          userIds: userIdsToSendTo,
          preferredCommunicationMethod: communicationMethod,
        });
        showSuccess("Reset password sent successfully");
        closeAction();
      } catch (error) {
        showError("Failed to send reset password");
      }

      setIsLoading(false);
    })();
  }, [
    sendResetToNotLoggedUsers,
    notLoggedInUsers,
    userIds,
    sendResetPassword,
    communicationMethod,
    showSuccess,
    closeAction,
    showError,
  ]);

  const content = useMemo(() => {
    if (singleUserMode) {
      const user = usersById[userIds[0]!];
      return (
        <Typography>
          Trigger reset password flow for{" "}
          <b>
            {user?.firstName || ""} {user?.lastName || ""}
          </b>
          .<br />
          The user will receive an <b>{communicationMethod}</b> with a link to reset their password.
        </Typography>
      );
    } else {
      const userCount = sendResetToNotLoggedUsers ? notLoggedInUsers.length : userIds.length;
      return (
        <Typography>
          Send reset password <b>{communicationMethod}</b> to {userCount} users
        </Typography>
      );
    }
  }, [
    singleUserMode,
    userIds,
    usersById,
    communicationMethod,
    notLoggedInUsers,
    sendResetToNotLoggedUsers,
  ]);

  const communicationMethodToggle = (
    <ToggleButtonGroup
      value={communicationMethod}
      exclusive
      onChange={(_, value: ECommunicationMethod) => setCommunicationMethod(value)}
      aria-label="text alignment"
      color={"darkPurple"}
      sx={{ position: "absolute", right: "32px", bottom: "32px" }}
    >
      <ToggleButton value={ECommunicationMethod.email} aria-label="email" trackingLabel="email">
        Email <Email sx={{ pl: 1 }} />
      </ToggleButton>
      <ToggleButton value={ECommunicationMethod.sms} aria-label="sms" trackingLabel="sms">
        Text <Sms sx={{ pl: 1 }} />
      </ToggleButton>
    </ToggleButtonGroup>
  );

  const userPicker = singleUserMode ? null : (
    <Stack direction="column" width="100%">
      <AutocompleteStaffSelector
        limitTags={15}
        trackingLabel={null}
        label="Send reset password to"
        staffOptions={users || []}
        selectedStaffIds={userIds}
        setSelectedStaffIds={setUserIds}
        sx={{ my: 2 }}
        disabled={sendResetToNotLoggedUsers}
      />
      <CustomSwitch
        trackingLabel={"send-reset-password-to-all-not-logged-in-users"}
        switchVariant="checkbox"
        label={
          <>
            Send reset password to <b>all {notLoggedInUsers.length} users</b> that have not logged
            in yet.
          </>
        }
        name="send-reset-password-to-all-users-that-have-not-logged-in-yet"
        checked={sendResetToNotLoggedUsers}
        onChange={(checked) => setSendResetToNotLoggedUsers(checked)}
      />
    </Stack>
  );

  return (
    <CustomModal
      isOpen={true}
      onClose={closeAction}
      modalHeaderText="Reset Password"
      primaryBtnText="Send"
      onSubmit={resetPasswordAction}
      secondaryBtnText="Cancel"
      onSecondaryBtnClick={closeAction}
      isLoading={isLoading}
      primaryDisabled={userIds.length === 0 && !sendResetToNotLoggedUsers}
      trackingLabel="send-reset-password"
    >
      <Box>
        <Typography children={content} />
        {userPicker}
        {communicationMethodToggle}
      </Box>
    </CustomModal>
  );
};
