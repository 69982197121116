import { ECommunicationMethod, KeyBy, m7DayJs, Timezone } from "@m7-health/shared-utils";
import { PayloadAction } from "@reduxjs/toolkit";

import { IShiftType, IStaffType } from "@/api";

import { IRosterState } from "../types";

export interface IBulkCreateUsersState {
  uploadedFile: File | null;
  activeStep: number;
  dataIsValid: boolean;
  highlightedRow: number | null;
  unitData: TBulkCreateUsersUnitData;
  saveUserSignal: boolean;
  sendResetPasswordMethod: ECommunicationMethod | null;
}

export type TBulkCreateUsersUnitData = {
  shiftTypes: IShiftType[];
  staffTypes: IStaffType[];
  indexedShiftTypes: KeyBy<IShiftType, "key">;
  indexedStaffTypes: KeyBy<IStaffType, "key">;
  timezone: Timezone;
};

export const initialBulkCreateUsersState: IBulkCreateUsersState = {
  uploadedFile: null,
  activeStep: 0,
  dataIsValid: false,
  highlightedRow: null,
  unitData: {
    shiftTypes: [],
    staffTypes: [],
    indexedShiftTypes: {},
    indexedStaffTypes: {},
    // eslint-disable-next-line no-restricted-properties
    timezone: m7DayJs.tz.guess() as Timezone,
  },
  saveUserSignal: false,
  sendResetPasswordMethod: null,
};

export const bulkCreateUsersActions = {
  setUploadedFile: (state: IRosterState, action: PayloadAction<File | null>) => {
    state.bulkCreateUsers.uploadedFile = action.payload;
  },

  setActiveStep: (state: IRosterState, action: PayloadAction<number>) => {
    state.bulkCreateUsers.activeStep = action.payload;
  },

  setDataIsValid: (state: IRosterState, action: PayloadAction<boolean>) => {
    state.bulkCreateUsers.dataIsValid = action.payload;
  },

  setHighlightedRow: (state: IRosterState, action: PayloadAction<number | null>) => {
    state.bulkCreateUsers.highlightedRow = action.payload;
  },

  setUnitData: (state: IRosterState, action: PayloadAction<Partial<TBulkCreateUsersUnitData>>) => {
    state.bulkCreateUsers.unitData = {
      ...state.bulkCreateUsers.unitData,
      ...action.payload,
    };
  },

  setSaveUserSignal: (state: IRosterState, action: PayloadAction<boolean>) => {
    state.bulkCreateUsers.saveUserSignal = action.payload;
  },

  setSendResetPasswordMethod: (
    state: IRosterState,
    action: PayloadAction<ECommunicationMethod | null>,
  ) => {
    state.bulkCreateUsers.sendResetPasswordMethod = action.payload;
  },
};
