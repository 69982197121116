import { buildApiMutationHook } from "../_shared/helpers";

import { User } from "./types";

export const useSendResetPassword = buildApiMutationHook<
  User.API.SendResetPassword.QueryParams,
  User.API.SendResetPassword.Response
>(User.API.SendResetPassword.PATH, "post");

export const useBulkUploadUsers = buildApiMutationHook<
  User.API.BulkUploadUsers.QueryParams,
  User.API.BulkUploadUsers.Response
>(User.API.BulkUploadUsers.PATH, "post");
