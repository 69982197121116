import { Timezone } from "@m7-health/shared-utils";

import { IUnitConfig } from "#/features/User/types";
import { IShiftType, IStaffShift, IUnit } from "@/api";
import { Dayjs, localDayJs } from "@/common/packages/dayjs";
import { dateToTimeString, timeStringToDate } from "@/common/utils/dates";

export const ShiftEditorHelpers = {
  /**
   * Make sure that start/end are always max 24 hours apart
   * This is required when the "start" is after the "end" or vice versa
   *   when the shift is overlapping midnight
   */
  adjustToMax24Hours: ({
    value,
    timezone,
    timesBuffer,
    key,
  }: {
    value: Dayjs;
    timezone: Timezone;
    timesBuffer: {
      startTime: Dayjs | null;
      endTime: Dayjs | null;
    };
    key: "startTime" | "endTime";
  }) => {
    value = localDayJs.tz(value, timezone);
    if (key === "startTime" && timesBuffer.endTime) {
      // In case start time is on the previous day
      if (value.isSameOrAfter(timesBuffer.endTime)) value = value.addInTz(-1, "day");
      // In case end - start > 24 hours
      else if (value.isBefore(timesBuffer.endTime.addInTz(-1, "day")))
        value = value.addInTz(1, "day");
    } else if (key === "endTime" && timesBuffer.startTime) {
      // In case end time is on the next day
      if (value.isBefore(timesBuffer.startTime)) value.addInTz(1, "day");
      // In case end - start > 24 hours
      else if (value.isAfter(timesBuffer.startTime.addInTz(1, "day")))
        value = value.addInTz(-1, "day");
    }

    return value;
  },

  /** Check that the custom start/end are in the boundaries
   *  of the shift type's start and end time.
   */
  isShiftInsideBoundaries: ({
    shiftType,
    bufferStartTime,
    bufferEndTime,
    timezone,
  }: {
    shiftType: IShiftType;
    bufferStartTime: Dayjs;
    bufferEndTime: Dayjs;
    timezone: Timezone;
  }) => {
    let bufferStart = timeStringToDate(dateToTimeString(bufferStartTime), timezone);
    let bufferEnd = timeStringToDate(dateToTimeString(bufferEndTime), timezone);

    const shiftTypeStart = timeStringToDate(shiftType.startTime, timezone);
    // If shift start before midnight, but partial shifts starts after midnight, add a day
    //  to start and end
    if (bufferStart.isBefore(shiftTypeStart)) {
      bufferStart = bufferStart.addInTz(1, "day");
      bufferEnd = bufferEnd.addInTz(1, "day");
    }

    // if shifts overlap midnight, and start on previous day, add a day to the end
    if (bufferEnd.isSameOrBefore(bufferStart)) bufferEnd = bufferEnd.addInTz(1, "day");

    const shiftTypeEnd = timeStringToDate(shiftType.startTime, timezone).addInTz(
      shiftType.durationSeconds,
      "seconds",
    );

    return bufferStart.isSameOrAfter(shiftTypeStart) && bufferEnd.isSameOrBefore(shiftTypeEnd);
  },
};

/**
 * Given:
 *  - a current incentive Id,
 *  - from > to units
 *  - unit configs
 * Find the matching incentive Id for the to unit, first by key,
 *  then by name if no key match.
 *
 * returns null if no matching incentive is found
 */
export const getMatchingIncentive = ({
  fromIncentiveLevelId,
  fromUnitId,
  toUnitId,
  unitsConfigs,
}: {
  fromIncentiveLevelId: IStaffShift["incentiveLevelId"];
  fromUnitId: IUnit["id"];
  toUnitId: IUnit["id"];
  unitsConfigs: IUnitConfig[];
}) => {
  if (!fromIncentiveLevelId) return null;
  const fromUnitConfig = unitsConfigs.find((config) => config.id === fromUnitId);
  const toUnitConfig = unitsConfigs.find((config) => config.id === toUnitId);

  const fromIncentive = fromUnitConfig?.shiftIncentiveLevels.find(
    (incentive) => incentive.id === fromIncentiveLevelId,
  );
  if (!fromIncentive) return null;

  const fromIncentiveLabel = fromIncentive.label.toLowerCase();
  // Find by key
  let toIncentive = toUnitConfig?.shiftIncentiveLevels.find(
    (incentive) => incentive.key === fromIncentive?.key,
  );
  // Else, find by name
  toIncentive ||= toUnitConfig?.shiftIncentiveLevels.find(
    (incentive) => incentive.label.toLowerCase() === fromIncentiveLabel,
  );

  return toIncentive?.id || null;
};
