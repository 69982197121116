import { useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";

import { EUnitPermissionAreas, m7DayJs } from "@m7-health/shared-utils";
import { createColumnHelper } from "@tanstack/react-table";
import { capitalize, isBoolean, upperFirst } from "lodash";

import { AddBoxOutlined, Article, ContentCopy, LockReset } from "@mui/icons-material";
import { Box, Chip, Grid, IconButton, Popover, Stack, Tooltip, Typography } from "@mui/material";

import { CustomButton, CustomSwitch, PreferencesTemplateLabel } from "~/common/components";
import Avatar from "~/common/components/Avatar";
import MeatballsMenu from "~/common/components/MeatballsMenu";
import { useAppDispatch } from "~/common/hooks/useRedux";
import { getFullName } from "~/common/utils/user";
import { useStaffAttributesByKeyForUnit } from "~/routes/hooks/useStaffAttributesByKeyForUnit";

import { IPreferencesTemplate, IUser, useListPreferencesTemplatesQuery, User } from "@/api";
import { SendSingleSms } from "@/common/components/SendSingleSms/SendSingleSms";
import { NOT_EXISTING_UUID } from "@/common/constants";
import {
  useAppFlags,
  useCheckUserPermission,
  useCurrentRole,
  useCurrentTimezone,
  useCurrentUnitId,
  useToast,
} from "@/common/hooks";
import { black, darkGray, lightGray, nightShift, rosterIconsGrey } from "@/common/theming";
import { getTzFormattedDate, getTzUSFormattedDate } from "@/common/utils/dates";
import { formatPhoneNumber } from "@/common/utils/phoneNumber";

import {
  openModal,
  openUpdateStatusDateModal,
  openUpdateSuspensionDateModal,
  setEditUserModalId,
  setEditUserModalUserType,
  setIsEditUserModalOpen,
  setIsNoteModalOpen,
  setIsRestoredModalOpen,
  setIsStatusChangeModalOpen,
  setIsSuspendedModalOpen,
  setNoteModalData,
  setNoteModalVariant,
  setRestoredModalData,
  setStatusChangeModalData,
  setSuspendedModalData,
  setUsersToSendResetPassword,
} from "../../store";
import { EStaffRosterPages, EStaffStatus, IRosterItem } from "../../types";
import { useSelectedRosterTab } from "../hooks/useSelectedRosterTab";
import { useUsersRuleSets } from "../hooks/useUsersRuleSets";

const columnHelper = createColumnHelper<IRosterItem>();

const notApplicable = <Box color={darkGray}>N/A</Box>;

export const useColumns = (isSuspended: boolean) => {
  const navigate = useNavigate();
  const { showInfo } = useToast();
  const unitId = useCurrentUnitId();
  const {
    preferenceRequirementsV2,
    schedulerStaffProfile,
    templatedPreferences,
    newSmsOnboarding,
  } = useAppFlags();

  const staffAttributesByKeyInUnit = useStaffAttributesByKeyForUnit();
  const canManage = useCheckUserPermission("manage", EUnitPermissionAreas.staffRoster);
  const rulesSetByUserId = useUsersRuleSets();
  const { data: preferencesTemplates } = useListPreferencesTemplatesQuery(
    { unitIds: [unitId || NOT_EXISTING_UUID] },
    { skip: !unitId },
  );
  const preferencesTemplatesByUserId = useMemo(() => {
    if (!preferencesTemplates) return {};
    return preferencesTemplates.reduce(
      (acc, template) => {
        template.userIds.forEach((userId) => {
          acc[userId] = template;
        });
        return acc;
      },
      {} as Record<IUser["id"], IPreferencesTemplate>,
    );
  }, [preferencesTemplates]);
  const currentTab = useSelectedRosterTab().selectedRosterTab;

  const [emailPopoverAnchors, setEmailPopoverAnchors] = useState<
    Record<string, { x: number; y: number } | null>
  >({});

  const dispatch = useAppDispatch();
  const { userIsAdmin } = useCurrentRole();
  const timezone = useCurrentTimezone();
  const currentUnitId = useCurrentUnitId();

  const getFormattedDate = (date?: string | null) => {
    if (!date) {
      return;
    }
    return m7DayJs(date).format("D MMM, YYYY");
  };

  const columns = useMemo(
    () => [
      columnHelper.accessor("name", {
        header: "Name",
        cell: (info) => {
          const {
            firstName,
            id,
            lastName,
            userType,
            postDateStatusUpdateType,
            postDateStatusUpdateDate,
            lastLoggedInAt,
          } = info.row.original;

          const futureSuspensionDate =
            postDateStatusUpdateType === "suspended" && postDateStatusUpdateDate;

          const openSendResetPasswordModal = () => {
            dispatch(openModal("sendResetPasswordModal"));
            dispatch(setUsersToSendResetPassword([id as IUser["id"]]));
          };

          const menuItems = isSuspended
            ? [
                {
                  label: "Restore Account",
                  onClick: () => {
                    dispatch(setIsRestoredModalOpen(true));
                    dispatch(setRestoredModalData({ id, name: `${lastName} ${firstName}` }));
                  },
                },
              ]
            : [
                {
                  label: "Edit User",
                  onClick: () => {
                    dispatch(setIsEditUserModalOpen(true));
                    dispatch(setEditUserModalId(id));
                    dispatch(
                      setEditUserModalUserType(
                        userType as unknown as User.ERole.staff | User.ERole.scheduler | null,
                      ),
                    );
                  },
                },
                {
                  label: futureSuspensionDate ? "Edit Suspend Date" : "Suspend Account",
                  onClick: () => {
                    if (futureSuspensionDate) {
                      dispatch(
                        openUpdateSuspensionDateModal({
                          id,
                          name: `${lastName} ${firstName}`,
                          date: futureSuspensionDate,
                          status: postDateStatusUpdateType || null,
                        }),
                      );
                    } else {
                      dispatch(setIsSuspendedModalOpen(true));
                      dispatch(setSuspendedModalData({ id, name: `${lastName} ${firstName}` }));
                    }
                  },
                },
                ...(newSmsOnboarding && canManage
                  ? [
                      {
                        label: "Send Reset Password",
                        onClick: openSendResetPasswordModal,
                      },
                    ]
                  : []),
              ];

          return (
            <Grid container alignItems="center" justifyContent="space-between" flexWrap="nowrap">
              <Grid item ml={2} flexGrow={1}>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: 1,
                  }}
                >
                  <CustomButton
                    variant="text"
                    trackingLabel="staff-profile-clicked"
                    disabled={!schedulerStaffProfile}
                    onClick={() =>
                      navigate(
                        `/roster/staff/${currentUnitId || ""}/${currentTab}/${EStaffRosterPages.staffDetails}/${id}`,
                      )
                    }
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: 1,
                      textAlign: "left",
                      padding: 0,
                      ":hover": { backgroundColor: lightGray },
                      "&.Mui-disabled": {
                        color: black,
                      },
                    }}
                  >
                    <Avatar
                      firstName={firstName}
                      lastName={lastName}
                      height="28px"
                      width="28px"
                      style={{ fontSize: "0.8rem", background: nightShift, color: black }}
                    />
                    <Stack spacing={0}>
                      {getFullName(firstName, lastName, true)}
                      {futureSuspensionDate && (
                        <Typography color="error" fontSize={"12px"}>
                          Suspended starting {getTzUSFormattedDate(futureSuspensionDate, timezone)}
                        </Typography>
                      )}
                    </Stack>
                  </CustomButton>
                  <Box flexGrow={1} />
                  {canManage && !lastLoggedInAt && newSmsOnboarding && (
                    <CustomButton
                      iconOnly
                      color="error"
                      startIcon={<LockReset />}
                      label={`${getFullName(firstName, lastName, true)} has not logged in yet. Click to send reset password.`}
                      onClick={openSendResetPasswordModal}
                    />
                  )}
                </Box>
              </Grid>
              {canManage ? (
                <Grid item ml={1}>
                  <MeatballsMenu
                    meatballIconStyles={meatballStyles}
                    menuItemStyles={menuItemStyles}
                    items={menuItems}
                  />
                </Grid>
              ) : null}
            </Grid>
          );
        },
        meta: {
          pinned: true,
        },
      }),
      columnHelper.accessor("note", {
        header: "Notes",
        cell: (info) => {
          const { firstName, id, lastName, note, userType } = info.row.original;

          const isStaff = userType === User.ERole.staff;

          const onClick = () => {
            if (canManage) {
              dispatch(setIsNoteModalOpen(true));
              dispatch(setNoteModalData({ firstName, id, lastName, note: note || "" }));
              note ? dispatch(setNoteModalVariant("edit")) : dispatch(setNoteModalVariant("add"));
            }
          };

          return (
            <>
              {isStaff && (
                <>
                  {note ? (
                    <Tooltip placement="top" title={note}>
                      <Article onClick={onClick} sx={noteIconStyles} />
                    </Tooltip>
                  ) : (
                    <AddBoxOutlined onClick={onClick} sx={noteIconStyles} />
                  )}
                </>
              )}
            </>
          );
        },
      }),
      columnHelper.accessor("staffTypeName", {
        header: "Staff Type",
        cell: (info) =>
          info.getValue() ? info.getValue() : <Box color={darkGray}>N/A (Scheduler)</Box>,
      }),
      columnHelper.accessor("status", {
        header: "Status",
        cell: (info) => {
          const { firstName, id, lastName, postDateStatusUpdateDate, postDateStatusUpdateType } =
            info.row.original;
          if (info.row.original.userType !== User.ERole.staff) return notApplicable;

          const futureInactivationDate =
            postDateStatusUpdateType === "inactive" && postDateStatusUpdateDate;

          return isSuspended ? (
            <CustomSwitch
              checked={false}
              onChange={() => void 0}
              label={upperFirst(info.getValue())}
              name={`status_${info.row.original.id}`}
              style={{ margin: 0 }}
              disabled
            />
          ) : (
            <Tooltip
              title={
                futureInactivationDate ? (
                  <>
                    This user is set to be inactive on{" "}
                    {getTzFormattedDate(futureInactivationDate, timezone)}.
                  </>
                ) : (
                  ""
                )
              }
              open={postDateStatusUpdateType ? undefined : false}
            >
              <span>
                <CustomSwitch
                  checked={info.getValue() === EStaffStatus.Active}
                  color={futureInactivationDate ? "warning" : "primary"}
                  onChange={(value) => {
                    if (futureInactivationDate) {
                      dispatch(
                        openUpdateStatusDateModal({
                          id,
                          name: `${lastName} ${firstName}`,
                          date: futureInactivationDate,
                          status: postDateStatusUpdateType || null,
                        }),
                      );
                    } else {
                      dispatch(
                        setStatusChangeModalData({
                          name: `${lastName} ${firstName}`,
                          id: id,
                          status: value ? EStaffStatus.Active : EStaffStatus.Inactive,
                          postDatedExistingStatus: postDateStatusUpdateType || null,
                          postDatedExistingDate: postDateStatusUpdateDate || null,
                        }),
                      );
                      dispatch(setIsStatusChangeModalOpen(true));
                    }
                  }}
                  label={
                    futureInactivationDate
                      ? "Active until " + getTzUSFormattedDate(futureInactivationDate, timezone)
                      : upperFirst(info.getValue())
                  }
                  name={`status_${info.row.original.id}`}
                  style={{ margin: 0 }}
                  disabled={!canManage}
                />
              </span>
            </Tooltip>
          );
        },
      }),
      ...(preferenceRequirementsV2
        ? [
            columnHelper.display({
              header: "Contact",
              meta: { cellStyles: { padding: 0 } },
              cell: (info) => {
                const { firstName, lastName, phoneNumber, id } = info.row.original;

                return (
                  <Stack direction="row">
                    {canManage && (
                      <SendSingleSms.Button
                        data={{ firstName, lastName, id }}
                        sx={{ fontSize: "20px" }}
                      />
                    )}
                    <Stack direction="column" sx={{ p: 0 }}>
                      <Stack direction="row" alignItems="center" position="relative">
                        <Typography sx={{ fontSize: "12px", paddingRight: "20px" }}>
                          {info.row.original.email}
                        </Typography>
                        {userIsAdmin && (
                          <IconButton
                            sx={{
                              svg: { fontSize: "15px" },
                              ml: 1,
                              ":hover": { color: black },
                              position: "absolute",
                              right: "-10px",
                              top: "-5px",
                            }}
                            onClick={() => {
                              showInfo("Email Copied to clipboard");
                              void navigator.clipboard.writeText(info.row.original.email);
                            }}
                          >
                            <ContentCopy fontSize="small" />
                          </IconButton>
                        )}
                      </Stack>
                      <Stack direction="row" alignItems="center" position="relative">
                        <Typography sx={{ fontSize: "12px" }}>
                          {formatPhoneNumber(phoneNumber)}
                        </Typography>
                      </Stack>
                    </Stack>
                  </Stack>
                );
              },
            }),
          ]
        : [
            columnHelper.accessor("email", {
              header: "E-mail",
              cell: (info) => {
                const popperId = info.cell.id + "-copy";
                const handleClose = () => {
                  setEmailPopoverAnchors((prev) => ({ ...prev, [popperId]: null }));
                };
                const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
                  const position = {
                    x: event.clientX,
                    y: event.clientY,
                  };
                  setEmailPopoverAnchors((prev) => ({ ...prev, [popperId]: position }));
                  setTimeout(() => handleClose(), 2000);
                  void navigator.clipboard.writeText(info.getValue());
                };

                const position = emailPopoverAnchors[popperId];

                return (
                  <>
                    {info.getValue()}
                    {userIsAdmin && (
                      <>
                        <IconButton
                          aria-describedby={popperId}
                          sx={{ ml: 1, ":hover": { color: black } }}
                          onClick={handleClick}
                        >
                          <ContentCopy
                            sx={{ color: position ? black : "inherit" }}
                            fontSize="small"
                          />
                        </IconButton>
                        {position && (
                          <Popover
                            id={popperId}
                            open={true}
                            sx={{
                              position: "fixed",
                              top: position.y,
                              left: position.x,
                            }}
                            onClose={handleClose}
                          >
                            <Typography sx={{ p: 2 }}>Copied to clipboard.</Typography>
                          </Popover>
                        )}
                      </>
                    )}
                  </>
                );
              },
            }),
          ]),
      ...(preferenceRequirementsV2
        ? []
        : [
            columnHelper.accessor("phoneNumber", {
              header: "Phone number",
              cell: (info) => info.getValue(),
            }),

            columnHelper.accessor("shiftType", {
              header: "Shift type",
              cell: (info) => (info.getValue() ? capitalize(info.getValue()) : notApplicable),
            }),
          ]),
      // only show positions that are eligible for this unit
      columnHelper.accessor("attributeKeys", {
        header: "Position eligibility",
        cell: (info) =>
          (info.getValue()?.length ?? 0) > 0
            ? info
                .getValue()
                ?.filter((attributeKey) => attributeKey in staffAttributesByKeyInUnit)
                ?.map((attributeKey) => staffAttributesByKeyInUnit[attributeKey]?.name)
                .sort()
                .join(", ")
            : notApplicable,
      }),
      columnHelper.accessor("employmentType", {
        header: "Employment",
        cell: (info) => info.getValue() || notApplicable,
      }),
      ...(preferenceRequirementsV2
        ? [
            columnHelper.display({
              header: "Shift Requirement",
              cell: (info) => {
                const userId = info.row.original.id;
                const ruleSetLabels = rulesSetByUserId[userId];
                if (!ruleSetLabels?.length) return notApplicable;

                return ruleSetLabels.map(({ label, name, color }) => (
                  <Tooltip title={`Rule Set: ${name}`} key={label + userId} placement="top" arrow>
                    <Chip
                      label={label}
                      size={"small"}
                      sx={{
                        mr: 1,
                        backgroundColor: color,
                        borderRadius: "5px",
                      }}
                    />
                  </Tooltip>
                ));
              },
            }),
          ]
        : [
            columnHelper.accessor("totalShiftsRequiredPerSchedule", {
              header: "Total shifts required",
              cell: (info) => info.getValue() || notApplicable,
            }),
            columnHelper.accessor("totalWeekendShiftsRequiredPerSchedule", {
              header: "Weekend shifts required",
              cell: (info) => info.getValue() || notApplicable,
            }),
            columnHelper.accessor("maxRequestsOffPerSchedule", {
              header: "Maximum block requests",
              cell: (info) => info.getValue() || notApplicable,
            }),
          ]),
      ...(templatedPreferences
        ? [
            columnHelper.display({
              header: "Preference Template",
              cell: (info) => {
                const userId = info.row.original.id;
                const preferencesTemplate = preferencesTemplatesByUserId[userId];
                if (!preferencesTemplate) return notApplicable;

                return (
                  <PreferencesTemplateLabel preferencesTemplate={preferencesTemplate} withTooltip />
                );
              },
            }),
          ]
        : []),
      columnHelper.accessor("experience", {
        header: "Experience",
        cell: (info) => (info.getValue() ? capitalize(info.getValue()) : notApplicable),
      }),
      columnHelper.accessor("onOrientation", {
        header: "On orientation",
        cell: (info) => {
          const { orientationEndDate } = info.row.original;
          return isBoolean(info.getValue())
            ? info.getValue() === true
              ? getFormattedDate(orientationEndDate)
              : "No"
            : notApplicable;
        },
      }),
      columnHelper.accessor("preceptor", {
        header: "Preceptor",
        cell: (info) =>
          isBoolean(info.getValue()) ? (info.getValue() === true ? "Yes" : "No") : notApplicable,
      }),
      ...(preferenceRequirementsV2
        ? []
        : [
            columnHelper.accessor("minShiftsPerWeek", {
              header: "Min per week",
              cell: (info) => {
                const { minShiftsPerWeek } = info.row.original;
                return minShiftsPerWeek ? `${minShiftsPerWeek}` : notApplicable;
              },
            }),
            columnHelper.accessor("moreThanOneDayBetweenShifts", {
              header: "Days btwn shifts?",
              cell: (info) =>
                isBoolean(info.getValue())
                  ? info.getValue() === true
                    ? "Yes"
                    : "No"
                  : notApplicable,
            }),
            columnHelper.accessor("maxConsecutiveShifts", {
              header: "Max consecutive shifts",
              cell: (info) => info.getValue() ?? notApplicable,
            }),
          ]),
      columnHelper.accessor("contractEndDate", {
        header: "Contract expiration",
        cell: (info) => {
          const value = info.getValue();
          return value ? getFormattedDate(value) : notApplicable;
        },
      }),
      ...(isSuspended
        ? [
            columnHelper.accessor("softDeletedDate", {
              header: "Suspended Date",
              cell: (info) => {
                const value = info.getValue();
                return value ? getFormattedDate(value) : notApplicable;
              },
            }),
          ]
        : []),
    ],
    [
      preferenceRequirementsV2,
      templatedPreferences,
      isSuspended,
      newSmsOnboarding,
      schedulerStaffProfile,
      timezone,
      canManage,
      dispatch,
      navigate,
      currentUnitId,
      currentTab,
      userIsAdmin,
      showInfo,
      emailPopoverAnchors,
      staffAttributesByKeyInUnit,
      rulesSetByUserId,
      preferencesTemplatesByUserId,
    ],
  );

  return { columns };
};

const meatballStyles = {
  border: "none",
  color: darkGray,
  width: "36px",
  height: "36px",
  "&:hover": {
    color: black,
    border: `1px solid ${black}`,
    borderRadius: "4px",
  },
};

const menuItemStyles = {
  width: "188px",
};
const noteIconStyles = {
  color: rosterIconsGrey,
  cursor: "pointer",
  "&:hover": {
    color: black,
  },
};
