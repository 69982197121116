import { memo } from "react";

import { keys } from "lodash";

import { Add } from "@mui/icons-material";
import {
  Box,
  Paper,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import MuiTable from "@mui/material/Table";

import { CustomButton } from "@/common/components";
import { useAppSelector } from "@/common/hooks";

import { ErrorsSideBar } from "../ErrorsSideBar";

import { expectedColumnLabels } from "./constants";
import { useCaptureSaveSignal } from "./hooks/useCaptureSaveSignal";
import { useLazyLoadRows } from "./hooks/useLazyLoadRows";
import { useParseData } from "./hooks/useParseData";
import { useSetUnitData } from "./hooks/useSetUnitData";
import { useUpdateData } from "./hooks/useUpdateData";
import { TExpectedColumn } from "./types";
import { UserRow } from "./UserRow";

const componentId = "bulk-create-users-table";
export const Table = memo(() => {
  const { data: CSVData } = useParseData();
  const unitData = useSetUnitData();
  const {
    data: { rows, headers },
    errors,
    actions,
  } = useUpdateData({ CSVData, unitData });
  useCaptureSaveSignal({ users: rows || undefined, errors: errors.cells });

  const highlightedRow = useAppSelector((store) => store.roster.bulkCreateUsers.highlightedRow);

  // Setup scrollbar tracking and lazy loading
  const lazyLoadIndexes = useLazyLoadRows(rows?.length || 0);

  if (!rows || !headers) return null;

  return (
    <Box className={componentId} data-testid={componentId}>
      {/* Error sidebar */}
      <ErrorsSideBar errors={errors} headers={headers} />

      {/* Add row button */}
      <AddRowButton onClick={actions.addRow} />
      {/* Table */}
      <TableContainer component={Paper} className={"table-container"}>
        <MuiTable stickyHeader size="small">
          {/* Table headers */}
          <TableHeaders headers={headers} />

          {/* Table body */}
          <TableBody>
            {rows?.map((row, rowIndex) => (
              <UserRow
                shouldRender={
                  lazyLoadIndexes.rowDisplayStart <= rowIndex &&
                  lazyLoadIndexes.rowDisplayEnd >= rowIndex
                }
                key={rowIndex.toString()}
                // General data
                headers={headers}
                unitData={unitData}
                // Row data
                cellErrors={errors.cells[rowIndex]}
                isHighlighted={highlightedRow === rowIndex}
                row={row}
                rowIndex={rowIndex}
                // Actions
                updateCell={actions.updateCell}
                removeRow={actions.removeRow}
              />
            ))}
          </TableBody>
        </MuiTable>
      </TableContainer>
    </Box>
  );
});

const AddRowButton = ({ onClick }: { onClick: () => void }) => (
  <CustomButton
    onClick={onClick}
    startIcon={<Add />}
    label="Add row"
    trackingLabel={null}
    className="add-row-button"
  />
);

const TableHeaders = ({ headers }: { headers: Record<TExpectedColumn, number> }) => (
  <TableHead>
    <TableRow className={"table-head-row"}>
      {keys(headers).map((column) => (
        <TableCell key={column} align="right" children={expectedColumnLabels[column]} />
      ))}
    </TableRow>
  </TableHead>
);
