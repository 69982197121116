import { isArray } from "lodash";
import { parse, ParseError } from "papaparse";

import { useToast } from "@/common/hooks";

/** read the CSV file and return unknown[] data,
 * displays errors or success in toast */
export class FileReader {
  constructor(private readonly toaster: ReturnType<typeof useToast>) {}

  async read(file: File | null) {
    if (!file) return null;

    // Wraps the callback into a promise
    return new Promise<unknown[]>((resolve, reject) => {
      parse(file, {
        complete: (results) => {
          if (results.errors.length > 0) {
            this.displayErrors(results.errors);
            reject(new Error("Failed to parse file"));
            return;
          }

          // Trim empty last rows if any
          const lastRow = results.data[results.data.length - 1];
          const lastRowIsEmpty = lastRow && isArray(lastRow) && lastRow.length === 1;

          this.toaster.showSuccess("File parsed successfully.");
          resolve(results.data.slice(0, lastRowIsEmpty ? -1 : undefined));
        },
      });
    });
  }

  /** display errors first 3 errors in toast */
  private displayErrors(errors: ParseError[]) {
    const limit = 3;
    const errorToDisplay = errors
      .slice(0, limit)
      .map((error) => error.message)
      .join(", ");
    let suffix = "";
    if (errors.length > limit) suffix = ` and ${errors.length - limit} more.`;
    this.toaster.showError(`There were errors in the file: ${errorToDisplay}${suffix}`);
  }
}
