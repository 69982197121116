import { useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";

import { toISO, User } from "@m7-health/shared-utils";
import { isArray, keys, map } from "lodash";

import { routes } from "~/routes";
import { TAppDispatch } from "~/store";

import { RosterActions } from "#/features/Roster/store";
import { IStaffType, useBulkUploadUsers } from "@/api";
import { useAppDispatch, useAppSelector, useM7SimpleContext, useToast } from "@/common/hooks";

import { TUserRow, TUserRowOptional } from "../types";

import { TIndexedErrors } from "./useUpdateData";

/**
 * Capture the save signal and save the users.
 * If there is an error, toast it.
 *
 * In any case (success or error), reset the save signal.
 */
export const useCaptureSaveSignal = ({
  users,
  errors,
}: {
  users?: TUserRowOptional[];
  errors?: TIndexedErrors;
}) => {
  // Hooks
  const dispatch = useAppDispatch();
  const toast = useToast();
  const navigate = useNavigate();
  const unitId = useM7SimpleContext().currentUnitId;

  // Ref state to make sure we only have one save in flight
  const alreadySaving = useRef(false);

  // Store selectors
  const {
    saveUserSignal,
    sendResetPasswordMethod,
    unitData: { staffTypes },
  } = useAppSelector((store) => store.roster.bulkCreateUsers);
  const haveErrors = keys(errors).length > 0;

  const { mutateAsync: createUsers } = useBulkUploadUsers({});

  // Effect that triggers on saveUserSignal change
  // 1 - validate the state and the data
  // 2 - if valid, format the users
  // 3 - call the api
  // 4 - toast the result
  // 5 - reset the save signal
  useEffect(() => {
    if (saveUserSignal) {
      if (
        !canSave({
          data: { alreadySaving: alreadySaving.current, haveErrors, users, unitId },
          actions: { dispatch, toast },
        })
      )
        return;

      const indexedStaffTypes = staffTypes.reduce(
        (acc, staffType) => ({
          ...acc,
          [staffType.name]: staffType.key,
        }),
        {} as Record<IStaffType["name"], IStaffType["key"]>,
      );

      void (async () => {
        try {
          const formattedUsers = map(users as TUserRow[], (user) =>
            formatUser({ user, indexedStaffTypes, unitId: unitId! }),
          );

          await createUsers({
            users: formattedUsers,
            sendResetPasswordMethod,
          });

          toast.showSuccess(`${users!.length} users created successfully`);
          dispatch(RosterActions.setUploadedFile(null));
          navigate(routes.staffRosterUnit.path);
        } catch (error) {
          const errorMessage = (error as Error).message;
          const stringMessage = isArray(errorMessage) ? errorMessage.join("\n") : errorMessage;
          toast.showError(`Failed to create users: ${stringMessage}`);
        } finally {
          alreadySaving.current = false;
          dispatch(RosterActions.setSaveUserSignal(false));
        }
      })();
    }
  }, [
    saveUserSignal,
    dispatch,
    createUsers,
    users,
    navigate,
    toast,
    haveErrors,
    sendResetPasswordMethod,
    unitId,
    staffTypes,
  ]);
};

/**
 * Make sure we are in a good state to save.
 * Return true if we are, false otherwise, with a toast error.
 */
const canSave = ({
  data: { alreadySaving, haveErrors, users, unitId },
  actions: { dispatch, toast },
}: {
  data: {
    alreadySaving: boolean;
    haveErrors: boolean;
    users?: TUserRowOptional[];
    unitId?: string;
  };
  actions: {
    dispatch: TAppDispatch;
    toast: ReturnType<typeof useToast>;
  };
}) => {
  if (alreadySaving) return;

  if (haveErrors) {
    toast.showError("Please fix errors before saving");
    dispatch(RosterActions.setSaveUserSignal(false));
    return;
  }

  if (!users) {
    toast.showError("No users to save");
    dispatch(RosterActions.setSaveUserSignal(false));
    return;
  }

  if (!unitId) {
    toast.showError("No unit selected");
    dispatch(RosterActions.setSaveUserSignal(false));
    return;
  }

  return true;
};

const formatUser = ({
  user,
  indexedStaffTypes,
  unitId,
}: {
  user: TUserRow;
  indexedStaffTypes: Record<string, IStaffType["key"]>;
  unitId: string;
}) => {
  const {
    // Staff type Name, need to get key key
    staffTypeName,
    // Dayjs Date, need to convert to ISO
    employmentStartDate,
    orientationEndDate,
    contractEndDate,
    // Other fields, just pass through
    ...rest
  } = user;
  const staffTypeKey = indexedStaffTypes[staffTypeName];
  if (!staffTypeKey) throw new Error(`Staff type ${staffTypeName} not found`);

  return {
    ...rest,
    orientationEndDate: orientationEndDate ? toISO(orientationEndDate) : null,
    contractEndDate: contractEndDate ? toISO(contractEndDate) : null,
    employmentStartDate: employmentStartDate ? toISO(employmentStartDate) : null,
    homeUnitId: unitId,
    roles: [User.ERole.staff],
    staffTypeKey,
  };
};
