import { Timezone } from "@m7-health/shared-utils";
import { entries, includes, indexOf, intersection, keys, map, reduce } from "lodash";

import { IShiftType, IStaffType } from "@/api";

import { expectedColumnLabels, expectedOrderedColumns, requiredColumns } from "../../constants";
import { TCellError, TColumnError, TExpectedColumn, TUserRow, TUserRowOptional } from "../../types";

import {
  booleanParser,
  dayJsParser,
  employmentTypeParser,
  shiftTypeParser,
  staffTypeNameParser,
  stringParser,
} from "./parsers";
import { flatCleanString } from "./parsers/helpers";
import { TDataParser } from "./parsers/types";

export class ParserError extends Error {}

export class DataParser {
  staffTypesNames: Record<string, IStaffType["name"]>;
  shiftTypesNames: Record<string, IShiftType["key"]>;

  constructor(
    private readonly staffTypes: IStaffType[],
    private readonly shiftTypes: IShiftType[],
    readonly timezone: Timezone,
  ) {
    this.staffTypesNames = this.setupMatchingStaffTypeNames();
    this.shiftTypesNames = this.setupMatchingShiftTypeKeys();
  }

  parseData(data: unknown[]) {
    // Extract headers and rows as unknown[]
    const { headers, rows } = this.extractTableHeaders(data);
    // Make sure headers are valid, and return typed headers
    const { indexedHeaders, columnErrors } = this.validateHeaders(headers);
    // Validate rows and return typed rows
    const { rows: validatedRows, cellErrors } = this.validateRows(rows, indexedHeaders);

    // Once rows are validated and extra data is removed, we can re-index headers
    const reindexedHeaders = keys(indexedHeaders).reduce(
      (acc, header, index) => {
        acc[header] = index;
        return acc;
      },
      {} as Record<TExpectedColumn, number>,
    );

    return {
      indexedHeaders: reindexedHeaders,
      columnErrors: columnErrors,
      cellErrors: cellErrors,
      rows: validatedRows,
    };
  }

  // Check if headers are present or not.
  // If headers are present, return them and the rows without the first row.
  // If headers are not present, return the hardcoded expected headers and the rows as is.
  private extractTableHeaders(data: unknown[]) {
    const maybeHeaders = data[0];
    let headers: unknown[];
    let rows: unknown[][];

    if (!Array.isArray(maybeHeaders)) throw new Error("Invalid file format.");

    if (intersection(maybeHeaders, expectedOrderedColumns).length) {
      headers = maybeHeaders as unknown[];
      rows = data.slice(1) as unknown[][];
    } else {
      headers = expectedOrderedColumns;
      rows = data as unknown[][];
    }

    return { headers, rows };
  }

  // Validate headers and return a typed object with the headers and their indices.
  // Make sure all required headers are present.
  // Make sure all provided headers are expected.
  private validateHeaders(headers: unknown[]) {
    const columnErrors: TColumnError[] = [];
    // Flat headers to increase chances of finding a match
    const flattenedExpectedHeaders = map(expectedOrderedColumns, flatCleanString);

    const indexedHeaders = reduce(
      headers,
      (acc, header, currentIndex) => {
        const stringHeader = flatCleanString(header || "");

        const index = indexOf(flattenedExpectedHeaders, stringHeader);
        if (index === -1) {
          columnErrors.push({
            columnIndex: currentIndex,
            message: `Unexpected column: ${(header || "").toString()}`,
          });
        } else acc[expectedOrderedColumns[index] as TExpectedColumn] = currentIndex;

        return acc;
      },
      {} as Record<TExpectedColumn, number>,
    );

    requiredColumns.forEach((column) => {
      if (!(column in indexedHeaders)) {
        columnErrors.push({
          columnIndex: -1,
          message: `Missing required column: ${column}`,
        });
        indexedHeaders[column] = -1;
      }
    });

    return { indexedHeaders, columnErrors };
  }

  // For each row, based on headers indexes, grab the value and parse it.
  validateRows(rows: unknown[][], indexedHeaders: Record<TExpectedColumn, number>) {
    const orderedHeaders = entries(indexedHeaders);
    const cellErrors: TCellError[] = [];

    const parsedRows = reduce(
      rows,
      (rowsAcc, row, rowIndex) => {
        const { rowErrors, indexedRow } = this.validateRow({ row, orderedHeaders, rowIndex });

        rowsAcc.push(indexedRow);
        cellErrors.push(...rowErrors);
        return rowsAcc;
      },
      [] as TUserRowOptional[],
    );

    return { rows: parsedRows, cellErrors };
  }

  validateRow({
    row,
    orderedHeaders,
    rowIndex,
  }: {
    row: unknown[];
    orderedHeaders: [TExpectedColumn, number][];
    rowIndex: number;
  }) {
    const rowErrors: TCellError[] = [];

    const indexedRow = reduce(
      orderedHeaders,
      (rowAcc, [header, columnIndex]) => {
        // -1 index means it's a required column, but it's is missing from the file.
        // So we just skip it.
        if (columnIndex === -1) return rowAcc;

        const unparsedValue = row[columnIndex];
        let parsedValue;
        try {
          parsedValue = this.parseCellValue({
            header,
            unparsedValue,
          });
        } catch (error: unknown) {
          const prefix = expectedColumnLabels[header];
          const errorMessage =
            error instanceof ParserError
              ? error.message
              : `Unknown error: ${(error as Error).message}`;

          rowErrors.push({
            originalValue: unparsedValue || "No value",
            rowIndex: rowIndex,
            columnIndex: columnIndex,
            message: `${prefix}: ${errorMessage}`,
          });
          parsedValue = undefined;
        }

        return { ...rowAcc, [header]: parsedValue };
      },
      {} as TUserRowOptional,
    );

    return { rowErrors, indexedRow };
  }

  private parseCellValue(params: { header: TExpectedColumn; unparsedValue: unknown }) {
    // Get the parser for the current header
    const dataParser = parsers[params.header];
    // Parse the value
    const data = dataParser(params.unparsedValue, this);

    // If the value is null and the header is required, throw an error
    const dataIsEmpty = (data ?? "").toString().trim() === "";
    if (dataIsEmpty && includes(requiredColumns, params.header))
      throw new ParserError(`Required ${params.header} is empty`);

    // Return the parsed value
    return data;
  }

  // Build an object that maps keys and names to the staff type name
  private setupMatchingStaffTypeNames() {
    return this.staffTypes.reduce(
      (acc, staffType) => {
        acc[flatCleanString(staffType.name)] = staffType.name;
        acc[flatCleanString(staffType.key)] = staffType.name;
        return acc;
      },
      {} as Record<string, IStaffType["name"]>,
    );
  }
  // Build an object that maps keys and names to the shift type key
  private setupMatchingShiftTypeKeys() {
    return this.shiftTypes.reduce(
      (acc, shiftType) => {
        acc[flatCleanString(shiftType.name)] = shiftType.key;
        acc[flatCleanString(shiftType.key)] = shiftType.key;
        return acc;
      },
      {} as Record<string, IShiftType["key"]>,
    );
  }
}

const parsers = {
  email: stringParser,
  firstName: stringParser,
  lastName: stringParser,
  phoneNumber: stringParser,
  preceptor: booleanParser,
  onOrientation: booleanParser,
  orientationEndDate: dayJsParser,
  contractEndDate: dayJsParser,
  employmentStartDate: dayJsParser,
  staffTypeName: staffTypeNameParser,
  employmentType: employmentTypeParser,
  shiftType: shiftTypeParser,
} satisfies Record<keyof TUserRow, TDataParser>;
