import { useEffect, useState } from "react";

import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import { Box, Collapse, Grid, Typography } from "@mui/material";

import { emptySx } from "@/common/types";

import { ICollapseProps } from "./types";

export const CustomCollapse = ({
  label,
  content,
  children,
  onExpand,
  onChange,
  id,
  styles,
  sx = emptySx,
  isExpandedByDefault = false,
  isOpen,
  hideToggle = false,
  disabled = false,
  className,
}: ICollapseProps) => {
  const [isExpanded, setIsExpanded] = useState<boolean>(isExpandedByDefault);
  const onToggleHandler = (event: React.MouseEvent<unknown>) => {
    if (disabled) return;

    event.stopPropagation();
    setIsExpanded((prev) => {
      const newExpandState = !prev;
      if (onExpand || onChange) {
        if (newExpandState && id) {
          onChange?.(id, true);
          onExpand?.(id);
        } else if (!newExpandState && id) {
          onChange?.(id, false);
        }
      }

      return newExpandState;
    });
  };

  useEffect(() => {
    if (isOpen !== undefined) {
      setIsExpanded(isOpen);
    }
  }, [isOpen]);

  return (
    <Grid
      container
      flexDirection="column"
      mt={1}
      mb={1}
      sx={styles || sx}
      className={`m7-collapse ${className || ""}`}
    >
      <Grid
        onClick={onToggleHandler}
        container
        alignItems="flex-start"
        justifyContent="flex-start"
        flexWrap="nowrap"
        className="m7-collapse-header"
        sx={{ cursor: disabled ? "default" : "pointer" }}
      >
        {!hideToggle && (
          <>
            {disabled ? <></> : isExpanded ? <ArrowDropDownIcon /> : <ArrowRightIcon />}
            {typeof label === "string" ? (
              <Typography mt="5px" variant="small" className="m7-collapse-label">
                {label}
              </Typography>
            ) : (
              label
            )}
          </>
        )}
      </Grid>
      <Collapse
        className="m7-collapse-content"
        in={isExpanded}
        orientation="vertical"
        sx={{ marginLeft: "8px" }}
      >
        <Box mt={1}>{content || children}</Box>
      </Collapse>
    </Grid>
  );
};
